import { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { useHistory, useParams } from "react-router-dom";
import Chart2 from "../../components/chartjs/Chart2";
import Hn from "../../components/common/Hn";
import PopupVideo from "../../components/popup/PopupVideo";
import "./DetailFormation.css";
import "../../components/popup/popupVideo.css";
import Placeholder from "../../assets/placeholder.png";
import axios from "axios";
import Iframe from "react-iframe";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player/lazy";
import toast, { Toaster } from "react-hot-toast";
export default function DetailsFormationE({ disabled }) {
  const user = localStorage.getItem("email");
  const [video, setVideo] = useState(false);
  const [unite, setUnite] = useState();
  const [content_url, setContentUrl] = useState(false);
  const [showModalBool, setShowModalBool] = useState(false);
  const [showfiltre, setShowfiltre] = useState(false);
  const [elearning, setElearning] = useState([]);
  const [validated, setValidated] = useState(0);
  const [nbUnites, setNbUnites] = useState(0);
  const [scoreds, setScoreds] = useState([]);
  const [play, setPlay] = useState([]);
  const [score, setScore] = useState();
  const [openDetails, setOpenDetails] = useState(false);
  const [elearnings, setElearnings] = useState([]);
  const [trainings, setTrainings] = useState([]);

  const IMG_URL = process.env.REACT_APP_PIC_ELEARNING_IMG;
  const params = useParams();

  let history = useHistory();

  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const uri = process.env.REACT_APP_BASE_URL;
  const handleClosefiltre = () => {
    setShowfiltre(false);
  };

  //récupérer toutes les formations elearning
  function getElearnings() {
    trackPromise(
      /*local*/ axios
        .get(uri + "/elearning")
        .then((result) => {
          setElearnings(result?.data);
        })
        .catch((err) => {
          console.log("err location", err);
        })
    );
  }

  //créer les données e-learning de l'utilisateur en question.  Le score par formation, la validation,le nombre d'unités et l'avancement...
  function getUserData(user_email) {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    var data = new FormData();
    data.append("user_email", user_email);

    trackPromise(
      /*local*/ axios
        .post(
          uri + "/watershed/get_user_data_for_elearning/" + params.id,
          data,
          headersFiles
        )
        .then((result) => {
          setScore(result?.data?.score);
          setScoreds(result?.data?.scoreds);
          setPlay(result?.data?.play);
          setValidated(result?.data?.validated);
          setNbUnites(result?.data?.nb_unites);
        })
        .catch((err) => {
          console.log("err data", err);

          toast.error("Désolé cette formation n'a pas de contenu");
          setTimeout(() => {
            history.push("/Formations-e-learning");
          }, 1500);
        })
    );
  }
  //récupérer l'elearning par id
  function getElearning() {
    trackPromise(
      axios
        .get(uri + "/elearning/" + params.id, headers)
        .then((result) => {
          setElearning(result?.data);
        })
        .catch((err) => {
          console.log("err elearning", err);
        })
    );
  }
  //récupérer toutes les formations présentielles
  function getTrainings() {
    trackPromise(
      axios
        .get(uri + "/training")
        .then((result) => {
          setTrainings(result?.data);
        })
        .catch((err) => {
          console.log("err location", err);
        })
    );
  }

  let img = "";
  if (elearning?.image) {
    img = IMG_URL + elearning.image;
  } else {
    img = Placeholder;
  }
  useEffect(() => {
    getElearnings();
    getTrainings();
    getUserData(user);
    getElearning();
  }, []);

  const showModal = (video, url, unite) => {
    setVideo(video);
    setUnite(unite);
    setContentUrl(url);
    setShowModalBool(true);
  };

  const hideModal = () => {
    setShowModalBool(false);
  };

  const UniteApprentissageItem = ({ uniteApprentissage }) => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <tr className="td">
          <td>{uniteApprentissage.chapitre.titre}</td>
          <td>{uniteApprentissage.chapitre.duree}</td>
          <td>Documents associés</td>
          <td>{uniteApprentissage.score}</td>
          <td>
            <span className="text-success">
              {uniteApprentissage.chapitre_passed === true && (
                <img
                  style={{ width: "20px" }}
                  src={require("../../assets/icons/Valid.png")}
                />
              )}
              {uniteApprentissage.chapitre_passed === false && (
                <img
                  style={{ width: "20px" }}
                  src={require("../../assets/icons/NotValid.png")}
                />
              )}
            </span>
          </td>
          <td>
            <a onClick={() => setOpen(!open)}>
              <img
                style={{ width: "20px" }}
                src={require("../../assets/icons/dropdown.png")}
              />
            </a>
          </td>
        </tr>

        {uniteApprentissage?.unites?.map((unite, index) => {
          return (
            <>
              <tr
                className="accordion"
                style={{ visibility: open ? "visible" : "collapse" }}
                key={index}
              >
                <td>{unite.titre}</td>
                <td></td>
                <td>
                  {unite?.extension == "mp4" && (
                    <a
                      href="#"
                      style={{ color: "#006FFF" }}
                      onClick={() =>
                        showModal(
                          true,
                          process.env.REACT_APP_PIC_ELEARNING_FILE +
                            unite?.document,
                          unite
                        )
                      }
                    >
                      <img
                        style={{ width: "20px" }}
                        className="unite_icone"
                        src={require("../../assets/icons/Play.png")}
                      />
                      Lancer la vidéo
                    </a>
                  )}

                  {unite?.extension == "mp3" && (
                    <a
                      href="#"
                      style={{ color: "#006FFF" }}
                      onClick={() =>
                        showModal(
                          true,
                          process.env.REACT_APP_PIC_ELEARNING_FILE +
                            unite?.document,
                          unite
                        )
                      }
                    >
                      <img
                        style={{ width: "20px" }}
                        className="unite_icone"
                        src={require("../../assets/icons/Play.png")}
                      />
                      Lancer le fichier audio
                    </a>
                  )}

                  {unite?.extension == "pdf" && (
                    <a
                      href="#"
                      style={{ color: "#006FFF" }}
                      onClick={() => {
                        showModal(
                          true,
                          process.env.REACT_APP_PIC_ELEARNING_FILE +
                            unite?.document,
                          unite
                        );
                      }}
                    >
                      <img
                        style={{ width: "20px" }}
                        className="unite_icone"
                        src={require("../../assets/icons/MenuMobile.png")}
                      />
                      Ouvrir le document PDF
                    </a>
                  )}

                  {unite?.extension == "zip" && (
                    <a
                      href="#"
                      style={{ color: "#006FFF" }}
                      onClick={() =>
                        showModal(
                          false,
                          process.env.REACT_APP_PIC_ELEARNING_SCORM +
                            unite?.scormdirectoryname +
                            "/story.html",
                          unite
                        )
                      }
                    >
                      <img
                        style={{ width: "20px" }}
                        className="unite_icone"
                        src={require("../../assets/icons/MenuMobile.png")}
                      />
                      Ouvrir le document
                    </a>
                  )}
                </td>
                <td>&nbsp;&nbsp;&nbsp;{unite.score}</td>
                <td></td>
              </tr>
            </>
          );
        })}
      </>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="details pt-5 px-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/Formations-e-learning">Formations e-learning</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {elearning?.intitule}
              </li>
            </ol>
            <div>
              <a href="/Formations-e-learning">Retour</a>

              <Modal
                show={showModalBool}
                onHide={() => setShowModalBool(false)}
                className="videomodal"
                backdrop="static"
                fullscreen={true}
              >
                <Modal.Header closeButton>
                  <div id="unite_title" className="text-white">
                    <p className="elearning">{unite?.elearning_titre}</p>
                    <p className="chapitre">
                      {unite?.chapitre_titre} | {unite?.titre}
                    </p>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div></div>

                  <div id="content_player">
                    {(unite?.extension == "mp3" ||
                      unite?.extension == "mp4") && (
                      <ReactPlayer
                        id="mp4-player"
                        url={content_url}
                        controls={true}
                      />
                    )}

                    {unite?.extension == "zip" && (
                      <Iframe
                        url={content_url}
                        id="scorm_content"
                        display="initial"
                        position="relative"
                        className="vidIframe"
                      />
                    )}

                    {unite?.extension == "pdf" && (
                      <object data={content_url} type="application/pdf">
                        <p>
                          Erreur de chargement, vous pouvez télécharger le PDF{" "}
                          <a href={content_url}>ici</a>
                        </p>
                      </object>
                    )}
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </nav>
          <div className="head-details">
            <div className="row">
              <div className="col-md-9">
                <Hn
                  title={elearning?.intitule}
                  level="h1"
                  dispaly
                  className="pt-4"
                  plus={false}
                />

                <p className="text text-white py-4">
                  Parcours associé :{" "}
                  {elearnings.map((learning, i) => {
                    if (
                      "elearning-" + learning.id ==
                      elearning?.parcours_associe
                    ) {
                      return <span key={i}>{learning?.intitule}</span>;
                    }
                  })}
                  {trainings.map((train, i) => {
                    if ("training-" + train.id == elearning?.parcours_associe) {
                      return <span key={i}>{train?.title}</span>;
                    }
                  })}
                  <span>
                    {elearning.obligation_de_formation
                      ? " Participe à l’obligation de formation "
                      : ""}{" "}
                    |{elearning.manager ? " Spécifique au manager" : ""}
                  </span>
                </p>
              </div>

              <PopupVideo
                showfiltre={showfiltre}
                closefiltre={handleClosefiltre}
                play={play}
                run={params.run}
              />
            </div>
          </div>
          <div className="body-details p-3">
            <div className="row pt-3">
              <div className="col-lg-3 col-md-5 col-xs-6 pt-3">
                <div>
                  <img src={img} className="float-end" width="80%" alt="..." />
                </div>
              </div>
              <div className="col-lg-9 col-md-7 col-xs-6">
                <div className="row pt-3">
                  <div className="col-md-8 border-end">
                    <Hn
                      title="Objectifs"
                      level="h4"
                      plus={false}
                      className="pb-3"
                    />
                    {/* <Hn
                      title={elearning?.objectifs}
                      className="py-1 text-white"
                    /> */}
                    <p className="simpletext ">
                      {elearning?.objectifs?.split(";").map((it, i) => {
                        if (it !== "") {
                          return (
                            <Hn
                              title={it}
                              className="py-1 text-white"
                              key={i}
                            />
                          );
                        }
                      })}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex justify-content-between rest">
                      <div className="align-self-center">
                        {" "}
                        <Chart2
                          title={validated + "/" + nbUnites}
                          invalidated={100 - (validated * 100) / nbUnites}
                          validated={(validated * 100) / nbUnites}
                        />
                      </div>
                      <div className="align-self-center">
                        <Hn
                          title={
                            "il reste " +
                            parseInt(nbUnites - validated) +
                            " unité(s) d'apprentissage"
                          }
                          plus={false}
                          className="text-white text-center"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div></div>
                <div className="row pt-4 pe-2">
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card text-white bg-dark mb-3 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Durée</h5>
                        <p className="card-text">{elearning?.duree} min</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card text-white bg-dark mb-3 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Nombres d'unités</h5>
                        <p className="card-text">{nbUnites}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card text-white bg-dark mb-3 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Validation</h5>
                        <p className="card-text">{elearning?.validation}%</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card text-white bg-dark mb-3 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Score actuel</h5>
                        <p className="card-text">{score}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-details row mt-8 m-1">
            <div className="col-md-12">
              <div className={`titre-methode-vente pt-4 no-padding `}>
                <div
                  className="flex"
                  style={{ justifyContent: "space-between" }}
                >
                  {" "}
                  <Hn
                    title="Détails de la formation"
                    level="h4"
                    className="mb-3"
                    plus={false}
                  />
                  <a
                    href="javascript: return false;"
                    onClick={() => setOpenDetails(!openDetails)}
                  >
                    Voir tout
                  </a>
                </div>
                <div
                  className="accordion"
                  style={{ display: openDetails ? "block" : "none" }}
                >
                  {elearning?.prerequis ? (
                    <>
                      <Hn
                        title="Prérequis et public visé"
                        level="h5"
                        className="mb-2"
                        plus={false}
                      />
                      <p className="simpletext ">
                        {elearning?.prerequis?.split(";").map((it, i) => {
                          if (it !== "") {
                            return (
                              <Hn
                                title={it}
                                className="py-1 text-white"
                                key={i}
                              />
                            );
                          }
                        })}
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                  <div
                    className="flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    {" "}
                    <div>
                      {elearning?.programme_de_formation && 
                        <Hn
                          title="Programme"
                          level="h5"
                          className="mb-2 mt-3"
                          plus={false}
                        />
                      }
                    </div>
                    {elearning?.fichier_programme !== null &&
                      elearning?.fichier_programme != "null" && (
                        <div>
                          <a
                            href={
                              process.env.REACT_APP_PIC_ELEARNING_FILE +
                              elearning?.fichier_programme
                            }
                            target="_blank"
                            className="donwload mb-2 mt-3"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19"
                              height="19"
                              fill="currentColor"
                              class="bi bi-download"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>{" "}
                            Télécharger le programme
                          </a>
                        </div>
                      )}
                  </div>

                  <p className="simpletext ">
                    {elearning?.programme_de_formation
                      ?.split(";")
                      .map((it, i) => {
                        if (it !== "") {
                          return (
                            <Hn
                              title={it}
                              className="py-1 text-white"
                              key={i}
                            />
                          );
                        }
                      })}
                  </p>

                  <div style={{ justifyContent: "space-between" }}>
                    {" "}
                    {elearning?.commentaire !== null &&
                    elearning?.commentaire != "null" ? (
                      <p className="simpletext ">
                        <Hn
                          title="Commentaire"
                          level="h5"
                          className="mb-2 mt-3"
                          plus={false}
                        />
                        {elearning?.commentaire?.split(";").map((it, i) => {
                          if (it !== "") {
                            return (
                              <Hn
                                title={it}
                                className="py-1 text-white"
                                key={i}
                              />
                            );
                          }
                        })}
                      </p>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className={`more-info ${disabled}`}></div>
            </div>
          </div>

          <div className="table pt-5">
            <table className="table table-dark table-hover">
              <thead>
                <tr className="tr">
                  <th>Unité d'apprentissage</th>
                  <th>Durée</th>
                  <th>Documents associés</th>
                  <th>Score obtenu</th>
                  <th>Validation</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {scoreds?.map((scored, i) => {
                  return (
                    <UniteApprentissageItem
                      uniteApprentissage={scored}
                      key={i}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>

          <div></div>
        </div>
      </div>
    </div>
  );
}
