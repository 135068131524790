import axios from "axios";
import { useState } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import dateFormat from "dateformat";
import { hashRandom } from "react-hash-string";
import PopupFiltre from "../../components/popup/PopupFiltreSessions";
// import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createBrowserHistory } from "history";
import { Alert, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Hn from "../../components/common/Hn";
export default function Datatable({
  getSessions,
  sessions,
  setShow,
  show,
  actionName,
  setSessions,
}) {
  let history = createBrowserHistory({ forceRefresh: true });
  const [id, setId] = useState();
  let toastId = null;
  const [initialsession, setInitialsession] = useState([]);
  const [showfiltre, setShowfiltre] = useState(false);
  const [displayetat, setDisplayetat] = useState("false");
  const [eachEntry, setEachEntry] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { ExportCSVButton } = CSVExport;
  const uri = process.env.REACT_APP_BASE_URL;
  let input;

  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };
  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  function deleteSessions(id) {

    axios
      .delete(uri + "/session/" + id)
      .then(() => {
        displayToast("Csuccess", "Suppression effectuer avec success...");
        setTimeout(() => {
          getSessions();
        }, 3000);
      })
      .catch(() => {
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
    setShow(false);
  }
  function duplicateSession(id) {
  
    axios
      .post(`${uri}/session/duplicate/${id}`, headers)
      .then((response) => {
    
        displayToast("Csuccess", "Session dupliqué avec success...");
        setTimeout(() => {
          getSessions();
        }, 3000);
      })
      .catch((error) => {
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
  }
  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
  
  };
  function updateStatus(id,checked) { 
  let status={
    status:checked
  }
   
     axios
       .put(`${uri}/session/edit/comment/` + id, status, headers)
       .then((response) => {
       
       })
       .catch((error) => {
         console.log(error);
       });
      
   }
  function searchwithfiltre(val) {
    setInitialsession(sessions);
    
    setEachEntry(val);
    if (val.action===""
    && val.ville===""
    && val.datestart===""
    && val.dateend==="") {
    
      getSessions();
    //  setParticipant(initialparticipant)
    }
    else{
    setSessions([]);
      let sessionFilter =  sessions.filter(el =>    
      ( el?.training?.toLowerCase()=== val.action.toLowerCase()  && (val.action.length>0))
      || ( el?.location?.town?.toLowerCase().includes(val.ville.toLowerCase() )&& (val.ville.length>0))
      || (dateFormat(el?.dateStart, "dd/mm/yyyy") === val.datestart  && (val.datestart.length>0))
      || (dateFormat(el?.dateEnd, "dd/mm/yyyy") === val.dateend  && (val.dateend.length>0))

      )

   
        setSessions(sessionFilter)
     
    }
    
  }
  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push("/admin/session/show/"+row.id);
    },
  }
  const handleShowfiltre = () => {
    setShowfiltre(true);
  };
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }

  const columns = [
    {
      dataField: "status",
      text: "Actif",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <Form className=" form-switch">
            <Form.Check
              type="switch"
              id={row.id}
              onChange={(e) => {
                updateStatus(row.id,e.target.checked)
              }}  
              defaultChecked={row.status}
            />
           
          </Form>
        );
      },
    },
    {
      dataField: "actionName",
      text: "Action",
      csvText: "title",
      sort: true,
      formatter: (row) => {
        return <>{actionName}</>;
      },
    },
    {
      dataField: "location.town",
      text: "Ville",
      csvText: "ville",
      sort: true,
    },

    {
      dataField: "former.lastName",
      text: "Formateur",
      csvText: "formateur",
      sort: true,
    },
    {
      dataField: "contactSessions",
      text: "Inscrits",
      csvText: "inscrits",
      sort: true,
      formatter: (row) => {
        return <>{row?.length}</>;
      },
    },
    {
      dataField: "conditions",
      text: "Etat",
      csvText: "etat",
      sort: true,
    },
    {
      dataField: "actions",
      text: "",
      events: {
      onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="btn-group float-end" role="group" aria-label="Basic example">
            <a
              href={"/admin/update-session-formation-presentielle/" + row.id}
              // type="button"
              className="btn btn-link"
            >
              <img src={require("../../assets/icons/edit.png")} />
            </a>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                duplicateSession(row.id);
              }}
            >
              <img src={require("../../assets/icons/duplicate.png")} />
            </button>
            {localStorage.getItem("role") === "Super-admin" ? (
              <button
                type="button"
                className="btn"
                onClick={() => {
                  setId(row.id);
                  handleShow();
                }}
              >
                <img src={require("../../assets/icons/delete.png")} />
              </button>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    // hideSelectAll: true,
  };
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    const onFollowChanged = () => {
      this.setState({ isFollow: !this.state.isFollow });
     
    };

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Button
          onClick={() => {
            this.onFollowChanged(row);
          }}
        >
          Follow
        </Button>
      );
    };

    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };

  let rows = sessions.map((item, i) => item);
  return (
    <>
      <ToastContainer />
      <ToolkitProvider
        keyField="id"
        data={rows}
        columns={columns}
        exportCSV={{ onlyExportSelection: true, exportAll: false , fileName: 'Sessions.csv',}}
        search
      >
        {(props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter mb-5 mt-3">
              <div className="row">
             
                <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 rowgy">
                <div className="">
                <Hn title="Session de formation" level="h4" plus={false} />
                </div>
                  <div className="flexgy">
                    <div className="col-md-4  mr-1  px-0">
                      <div className="input-group">
                        <input
                          className="form-control height43"
                          type="search"
                          placeholder="Recherche"
                          onChange={(e) =>
                            props.searchProps.onSearch(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className=" px-0 myxs">
                      <div className="flex">
                        <div className="square">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={handleShowfiltre}
                          >
                            <img
                              src={require("../../assets/icons/filter.png")}
                            />
                          </button>
                        </div>
                        <PopupFiltre
                          showfiltre={showfiltre}
                          closefiltre={handleClosefiltre}
                          searchwithfiltre={searchwithfiltre}
                          filterEntry={eachEntry}
                          
                        />
                      
                        <div className="square">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept={".csv"}
                            className="btn btn-link"
                            //  onChange={handleOnChange}
                            hidden
                          />
                          <label for="file" type="button">
                            <img
                              src={require("../../assets/icons/download.png")}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="px10">
                      <div className="d-grid gap-2">
                        <a
                          href="/admin/create-session-formation"
                          className="btn btn-primary  btn-gy height43"
                          type="button"
                        >
                          Créer une session de formation
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div>
              <input
                className="form-control"
                type="text"
                value={inputSearch}
                
                onChange={e=>props.searchProps.onSearch(e.target.value)}
              />
              
            </div> */}
            {displayetat === "true" ? (
              <>
                <br></br>
                <Alert color="primary">Aucun resultat trouvé</Alert>
              </>
            ) : (
              ""
            )}
            <BootstrapTable
              {...props.baseProps}
              classes="table-dark"
              bordered={false}
              hover
              selectRow={selectRow}
              pagination={paginationFactory(options)}
              rowEvents={ tableRowEvents }
            />
          </div>
        )}
      </ToolkitProvider>

      <Modal show={show} onHide={handleClose} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            Etes-vous sûr(e) de vouloir supprimer{" "}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={handleClose} style={{ color: "#006FFF" }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-primary"
            onClick={(e) => deleteSessions(id)}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
