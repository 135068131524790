import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import Add from "../../../../../assets/icons/add.png";
import Play from "../../../../../assets/icons/Play.png";
import Hn from "../../../../../components/common/Hn";
import "./createUnite.css";
import { trackPromise } from 'react-promise-tracker';
import toast, { Toaster } from 'react-hot-toast';
import edit from "../../../../../assets/icons/edit.png";
import { Button, Modal, ProgressBar } from "react-bootstrap";


const validationSchema = Yup.object().shape({
    titre: Yup.string().required("Le titre est obligatoire"),
    document: Yup.mixed().nullable(),
});

function EditUniteDeFormation({ uniteToBeEdited }) {
    const [showModal, setShowModal] = useState(false);
    const uri = process.env.REACT_APP_BASE_URL;
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const fileRef = useRef();
    const [duree, setDuree] = useState('0');

    const onHandelStatus = (touched, error, value) => {
        if (error && touched) {
            return "danger";
        } else if (value) {
            return "info";
        } else {
            return "warning";
        }
    };


    const showModalFunction = (unite) => {
        setShowModal(unite);
    }

    const hideModal = () => {
        setShowModal(false);
    }



    const handleSubmit = (values) => {

        //upload
        const options = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent;

                let percent = Math.floor((loaded * 100) / total);

                if (percent < 100) {
                    setUploadPercentage(percent);
                }
            }
        };



        var data = new FormData();
        data.append('titre', values.titre);
        data.append('document', values.document);
        data.append('duree', duree);


        trackPromise(
            axios.post(
                uri + "/unite/" + uniteToBeEdited?.id + "/edit",
                data,
                options
            )
                .then(() => {
                    setUploadPercentage(100);

                    setTimeout(() => {
                        setUploadPercentage(0);
                    }, 1000);

                    values.titre = '';
                    values.document = null;
                    document.getElementById("formulaire-edit-unite").reset();
                    toast.success("Cette unité a bien été modifiée!");
                    window.location.reload();
                })
                .catch((err) => {
                    toast.error("Action non autorisée!");
                    console.log("err training", err);
                    setUploadPercentage(0);
                }));


    };

    function getDuration(control) {
        var video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          var duration = new Date(video.duration * 1000).toISOString().substring(14, 19);
          setDuree(duration);
        }
        video.src = URL.createObjectURL(control.files[0]);
    }

    useEffect(() => {

    }, [uniteToBeEdited]);


    return (
        <>
            <p><span className="cursor-pointer" onClick={(e) => showModalFunction(uniteToBeEdited)}><img src={edit} className="pe-1" /></span></p>
            <Modal show={showModal} onHide={(e) => hideModal()} className="deleteModal">
                <Modal.Header closeButton>
                    <Modal.Title className="modaltitle">
                        Modification de l'unité de formation "{uniteToBeEdited?.titre}"
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row g-3">
                        <Formik
                            initialValues={{
                                titre: uniteToBeEdited?.titre,
                                document: null,
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                            }) => (
                                <form className="row g-3" id="formulaire-edit-unite" onSubmit={handleSubmit}>

                                    <div className="col-md-6">
                                        <label className="form-label">Titre</label>
                                        <input
                                            status={onHandelStatus(
                                                touched.ref,
                                                errors.ref,
                                                values.ref
                                            )}
                                            type="text"
                                            className="form-control"
                                            id="titre"
                                            name="titre"
                                            placeholder="Ajouter un titre"
                                            onChange={handleChange("titre")}
                                            value={values.titre}
                                            onBlur={handleBlur("titre")}
                                            errors={errors.titre}
                                        />
                                        <ErrorMessage name="titre">
                                            {(msg) => <div className="text-danger">{msg}</div>}
                                        </ErrorMessage>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">
                                            Document <a href={process.env.REACT_APP_PIC_ELEARNING_FILE + uniteToBeEdited?.document} className="pe-1">Télécharger</a>
                                        </label>
                                        <span
                                            className="form-control"
                                            onClick={() => fileRef.current.click()}
                                        >

                                            <img src={Add} className="pe-1" />
                                            {fileRef?.current?.value ? fileRef?.current?.value : 'Ajouter un fichier'}
                                        </span>

                                        <input
                                            ref={fileRef}
                                            multiple={false}
                                            type="file"
                                            name="document"
                                            id="document"
                                            onChange={(event) => {
                                                setFieldValue("document", event.currentTarget.files[0]);
                                                getDuration(event.currentTarget)
                                            }}
                                            hidden
                                            accept=".mp4,.zip,.pdf,.mp3"
                                        />
                                        {uploadPercentage > 0 && (
                                            <div className="row mt-3">
                                                <div className="col pt-1">
                                                    <ProgressBar
                                                        now={uploadPercentage}
                                                        striped={true}
                                                        label={`${uploadPercentage}%`}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <ErrorMessage name="document">
                                            {(msg) => <div className="text-danger">{msg}</div>}
                                        </ErrorMessage>



                                    </div>
                                    <div className="col-md-6">
                                    </div>
                                    <div className="col-md-6">
                                        <p> Format : mp4, zip, pdf, mp3 </p>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <Button variant="" onClick={(e) => hideModal()} style={{ color: "#006FFF" }}>
                                                Annuler
                                            </Button>
                                        </div>

                                        <div className="col-md-6 text-right">
                                            <Button
                                                type="submit"
                                                variant="btn btn-primary"
                                                onClick={handleSubmit}
                                            >
                                                Modifier
                                            </Button>
                                        </div>
                                    </div>

                                </form>
                            )}
                        </Formik>

                    </div>
                </Modal.Body>

            </Modal>

        </>
    );
}

export default EditUniteDeFormation;
