import axios from "axios";

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Hn from "../../../../components/common/Hn";
import PopupFiltreFormer from "../../../../components/popup/PopupFiltreFormer";
import AxioServices from "../../../../services/AxioServices";
import "../face-training/crud/createFaceTraining.css";
import "../location/ListLocationTraining.css";
import ListFormerTrainingDataGrid from "./ListFormerTrainingDataGrid";

function ListFormer() {
  let history = useHistory();
  let toastId = null;
  const [former, setFormer] = useState([]);
  const [show, setShow] = useState(false);
  const [inputsearch, setSInputsearch] = useState(false);
  const [exports, setExports] = useState([]);
  const [id, setId] = useState();
  const [array, setArray] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [initialformer, setInitialformer] = useState([]);
  const [showfiltre, setShowfiltre] = useState(false);
  const [displayetat, setDisplayetat] = useState("false");
  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });
    setArray(array);
  };
  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
    searchwithfiltre(obj);
  };
  const uri = process.env.REACT_APP_BASE_URL;
  //filtre du formateur
  function searchwithfiltre(vals) {
    setInitialformer(former);
    if (vals) {
      setFormer([]);
      
      let query = `firstname=${vals.nom}&lastname=${vals.prenom}`;
      let req =
        uri+"/former/filter?" +
        query;
   
      axios
        .get(req)
        .then((result) => {
       
          if (result?.data.length > 0) {
            setFormer(result?.data);
            setDisplayetat("false");
          } else {
            setFormer(initialformer);
            setDisplayetat("true");
          }
        })
        .catch((err) => {
          console.log("err location", err);
          setDisplayetat("true");
        });
    }
  }
  //récupérer tous les formateurs
  function getFormer() {
    setFormer([]);
    axios
      .get(uri+"/former")
      .then((result) => {
       
        setFormer(result?.data);
      })
      .catch((err) => {
        console.log("err location", err);
      });
  }

  //rechercher un formateur 
  function findintable(e) {
    setFormer([]);
    
    setSInputsearch(e.target.value);
    if (e.target.value !== undefined) {
      setSInputsearch(e.target.value);
      axios
        .get(
          uri+"/former?search=" +
            e.target.value
        )
        .then((result) => {
          setFormer(result?.data);
         
        })
        .catch((err) => {
          console.log("err former", err);
        });
    } else if (inputsearch) {

      axios
        .get(
          uri+"/former?search=" +
            inputsearch
        )
        .then((result) => {
          setFormer(result?.data);
         
        })
        .catch((err) => {
          console.log("err former", err);
        });
    } else {
      getFormer();
    }
  }
  const navigateToDFormerDetails = (former) => {
  
    history.push(`/admin/former/${former?.id}`);
  };
  const handleShowfiltre = () => {
    setShowfiltre(true);
  };
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      console.log("Toast already active");
    }
  }

 

  useEffect(() => {
    setFormer([]);
    getFormer();
  }, []);

  return (
    <div className="container-fluid"><ToastContainer />
      <div className="row">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn
              title="Formations présentielles"
              level="h1"
              className="pt-5"
              plus={false}
            />
            <Hn title="formateurs" level="h2" className="pt-4" />
          </div>

          
          {/* lists-filter */}

          <div className="mt-20">
           
              <ListFormerTrainingDataGrid
                former={former}
                setFormer={setFormer}
                setShow={setShow}
                show={show}
                getFormer={getFormer}
              />
           
          </div>
        </div>
      </div>
    </div>
  );
}
export default ListFormer;
