import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Hn from "../common/Hn";
import Range from '../common/Range';
import "./popup.css";
export default function PopupFiltre({ showfiltre, closefiltre, searchwithfiltre, filterEntry }) {
  const initialInputState = {
    zip: filterEntry?.zip ?? "",
    name: filterEntry?.name ?? "",
    ville: filterEntry?.ville ?? "",
    minvalue: filterEntry?.minvalue ?? 0,
    maxvalue: filterEntry?.maxvalue ?? 10000
  };
  const [eachEntry, setEachEntry] = useState(initialInputState);
  const [minvalue, setMinvalue] = useState(0);
  const [maxvalue, setMaxvalue] = useState(10000);
  const {
    zip,
    name,
    ville
  } = eachEntry;

  function getvalueinput(e) {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  }
  const resetForm = () => {
    document.getElementById("filterForm").reset();
    setEachEntry({ zip: "", name: "", ville: "" })
    setMinvalue(0)
    setMaxvalue(10000)
  }
  useEffect(() => {
    setEachEntry(initialInputState);
  }, [showfiltre, closefiltre]);


  return (
    <Modal show={showfiltre}
      onHide={closefiltre}
      size="lg"
      className="filter">
      <Modal.Header closeButton>
        <Modal.Title className="px-3 nopad">
          <Hn title="Filtrer" level="h4"
            plus={false} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="filterForm">
          <div className="row px-3 nopad">
            <div className="col-md">
              <Hn title="Nom" level="h5"
                plus={false}
                className="label-popup" />
              <input type="text" className="form-control drop"
                onChange={getvalueinput} name="name" value={eachEntry.name} />
            </div>
            <div className="col-md">
              <Hn title="Ville" level="h5"
                plus={false}
                className="label-popup" />
              <input type="text" className="form-control drop"
                onChange={getvalueinput} name="ville" value={eachEntry.ville} />
            </div>
            <div className="col-md">
              <Hn title="Zip" level="h5"
                plus={false}
                className="label-popup" />
              <input type="text" className="form-control drop"
                onChange={getvalueinput} name="zip" value={eachEntry.zip} />
            </div>
          </div>
          {/* /row */}
          <br></br>

          <Hn title="Capacité d'accueil" level="h5"
            plus={false}
            className="label-popup" />
          <div className="row px-3">
            <Range
              setMinvalue={setMinvalue}
              setMaxvalue={setMaxvalue}
            />
          </div>
        </form>
        {/* /row */} </Modal.Body>
      <Modal.Footer>
        <div> <button className="btn btn-link resetBtn" onClick={closefiltre} style={{ color: "#006FFF" }}>
          Annuler
        </button></div>
        <div className="flex">  <button className="secondaryModal"
          onClick={() => {
            resetForm()
          }
          }
          disabled={eachEntry.name === "" && eachEntry.ville === "" && eachEntry.zip === ""  && minvalue === "" && maxvalue === "" ? "disabled" : ""}
        >
          Réinitialiser
        </button>
          <Button
            variant="primary"
            onClick={() => {
              closefiltre()
              searchwithfiltre({ minvalue, maxvalue, name, ville, zip });
            }}
          >
            Valider
          </Button></div>
      </Modal.Footer>

    </Modal>
  );
}