import axios from "axios";
import { useEffect, useState } from "react";
import Hn from "../../../../../components/common/Hn";
import ParticipantDatatable from './participantDatatable';


function Participant({setParticipe, participe, sessions}) {
 
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  };
  const uri = process.env.REACT_APP_BASE_URL;

  const [participant, setParticipant] = useState([]);
  const [show, setShow] = useState(false);

  

  // function getParticipant() {
  //   setParticipant([]);
  //   axios
  //     .get(uri+ "/participant")
  //     .then((result) => {
      
  //      setParticipant(result?.data);
  //     })
  //     .catch((err) => {
  //       console.log("err participant", err);
  //     });
  // }



  useEffect(() => {
   // getParticipant();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className=" container lists pe-4">
     

          <div className="pt-5">
          {/* {participant.length > 0 ? ( */}
              <ParticipantDatatable
                participant={participant}
                setParticipe={setParticipe} 
                participe={participe}
                sessions={sessions}
                setParticipant={setParticipant}
              />
             {/* ) : (
              " No Data found"
            )}  */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Participant;
