import { PublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
import { createBrowserHistory } from "history";
import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../context/AuthProvider";
import "./signin.css";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LOGIN_URL = "/auth";

export default function Connexion() {
  const { setAuth } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [connected, setConnected] = useState(false);
  const [success, setSuccess] = useState(false);
  const uri = process.env.REACT_APP_BASE_URL;
  let toastId = null;

  function checkAuth() {
    if (localStorage.getItem("user") && localStorage.getItem('role') === 'Super-admin') {
      history.push("/admin/dashboard");
    }
    if (localStorage.getItem("id")) {
      history.push("/Formations-e-learning");
    }
  }

  useEffect(() => {
    checkAuth();
    setErrMsg("");
  }, [user, pwd]);

  // PublicClientApplication c'est une instance 
  const msalInstance = new PublicClientApplication({
    auth: {
      tenantId: "49ee1bf3-16ec-4cba-b231-dd1d6f9b424f", // id app guyhoquet
      clientId: "0e828568-121c-4501-8b3c-02de3c754150",
      // clientId: '6263d0c0-6c21-4057-87f5-bec688909f25', // id app riadh
      // tenantId: 'common',
      authority:
        "https://login.microsoftonline.com/49ee1bf3-16ec-4cba-b231-dd1d6f9b424f", // Replace the last part with your tenant ID
      // authority:"https://login.microsoftonline.com/common",
      redirectUri: "https://preprod.222.guy-hoquet.com/loginProfil", // Must be the same in Azure AD portal, can be replace with an environment variable: process.env.REACT_APP_REDIRECT_URL
      postLogoutRedirectUri: "/",
      navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
      knownAuthorities: ["login.live.com"],
      protocolMode: "OIDC",
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  });
  const startLogin = async (e) => {
    e.preventDefault();

    var loginRequest = {
      scopes: ["User.Read"], // optional Array<string>
    };

    try {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
      }
      const loginResponse = msalInstance.loginPopup(loginRequest);
      const username = (await loginResponse).account.idTokenClaims;
      const token = (await loginResponse).accessToken;

      const resJson = {
        token: token,
        user: username,
      };

      getuserinfo(username.unique_name);

    } catch (err) {
      console.log("Authentication Failed.....");
      console.log(err);
    }
  };

  //récupérer les informations de user cherché par son mail 
  const getuserinfo = (user) => {

    axios({
      method: "get",
      url: process.env.REACT_APP_BASE_URL + "/participant/email/" + user,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        setConnected(response.data);
        console.log("setconnected", setConnected)
        let role = [];
        let agence = [];
        let ouinon = "non";
        // response?.data?.data.map((item) => {
        if (response?.data?.data === "Oui") {
          ouinon = "oui";
        }
        // });

        localStorage.setItem("elearning", ouinon);// setItem est utilisée pour ajouter une clé "elearning" et une valeur "ouinon" à localStorage.

        if (response.data.role === "Super-admin") {
          localStorage.setItem("ind", response?.data?.data.contactID);
          localStorage.setItem("id", response?.data?.data.id);

          localStorage.setItem("nom", response.data.data.nom);
          localStorage.setItem("prenom", response.data.data.prenom);
          localStorage.setItem("role", "Super-admin");
          localStorage.setItem("email", response.data.data.mailcontact);
          localStorage.setItem("AgenceID", response.data.data.AgenceID);
        } else if (response.data.role === "Formateur") {
          localStorage.setItem("nom", response.data.data.firstName);
          localStorage.setItem("prenom", response.data.data.lastName);
          localStorage.setItem("ind", response?.data?.data.contactID);
          localStorage.setItem("id", response?.data?.data.id);
          localStorage.setItem("email", response.data.data.mailcontact);
          localStorage.setItem("role", "Formateur");
          localStorage.setItem("AgenceID", response.data.data.AgenceID);
        } else {
          localStorage.setItem("nom", response.data.data[0].nom);
          localStorage.setItem("prenom", response.data.data[0].prenom);
          localStorage.setItem("ind", response?.data?.data[0].ContactID);
          localStorage.setItem("id", response?.data?.data[0].id);
          localStorage.setItem("email", response?.data?.data[0].mailcontact);
          localStorage.setItem("AgenceID", response.data.data[0].AgenceID);

          response?.data?.data.forEach((item) => {
            agence.push(item.name);
            if (item.fonction === "Agent commercial") {
              role.push("Apprenants");
            } else if (item.fonction === "Agent immobilier") {
              role.push("Responsable");
            } else if (item.fonction === "Assistant(e) commercial(e)") {
              role.push("Apprenants");
            } else if (item.fonction === "Conseiller Location") {
              role.push("Apprenants");
            } else if (item.fonction === "Conseiller Vente") {
              role.push("Apprenants");
            } else if (item.fonction === "Gestionnaire") {
              role.push("Responsable");
            } else if (item.fonction === "Associé") {
              role.push("Responsable");
            } else if (item.fonction === "Partenaire de contrat") {
              role.push("Responsable");
            } else if (item.fonction === "Responsable transaction") {
              role.push("Responsable");
            } else if (item.fonction === "Formateur") {
              role.push("Formateur");
            } else if (item.fonction === "Super-admin") {
              role.push("Super-admin");
            } else {
              role.push("Apprenants");
            }
            const uniqueArray = [...new Set(role)];
            const uniqueArray2 = [...new Set(agence)];
            localStorage.setItem("role", uniqueArray);
            localStorage.setItem("agence", uniqueArray2);

          });
        }
        history.push("/loginProfil");
      })
      .catch(function (error) {
        displayToast("Cerror", "Email n'existe pas dans notre systeme");
        localStorage.clear();
        console.log(error);
      });
  };
  //affichage d'une notification 
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {//toast.isActive c'est à dire si l'alerte est encore affiché dans le screen
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }

  //let history = useHistory();
  let history = createBrowserHistory({ forceRefresh: true });
  //pour se connecter
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      axios({
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/login",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: {
          username: user,
          password: pwd,
        },
      })
        .then(function (response) {
          if (response.data.token) {
            localStorage.setItem("user", JSON.stringify(response.data.token));
          }
          getuserinfo(user);
        })
        .catch(function (error) {
          setSuccess(true);
        });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <div className="container pt-5">
        <ToastContainer />
        <div className="row">
          <div className="signin">
            <section>
              <h1 className="text-center mb-3">Connexion</h1>
              {success && (
                <div className="alert text-danger ps-0" role="alert">
                  Email ou mot de passe est incorrect
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="form-label" htmlhtmlFor="username">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                  />
                </div>
                <div className="mb-4">
                  <label className="form-label">Mot de passe</label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                  />
                </div>
                <div className="form-check mb-4 pt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefaultt"
                  >
                    Se rappeler de moi
                  </label>
                </div>
                <br></br>
                <div className="d-grid gap-2">
                  <button type="submit" className="btn btn-primary">
                    Se connecter
                  </button>
                </div>
                <div className="d-grid gap-2">
                  <button
                    class="btn btn-primary microsoft"
                    onClick={(e) => {
                      startLogin(e);
                    }}
                  >
                    <object
                      type="image/svg+xml"
                      data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"
                      class="x-icon"
                    ></object>
                    Se connecter avec son compte Office 365
                  </button>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
