import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { object } from "yup";
import Hn from "../../../../../components/common/Hn";
import "./createFaceTraining.css";
import { useHistory } from "react-router-dom";
const uri = process.env.REACT_APP_BASE_URL;
let headers = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};
const validationSchema = object().shape({
 
})

function CreateFaceSession2() {
  const params = useParams();
  const [skills, setSkills] = useState([]);
  const [training, setTraining] = useState([]);

  //récupérer les compétences
  function getSkills()  {
    axios
    .get(uri+"/skills" ,headers)
    .then((result) => {
      setSkills(result?.data);
    })
    .catch((err) => {
      console.log("err skills", err);
    });
  }
  
  
  
  useEffect(() => {
    getSkills();

  
  }, []);
  let history = useHistory();
  //modifier une compétence 
  const handleSubmit = (values) => {
    
    
      axios
        .put(`${uri}/training/edit/skills/${params.id}`, values,headers)
        .then((response) => {
        
          history.push('/admin/showActionFormationPres/'+params.id);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    
   
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/Sessions">Formations elearning - Actions de formation</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Créer une action de formation
              </li>
            </ol>
          </nav>
          <div className="h-100 p-5 text-white bg-dark rounded-3">
            <Hn
              title="Créer une action de formation"
              level="h4"
              className="h4-size"
              plus={false}
            />
          <Hn
              title="Etape 3: Compétences associés"
              level="h5"
              className="h4-size"
              plus={false}
            />
          <div className="form-elements mt-2">
              <div className="mt-30">
                <Formik
                  initialValues={{
                    skill: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    /* and other goodies */
                  }) => (
                    <form className="row g-3" onSubmit={handleSubmit}>
                       <div className="col-md-6">
                        <label  className="form-label">
                        Référentiel de compétences
                        </label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          id="skill"
                          value={values.skill}
                          onChange={handleChange("skill")}
                          onBlur={handleBlur("skill")}
                          errors={errors.skill}
                        >
                          <option defaultValue>Sélectionner un référentiel</option>
                          {skills.map((skill, i) => {
                            return (
                              <option value={skill.id} key={i}>
                                {skill?.title}
                              </option>
                            );
                          })}
                        </select>
                        <ErrorMessage name="skill">
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                      </div>
                     
                      <div className="col-12 mt-5">
                      <a
                  href="/admin/Sessions"
                  className="btn btn-link text-decoration-none resetBtn"
                >
                  Annuler
                </a>
                        <button
                          type="submit"
                          className="btn btn-primary float-end"
                        >
                          Suivant
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
             
            </div>
          </div>
        </div>
        {/* lists */}
      </div>
    </div>
  );
}

export default CreateFaceSession2;
