import axios from "axios";
import dateFormat from "dateformat";
import { ErrorMessage, Formik } from "formik";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import { number, object, ref, string } from "yup";
import "./createFaceTraining.css";

import Icon from "../../../../../components/common/Icon";

function UniteApprentissage({ session, nbUnite, former, lieuFormation, ind,lesson }) {
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  const hours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
  const uri = process.env.REACT_APP_BASE_URL;
  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  let newstart = addDays(session.dateStart, nbUnite);
  const [modalShow, setModalShow] = useState(false);
  //const [ableEdit, setableEdit] = useState(false);
  const [unitiApprentissageName, setUnitiApprentissageName] = useState(lesson.name);
  const [doneDB, setDoneDB] = useState();
  const [datac, setDatac] = useState();
  function ModalForm(props) {
    const validationSchema = object().shape({
      // seat_capacityMin: number().required("Champ obligatoire."),
      capacityMax: number()
        .max(
          ref("capacityMax"),
          "Must be less than Capacité d'acccueil maximale"
        )
        .required("Champ obligatoire."),
      capacityMin: number()
        .min(
          ref("capacityMin"),
          "Must be more than Capacité d'acccueil minimale  "
        )
        .required("Champ obligatoire."),
     // former: string().required("Champ obligatoire."),
     // location: string().required("Champ obligatoire."),
    });
    const saveunite = (values) => {
      let data = {
        capacityMax: values.capacityMax,
        capacityMin: values.capacityMin,
        hourStartMorning: values.hourStartMorning,
        hourEndMorning: values.hourEndMorning,
        hourStartmidi: values.hourStartmidi,
        hourEndMidi: values.hourEndMidi,
        former: values.former,
        location: values.location,
        name: props?.unitiApprentissageName,
        session: props.id_session,
        date: props.date,
      };
   
      axios
        .put(`${uri}/lesson/edit/${ind}`, data, headers)
        .then((response) => {
  
          // getsession()
          setModalShow(false);
        
        })
        .catch((error) => {
          console.log("error");
        });
    };
    return (
      <Modal
        size="lg"
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        className="modalLession"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Unité d'apprentissage {props.nbUnite}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Formik
              initialValues={{
                capacityMin: lesson.capacityMin || session.seat_capacity_min,
                capacityMax: lesson.capacityMax || session.seat_capacity_max,
                hourStartMorning: lesson.hourStartMorning,
                hourEndMorning:lesson.hourEndMorning,
                hourStartmidi:lesson.hourStartmidi,
                hourEndMidi: lesson.hourEndMidi,
                former: lesson?.former?.id,
                location: lesson?.location?.id,
              }}
              validationSchema={validationSchema}
              onSubmit={saveunite}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form className="row g-3 unite" onSubmit={handleSubmit}>
                  <div className="col-md-4">
                    <label className="form-label">Heure de début matin</label>
                    {/* <select
                      className="form-select"
                      aria-label="Default select example"
                      id="hourStartMorning"
                      value={values.hourStartMorning}
                      onChange={handleChange("hourStartMorning")}
                      onBlur={handleBlur("hourStartMorning")}
                      errors={errors.hourStartMorning}
                    >
                      {hours.map((hour) => {
                        return <option value={hour}>{hour}h</option>;
                      })}
                    </select> */}
                     <input
                      type="time"
                      name="hourStartMorning"
                      className="form-control"
                      placeholder="00:00"
                      onChange={handleChange("hourStartMorning")}
                      value={values.hourStartMorning}
                      onBlur={handleBlur("hourStartMorning")}
                      errors={errors.hourStartMorning}
                    ></input>
                    <ErrorMessage name="hourStartMorning">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Heure de fin matin</label>
                    {/* <select
                      className="form-select"
                      aria-label="Default select example"
                      id="hourEndMorning"
                      value={values.hourEndMorning}
                      onChange={handleChange("hourEndMorning")}
                      onBlur={handleBlur("hourEndMorning")}
                      errors={errors.hourEndMorning}
                    >
                      {hours.map((hour) => {
                        return <option value={hour}>{hour}h</option>;
                      })}
                    </select> */}
                     <input
                      type="time"
                      name="hourEndMorning"
                      className="form-control"
                      placeholder="00:00"
                      onChange={handleChange("hourEndMorning")}
                      value={values.hourEndMorning}
                      onBlur={handleBlur("hourEndMorning")}
                      errors={errors.hourEndMorning}
                    ></input>
                    <ErrorMessage name="hourEndMorning">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Heure de début aprés-midi
                    </label>
                    {/* <select
                      className="form-select"
                      aria-label="Default select example"
                      id="hourStartmidi"
                      value={values.hourStartmidi}
                      onChange={handleChange("hourStartmidi")}
                      onBlur={handleBlur("hourStartmidi")}
                      errors={errors.hourStartmidi}
                    >
                      {hours.map((hour) => {
                        return <option value={hour}>{hour}h</option>;
                      })}
                    </select> */}
                    <input
                      type="time"
                      name="hourStartmidi"
                      className="form-control"
                      placeholder="00:00"
                      onChange={handleChange("hourStartmidi")}
                      value={values.hourStartmidi}
                      onBlur={handleBlur("hourStartmidi")}
                      errors={errors.hourStartmidi}
                    ></input>
                    <ErrorMessage name="hourStartmidi">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">
                      Heure de fin aprés-midi
                    </label>
                    {/* <select
                      className="form-select"
                      aria-label="Default select example"
                      id="hourEndMidi"
                      value={values.hourEndMidi}
                      onChange={handleChange("hourEndMidi")}
                      onBlur={handleBlur("hourEndMidi")}
                      errors={errors.hourEndMidi}
                    >
                      {hours.map((hour) => {
                        return <option value={hour}>{hour}h</option>;
                      })}
                    </select> */}
                      <input
                      type="time"
                      name="hourEndMidi"
                      className="form-control"
                      placeholder="00:00"
                      onChange={handleChange("hourEndMidi")}
                      value={values.hourEndMidi}
                      onBlur={handleBlur("hourEndMidi")}
                      errors={errors.hourEndMidi}
                    ></input>
                    <ErrorMessage name="hourEndMidi">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Lieux de formation</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      id="location"
                   
                      onChange={handleChange("location")}
                     // value={values.location}
                      onBlur={handleBlur("location")}
                      errors={errors.location}
                    >
                    
                      {lieuFormation?.map((lieu, i) => {
                        return <option 
                        value={lieu.id}
                        selected={
                        lieu?.id === lesson?.location?.id
                        }
                        key={i}
                        >{lieu?.address}</option>;
                      })}
                    </select>
                    <ErrorMessage name="location">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Capacité d'acccueil minimale
                    </label>
                    <input
                      type="number"
                      name="capacityMin"
                      className="form-control"
                      placeholder="00"
                      onChange={handleChange("capacityMin")}
                      value={values.capacityMin}
                      onBlur={handleBlur("capacityMin")}
                      errors={errors.capacityMin}
                    ></input>
                    <ErrorMessage name="capacityMin">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Capacité d'acccueil maximale
                    </label>
                    <input
                      type="number"
                      name="capacityMax"
                      className="form-control"
                      id="capacity-max"
                      placeholder="00"
                      onChange={handleChange("capacityMax")}
                      value={values.capacityMax}
                      onBlur={handleBlur("capacityMax")}
                      errors={errors.capacityMax}
                    />
                    <ErrorMessage name="capacityMax">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Formateur</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      id="actif"
                    >
                      <option selected>sélectinner dans la liste</option>
                      <option selected>Interne</option>
                      <option selected>Externe</option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">&nbsp;</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      id="former"
                   
                      onChange={handleChange("former")}
                      onBlur={handleBlur("former")}
                      errors={errors.former}
                    >
                      <option defaultValue>sélectionner dans la liste</option>
                      {former?.map((tariner, i) => {
                        return (
                          <option value={tariner?.id}
                          selected={
                            tariner?.id === lesson?.former?.id
                            }
                            key={i}
                          >
                            {tariner?.lastName}
                          </option>
                        );
                      })}
                    </select>
                    <ErrorMessage name="former">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 mt-5">
                    <Button
                      variant="btn btn-link resetBtn"
                      onClick={props.onHide}
                    >
                      Annuler
                    </Button>
                    <Button type="submit" variant="btn btn-primary float-end">
                      Valider
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <div className="col-md-6">
      <div className="cardUnit text-white  mb-3 p-3">
        <div className="card-body ">
          <h5 className="card-title">Unité d'apprentissage {nbUnite} </h5>
          <div className="mt-4">
            <label className="form-label">
              Nom de l'unité d'apprentissage{" "}
            </label>
            {/* {doneDB === "done" ? (
              <>
                <br></br>
                {datac.name}
              </>
            ) : ( */}
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Ajouter un nom"
               defaultValue={lesson.name}
               
                onChange={(e) => {
                  setUnitiApprentissageName(e.target.value);
                  //setableEdit(true);
                }}
              />
            {/* )} */}
          </div>
          <div className="pt-3">
            <Icon
              name="localisation"
              data={
                lesson?.location?.address +
                ", " +
                lesson?.location?.zip +
                " " +
                lesson?.location?.town
              }
            />
            <ul className="list-inline">
              <li className="list-inline-item">
                <Icon
                  name="Calendar"
                  data={dateFormat(newstart, "dd/mm/yyyy")}
                />
              </li>
              <li className="list-inline-item">
                <Icon name="Clock" data={lesson?.hourStartMorning+"h/"+lesson?.hourEndMorning+"h/"+lesson?.hourStartmidi+"h/"+lesson?.hourEndMidi+"h/"} />
              </li>
              <li className="list-inline-item">
                <Icon
                  name="profil"
                  data={
                    lesson.former.firstName + " " + lesson.former.lastName
                  }
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="card-footer bg-transparent">
          <button
            className="styleNone"
            // onClick={() =>
            //   ableEdit ? setModalShow(true) : setModalShow(false)
            // }
            onClick={() =>
             setModalShow(true)
            }
          >
            <Icon name="edit" />
          </button>
        </div>
      </div>
      <ModalForm
        show={modalShow}
        id_session={session.id}
        date={newstart}
        unitiApprentissageName={unitiApprentissageName}
        nbUnite={nbUnite}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}
export default UniteApprentissage;
