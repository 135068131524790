import { useState, useEffect, useRef } from "react";
import { trackPromise } from "react-promise-tracker";
import { useHistory, useParams } from "react-router-dom";
import Chart2 from "../../components/chartjs/Chart2";
import Hn from "../../components/common/Hn";
import PopupVideo from "../../components/popup/PopupVideo";

import Placeholder from "../../assets/placeholder.png";
import axios from "axios";
import Iframe from "react-iframe";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player/lazy";
import toast, { Toaster } from "react-hot-toast";
import logoDoc from "../../assets/logodoc.png";
import JoditEditor from "jodit-react";
import { Editor } from "@tinymce/tinymce-react";
export default function DetailsWebinar({}) {
  const [video, setVideo] = useState(false);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [unite, setUnite] = useState();
  const [content_url, setContentUrl] = useState(false);
  const [title, setTitle] = useState("");
  const [showModalBool, setShowModalBool] = useState(false);
  const [showModalInscri, setShowModalInscri] = useState(false);
  const [showfiltre, setShowfiltre] = useState(false);
  const [elearning, setElearning] = useState([]);
  const [validated, setValidated] = useState(0);
  const [nbUnites, setNbUnites] = useState(0);
  const [scoreds, setScoreds] = useState([]);
  const [play, setPlay] = useState([]);
  const [score, setScore] = useState();
  const [openDetails, setOpenDetails] = useState(false);
  const [elearnings, setElearnings] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [check, setCheck] = useState(0);

  const config = {
    // all options from https://xdsoft.net/jodit/doc/,
    readonly: false,
    placeholder: "...",
    theme: "dark",
    hidePoweredByJodit: true,
    height: 400,
    defaultActionOnPaste: "INSERT_AS_HTML",
    processPasteHTML: true,
    processPasteHTML: true,
    editHTMLDocumentMode: true,
  };

  const params = useParams();
  const [webinar, setWebinar] = useState([]);
  let history = useHistory();

  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const uri = process.env.REACT_APP_BASE_URL;

  const data = {
    participant: localStorage.getItem("id"),
  };

  const handleClosefiltre = () => {
    setShowfiltre(false);
  };
//lister un éditeur par son id
  function getEditor() {
    trackPromise(
      /*local*/ axios
        .get(uri + "/webinars/" + params.id)
        .then((result) => {
       
          setTitle(result?.data?.editor?.title);
          setContent(result?.data?.editor?.source);
        })
        .catch((err) => {
          console.log("err sessions", err);
        })
    );
  }

  //lister un webinaire par son id
  function getWebinars() {
    trackPromise(
      /*local*/ axios
        .get(uri + "/webinars/" + params.id)
        .then((result) => {
          setWebinar(result?.data);
         
        })
        .catch((err) => {
          console.log("err webinars", err);
        })
    );
  }

  // vérifier si un participant est inscrit dans ce webianire récupéré par son id ou non  si return 1 donc le participant déja inscrit dans ce webinbaire choisi sinon c'est le cas inverse 
  function getInscriWeb() {
    trackPromise(
      axios
        .get(
          uri +
            `/webinars/${params.id}/check/particiapnt/${localStorage.getItem(
              "id"
            )}`
        )
        .then((result) => {
          setCheck(result?.data);
         
        })
        .catch((err) => {
          console.log("err webinars", err);
        })
    );
  }
//ajouter (inscrire) un nouveau participant au webinaire crée 
  function inscrire() {
    axios
      .put(`${uri}/webinars/edit/particiapnt/` + params.id, data, headers)
      .then((response) => {
        setTimeout(() => {
          history.go(0);
        }, 100);
      })
      .catch((error) => {
        console.log("error", error);
        // displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
  }
  function handleChange(content, editor) {
    setContent(content);
  }

  useEffect(() => {
    getWebinars();
    getInscriWeb();
  }, []);

  const showModal = () => {};

  const hideModal = () => {
    setShowModalBool(false);
    setShowModalInscri(false);
  };

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="details pt-5 px-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/webinair">Webinaires</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {webinar?.title}
              </li>
            </ol>
            <div>
             
              <a href="#" onClick={()=>history.go(-1)} >Retour</a>
              {webinar && (
                <Modal
                  show={showModalBool}
                  onHide={() => hideModal()}
                  className=""
                  fullscreen={true}
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <div>
                      <img src={logoDoc} width="150" />
                      <Hn
                        title={webinar?.editor?.title}
                        level="h4"
                        plus={false}
                        className="pb-3"
                      />
                    </div>
                    <div>
                      {/* convert HTML CODE */}
                      
                    </div>
                    <div className=" col-md-12 pb-3">
                      {/* <JoditEditor
                        ref={editor}
                        value={webinar?.editor?.source}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) => {
                          setContent(newContent);
                        }}
                      /> */}
                      <div dangerouslySetInnerHTML={{ __html: webinar?.editor?.source }} />
                    </div>
                  </Modal.Body>
                </Modal>
              )}

              <Modal
                show={showModalInscri}
                onHide={() => hideModal()}
                size="lg"
                className="special-width-popup"
              >
                <Modal.Header closeButton>
                  <Modal.Title className="px-3 nopad">
                    <Hn title="Félicitations!" level="h3" plus={false} />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row px-3 nopad">
                    <div className="col-md-12">
                      <Hn
                        title="Nous vous confirmons l'inscription de :"
                        level="h5"
                        plus={false}
                        className="label-popup"
                      />
                      <p className="text-white textBox">
                        {localStorage.getItem("nom")}
                        {localStorage.getItem("prenom")}
                      </p>
                    </div>
                    <div className="pt-2">
                    <Hn
                        title="Webinaire :"
                        level="h5"
                        plus={false}
                        className="label-popup"
                      />
                      <p className="text-white textBox">
                        {webinar?.title}
                        {localStorage.getItem("prenom")}
                      </p>
                    </div>
                  </div>
                  {/* /row */}
                  <br></br>
                  {/* /row */}{" "}
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-primary float-end" onClick={() => {hideModal(); inscrire()}}>
                    Confirmer
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </nav>
          <div className="head-details">
            <div className="row">
              <div className="col-md-9">
                <Hn
                  title={webinar?.intitule}
                  level="h1"
                  dispaly
                  className="pt-4"
                  plus={false}
                />

                <p className="text text-white py-4">
                  <span>

                    {webinar.obligation
                      ? " Participe à l’obligation de formation "
                      : ""}

                    {webinar.manager_spec ? "  Spécifique au manager" : ""}
                    {webinar.forward_catalog
                      ? "  Mise en avant dans le catalogue"
                      : ""}
                  </span>
                </p>
              </div>
              <div className="col-md-3">
                {check != 0 ? (
                  <button
                    className="btn btn-primary float-end"
                    onClick={() => {
                      setShowModalBool(true);
                    }}
                  >
                    Reprendre
                  </button>
                ) : (
                  <button
                    className="btn btn-primary float-end"
                    onClick={() => setShowModalInscri(true)}
                  >
                    S'inscrire
                  </button>
                )}
              </div>

              {/* <PopupVideo
                showfiltre={showfiltre}
                closefiltre={handleClosefiltre}
                play={play}
                run={params.run}
              /> */}
            </div>
          </div>
          <div className="body-details pb-5">
            <div className="row">
              <div>
                <div className="row p-3">
                  <div className="col-md-8 border-end">
                    <Hn
                      title="Objectifs"
                      level="h4"
                      plus={false}
                      className="pb-3"
                    />

                    <p className="simpletext ">
                      {webinar?.objects?.split(";").map((it, i) => {
                        if (it !== "") {
                          return <Hn title={it} className="py-1 text-white" key={i}/>;
                        }
                      })}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="justify-content-between rest ">
                      <div className="align-self-center" style={ {lineHeight: 6}}>
                        <Hn
                          title={
                            parseInt(
                              Math.abs(
                                webinar?.capacity_max -
                                  webinar?.contacts?.length
                              )
                            ) + " places restantes "
                          }
                          plus={false}
                          className="text-white text-center"
                         
                        />
                      </div>
                      <div className="align-self-center"></div>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div className="box-details row mt-8 m-1">
            <div className="col-md-12">
              <div className={`titre-methode-vente pt-4 no-padding `}>
                <div
                  className="flex"
                  style={{ justifyContent: "space-between" }}
                >
                  {" "}
                  <Hn
                    title="Détails de la formation"
                    level="h4"
                    className="mb-3"
                    plus={false}
                  />
                  <a
                    href="javascript: return false;"
                    onClick={() => setOpenDetails(!openDetails)}
                  >
                    Voir tout
                  </a>
                </div>
                <div
                  className="accordion"
                  style={{ display: openDetails ? "block" : "none" }}
                >
                  <Hn
                    title="Prérequis et public visé"
                    level="h5"
                    className="mb-2"
                    plus={false}
                  />
                  <p className="simpletext ">
                    {webinar?.audience?.split(";").map((it, i) => {
                      if (it !== "") {
                        return <Hn title={it} className="py-1 text-white" key={i}/>;
                      }
                    })}
                  </p>
                  <div
                    className="flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    {" "}
                    <Hn
                      title="Programme"
                      level="h5"
                      className="mb-2 mt-3"
                      plus={false}
                    />
                    {webinar?.programFile !== null &&
                      webinar?.programFile != "null" && (
                        <a
                          href={
                            process.env.REACT_APP_PIC_WEBINAR_FILE +
                            webinar?.programFile
                          }
                          target="_blank"
                          className="donwload mb-2 mt-3"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="19"
                            fill="currentColor"
                            class="bi bi-download"
                            viewBox="0 0 16 16"
                          >
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                          </svg>{" "}
                          Télécharger le programme
                        </a>
                      )}
                  </div>
                  <p className="simpletext ">
                    {webinar?.training_program?.split(";").map((it, i) => {
                      if (it !== "") {
                        return <Hn title={it} className="py-1 text-white" key={i}/>;
                      }
                    })}
                  </p>

                  <div style={{ justifyContent: "space-between" }}>
                    {" "}
                    <Hn
                      title="Commentaire"
                      level="h5"
                      className="mb-2 mt-3"
                      plus={false}
                    />
                    <p className="simpletext ">
                      {webinar?.comment?.split(";").map((it, i) => {
                        if (it !== "") {
                          return <Hn title={it} className="py-1 text-white" />;
                        }
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col"></div>
          </div>

          <div></div>
        </div>
      </div>
    </div>
  );
}
