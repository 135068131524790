import axios from "axios";

import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Hn from "../../../../components/common/Hn";

import "./faceTraining.css";
import SessionDatatable from "./SessionDatatable.js";

function FaceSessions() {
  let history = useHistory();
  let toastId = null;
  const [sessions, setSessions] = useState([]);
  const [initialsession, setInitialsession] = useState([]);
  const [showfiltre, setShowfiltre] = useState(false);
  const [displayetat, setDisplayetat] = useState("false");
  const [inputSearch, setInputSearch] = useState("false");
  const [id, setId] = useState();
  const [show, setShow] = useState(false);
  const uri = process.env.REACT_APP_BASE_URL;
  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
    searchwithfiltre(obj);
  };

  //filtre d'une session
  function searchwithfiltre(vals) {
    setInitialsession(sessions);
    if (vals) {
      setInitialsession([]);
      // if (vals.minvalue === undefined) {
      //   vals.minvalue = 0;
      // }
      // if (vals.maxvalue === undefined) {
      //   vals.maxvalue = 100;
      // }
      let query = `action=${vals.action}&town=${vals.ville}&datestart=${vals.datestart}&dateend=${vals.dateend}`;
      let req = uri + "/session/filter?" + query;
   
      axios
        .get(req)
        .then((result) => {
       
          if (result?.data.length > 0) {
            setSessions(result?.data);
            setDisplayetat("false");
          } else {
            setSessions(initialsession);
            setDisplayetat("true");
          }
        })
        .catch((err) => {
          console.log("err session filter", err);
          setDisplayetat("true");
        });
    }
  }
  const handleShowfiltre = () => {
    setShowfiltre(true);
  };
  useEffect(() => {
    getSessions();
  }, []);

  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }
//récupérer toutes les sessions des formations présentielles
  function getSessions() {
    axios
      .get(uri + "/session")
      .then((result) => {
        setSessions(result?.data);
      })
      .catch((err) => {
        console.log("err  session", err);
      });
  }

  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn
              title="Formations présentielles"
              level="h1"
              className="pt-5"
              plus={false}
            />
            <Hn title="sesssions de formation" level="h2" className="pt-4" />
          </div>
          {/*  */}
          {/* lists-filter */}
          {displayetat === "true" ? (
            <>
              <br></br>
              <Alert color="primary">Aucun resultat trouvé</Alert>
            </>
          ) : (
            ""
          )}
          <div className="mt-20">
            
              <SessionDatatable
                setShow={setShow}
                show={show}
                sessions={sessions}
                getSessions={getSessions}
                inputSearch={inputSearch}
                setSessions={setSessions}
              />
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaceSessions;
