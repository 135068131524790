import { useEffect, useState } from "react";
import dateFormat from "dateformat";
import axios from "axios";
import Hn from "../../components/common/Hn";
import Jumbotron from "../../components/jumbotron/Jumbotron";
// import Listformation from "../../components/owl/Listformation";
import Mostformation from "../../components/owl counter/Mostformation";
import "./email.css";


// const columns = [
//   {
//     name: 'Expéditeur',
//     selector: row => row.expediteur,
//     id: "tableCell",
//     style: {
//       background: "#212730",
//       color: "white",
//       fontSize: "16px",
//       borderBottom: "1px solid #373D45"
//     },
//   },
//   {
//     name: 'Date',
//     selector: row => row.date,
//     id: "tableCell",
//     style: {
//       background: "#212730",
//       color: "white",
//       fontSize: "16px",
//       borderBottom: "1px solid #373D45"
//     },
//   },
// ];

// const data = [
//   {
//     id: 1,
//     expediteur: "L'équipe formation",
//     date: '26/02/2022',
//   },
//   {
//     id: 2,
//     expediteur: "L'équipe formation",
//     date: '26/02/2022',
//   },
// ]

const ExpandedComponent = ({ data }) => <pre>{data.expediteur} | {data.date}</pre>;

export default function Email() {
  const [selectedItem, setSelectedItem] = useState();
  const [trainingMostSorted, setTrainingMostSorted] = useState([]);
  const [emails, setEmails] = useState([]);
  const [nbmsg,setNb]=useState()
  const IMG_URL = process.env.REACT_APP_PIC_FACETRAINING_PATH;
  const uri = process.env.REACT_APP_BASE_URL;
 
  const LineEmail = ({ data,setSelectedItem }) => {
    return (  <>
      <tr  onClick={() => {
      
        setSelectedItem(data);
        luEmail(data.id)
      }}>
                      <td className={data?.view ? "" :"fw-bold"}>{data?.sender}</td>
                      <td  className={data?.view ? "":"fw-bold"}>{dateFormat(data?.createdAtt, "dd/mm/yyyy")}</td>
                      <td  className={data?.view ? "":"fw-bold"}>{data?.object}</td>
                    </tr>
    </>
    )}

    //récupérer les documents du user en question
  function getEmails() {
   let nb=[];
      axios
        .get(uri + "/document/convention/"+ localStorage.getItem("ind"))
        .then((result) => {
          setEmails(result?.data);
          setSelectedItem(result?.data[0])
         luEmail(result?.data[0].id)
         setNb(result?.data.filter((el)=>el.view===false).length)
       //  setNb(nb.length) 
        })
        .catch((err) => {
          console.log("err  attestations", err);
        });
    
  }

  //récupérer(notification) le nombre des messages lus
  function luEmail(id){
    axios
    .post(uri + "/document/notify/"+id)
    .then((result) => {
    })
    .catch((err) => {
      console.log("err lu email", err);
    });
  }
  useEffect(() => {
    getEmails();
  }, []);

  //récupérer les TOP 10 selon le nombre d’inscrit aux sessions
  function getTrainingMost() {
    axios
      .get(uri + "/training")
      .then((result) => {
        let trainingMostSortedD = result?.data;

        trainingMostSortedD.sort(function (a, b) {
          return b.sessions.length - a.sessions.length;
        });

        setTrainingMostSorted(trainingMostSortedD);

      })
      .catch((err) => {
        console.log("err training", err);
      });
  }

  useEffect(() => {
    getTrainingMost();
  }, []);

  return (
    <div className="container-fluid mt-5">
      <div className="row">
        <div className="email">
          <Hn title="Mes documents" level="h1" plus={false} />
          <h2 className="title-email">{nbmsg} message(s) non lu(s)</h2>
          <div className="row pt-3">
            <div className="col-lg-6 col-md-12 col-xs-12">
             <Jumbotron data={selectedItem} type={selectedItem?.type} />
            </div>

            <div className="col-lg-6 col-md-12 col-xs-12 ">
              <table className="table table-dark">
                <thead>
                  <tr className="tr">
                    <th>Expéditeur</th>
                    <th>Date</th>
                    <th>Objet</th>
                  </tr>
                </thead>
                <tbody>
                 
                {emails?.map((email, i) => (
                 
                  <LineEmail data={email} setSelectedItem={setSelectedItem} key={i}></LineEmail>
                
                ))}
                </tbody>
              </table>

            </div>
          </div>
          <Hn title="Top 10 des formations " level="h4" className="mt-5"/>
          <Mostformation
            details={"detailformation"}
            data={trainingMostSorted.slice(0, 10)}
            url={IMG_URL}
            carousel
            switchs
          />
        </div>
      </div>
    </div>
  );
}
