import { useEffect, useState } from "react";
import Hn from "../../components/common/Hn";
import "../admin/forms/face-training/crud/createFaceTraining.css";
import "../admin/forms/location/ListLocationTraining.css";
import ListWebinarDataGrid from "./ListWebinarDataGrid";

function ListWebinair() {
  const [displayetat, setDisplayetat] = useState("false");
  const [show, setShow] = useState(false);


  const uri = process.env.REACT_APP_BASE_URL;


  return (
    <div className="container-fluid">
      <div className="row">

        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn title="Webinaires" level="h1" className="pt-5" plus={false} />
          </div>
         


          <ListWebinarDataGrid
              
            
              />


        </div>
      </div>
    </div>
  );
}
export default ListWebinair;
