import axios from "axios";
import { useState, useEffect } from "react";
import range from "lodash.range";
import Papa from "papaparse";
import Modal from "react-bootstrap/Modal";
import { toast, ToastContainer } from 'react-toastify';
import PopupFiltre from "../../../../components/popup/PopupFiltreParticipant";
import AxioServices from "../../../../services/AxioServices";
// import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createBrowserHistory } from "history";
import { Alert, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  CSVExport
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

export default function Datatable({
  setShow,
  show,
  total,
  from
}) {
  let history = createBrowserHistory({ forceRefresh: true });


  const [currentPage, setCurrentPage] = useState(1);
  let toastId = null;
  const [id, setId] = useState();
  const [participant, setParticipant] = useState([]);
  const [showfiltre, setShowfiltre] = useState(false);
  const [displayetat, setDisplayetat] = useState("false");
  const [eachEntry, setEachEntry] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { ExportCSVButton } = CSVExport;
  const uri = process.env.REACT_APP_BASE_URL;
  const LEFT_PAGE = 'LEFT';
  const RIGHT_PAGE = 'RIGHT';
  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      console.log("Toast already active");
    }
  }
  useEffect(() => {
    getPage(1);
  }, []);
  let nb = range(1, total / 50);
  const handleClosefiltre = (obj) => {
    setShowfiltre(false);

  }; const handleShowfiltre = () => {
    setShowfiltre(true);
  };
  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);

    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {

        AxioServices.create("participant/import", JSON.stringify(results.data), {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*",
          },
        })
          .then((result) => {
            displayToast("Csuccess", "Importation en cours, Redirection...");
        
            // setTimeout(() => {
            //   history.go(0);
            // }, 5000);
          })
          .catch((err) => {
            displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
          });
      },
    });
  };
  const fetchPageNumbers = () => {
    const totalPages = Math.ceil(total / 50);
    // const currentPage = currentPage;
    const pageNeighbours = 3;

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = (pageNeighbours * 2);
    const totalBlocks = totalNumbers;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);

      //totalPages delete -1 
      const endPage = Math.min(totalPages, currentPage + pageNeighbours);
      let pages = range(startPage, endPage  );

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = (totalPages - endPage) > 1;
      const spillOffset = totalNumbers - (pages.length);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill): {
          const extraPages = range(startPage - spillOffset, startPage);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case (!hasLeftSpill && hasRightSpill): {
          const extraPages = range(endPage, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  }
  let pages = fetchPageNumbers();


  const getPage = (item) => {
    setParticipant([]);
    axios
      .get(uri + "/participant/pagination/?page=" + item)
      .then((result) => {
        setParticipant(result?.data?.data);

      })
      .catch((err) => {
        console.log("err participant", err);
      });
  }
  function searchParticipant(value) {
    //  setInitialParticipant([]);
    if (value) {
      setParticipant([])
      let req = uri + "/participant/?search=" + value;
      axios
        .get(req)
        .then((result) => {

          if (result?.data.length > 0) {
            setParticipant(result?.data);
          } else {
            getPage(1)
          }
        })
        .catch((err) => {
          console.log("err search", err);
        });

    } else {
      getPage(1)
    }

  }
  function searchwithfiltre(val) {
    setParticipant([])
    // setInitraining(participant);

    setEachEntry(val);
    if (val.collaborator === "" && val.email === "" && val.emailAgent === "" && val.email === "") {

      // getParticipant();
    } else {
      let query = `collaborator=${val.collaborator}&agent=${val.agent}&offre=${val.offre}&manager=${val.agence}`;
      let req = uri + "/participant/filter?" + query;
  
      axios
        .get(req)
        .then((result) => {

          if (result?.data.length > 0) {
            setParticipant(result?.data);
          } else {
            setParticipant([])
          }
        })
        .catch((err) => {
          console.log("err filter", err);
        });
    }
  }
  // function searchwithfiltre(val) {
  //   setInitialParticipant(participant);
  //   setEachEntry(val);

  //   if (val.collaborator === "" && val.email === "" && val.emailAgent === "" && val.email === "") {

  //     getParticipant();
  //   } else {
  //     setParticipant([]);
  //     let participantsFilter = participant.filter(el =>
  //       (el.apprenant.toLowerCase().includes(val.collaborator.toLowerCase()) && (val.collaborator.length > 0))
  //       || el?.mailcontact.toLowerCase() === val.email.toLowerCase()
  //       || (el?.agencymain.toLowerCase().includes(val.agent.toLowerCase()) && (val.agent.length > 0))
  //       || el?.agenceEmail.toLowerCase() === val.emailAgent.toLowerCase()
  //     )

  //     setParticipant(participantsFilter)

  //   }

  // }
  function deleteParticpant(id) {

    axios.delete(`${uri}/participant/` + id).then(() => {
      // getParticipant();
    });
    setShow(false);
  }

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push("/admin/apprenant/" + row.ContactID + "/" + from || "");

    },
  }

  const columns = [
    {
      dataField: "apprenant",
      text: "Collaborateur",
      csvText: "collaborator",
      sort: true,


    },
    {
      dataField: "mailcontact",
      text: "Email",
      csvText: "email",
      sort: true,
      width: 50,
      className: "cellTable"
    },
    {
      dataField: "agencymain",
      text: "Agent",
      csvText: "agent",
      sort: true,
    },
    {
      dataField: "agenceEmail",
      text: "Email Agent",
      csvText: "emailAgent",
      sort: true,
    },
    {
      dataField: "offre",
      text: "Offre",
      csvText: "offre",
      sort: true,
    },
    {
      dataField: "metier",
      classes:"text-center",
      text: "Manager",
      csvText: "manager",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <span>{row.metier === "Manager" ? "Oui" : "Non"}</span>;
      },
    },
    // {
    //   dataField: "actions",
    //   text: "",
    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       e.stopPropagation();
    //     },
    //   },
    //   formatter: (cell, row, rowIndex, formatExtraData) => {
    //     return (<>
    //       {localStorage.getItem("role") === "Super-admin" ? (
    //         <div className="btn-group" role="group" aria-label="Basic example">
    //           <button
    //             type="button"
    //             className="btn"
    //             onClick={() => {
    //               setId(row.id);
    //               handleShow();
    //             }}
    //           >
    //             <img src={require("../../../../assets/icons/delete.png")} />
    //           </button>
    //         </div>
    //       ) : (
    //         ""
    //       )}</>
    //     );
    //   },
    // },
  ];
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    // hideSelectAll: true,
  };
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    const onFollowChanged = () => {
      this.setState({ isFollow: !this.state.isFollow });

    };

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Button
          onClick={() => {
            this.onFollowChanged(row);
          }}
        >
          Follow
        </Button>
      );
    };

    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };
  let rows = participant.map((item, i) => item);
  console.log("part", rows)
  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={rows}
        columns={columns}
        exportCSV={{ onlyExportSelection: true, exportAll: false }}
        search
      >
        {(props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter my-5"><ToastContainer />
              <div className="row pe-3">
                <div className="col-xl-4 col-lg-1"></div>
                <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                  <div className="flexgy">
                    <div className="col-md-4  mr-1  px-0">
                      <div className="input-group">
                        <input
                          className="form-control height43"
                          type="search"
                          placeholder="Recherche"
                          onChange={(e => searchParticipant(e.target.value))}
                        />
                        {/* <span className="input-group-append">
                        <button className="btn btn-outline-dark height43" type="button">
                          <img
                            src={
                              require("../../../../assets/icons/Search.svg")
                                .default
                            }
                          />
                        </button>
                      </span> */}
                      </div>
                    </div>
                    <div className="px-0 myxs">
                      <div className="flex">
                        <div className="square">
                          <button type="button"
                            className="btn btn-link"
                            onClick={handleShowfiltre}
                          >
                            <img
                              src={require("../../../../assets/icons/filter.png")}
                            />
                          </button>
                        </div>
                        <PopupFiltre
                          showfiltre={showfiltre}
                          closefiltre={handleClosefiltre}
                          searchwithfiltre={searchwithfiltre}
                          filterEntry={eachEntry}
                        />
                        <div className="square">
                          <button type="button" className="btn btn-link" onClick={() => props.csvProps.onExport()}>
                            <img
                              src={require("../../../../assets/icons/Export.png")}
                            />
                          </button>
                        </div>
                        <div className="square">
                          <input
                            type="file"
                            name="file"
                            id="filex"
                            accept={".csv"}
                            className="btn btn-link"
                            onChange={handleOnChange}
                            hidden
                          />
                          <label for="filex" type="button">
                            <img
                              src={require("../../../../assets/icons/Import.png")}
                            />
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            {/* lists-filter */}
            {displayetat === "true" ? (
              <>
                <br></br>
                <Alert color="primary">Aucun resultat trouvé</Alert>
              </>
            ) : (
              ""
            )}
            <BootstrapTable
              {...props.baseProps}
              classes="table-dark "
              bordered={false}
              hover
              selectRow={selectRow}
              rowEvents={tableRowEvents}
            // pagination={paginationFactory(options)}
            />
            <div class="row react-bootstrap-table-pagination">
              <div class="col-md-6 col-xs-6 col-sm-6 col-lg-6"></div>
              <div class="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                <ul class="pagination react-bootstrap-table-page-btns-ul">
                  {pages.map((page, index) => {
                    if (page === LEFT_PAGE) return (
                      <li key={index} className="page-item">
                        <a className="page-link" href="#" aria-label="Previous"
                        // onClick={this.handleMoveLeft}
                        >

                          <span className="sr-only">...</span>
                        </a>
                      </li>
                    );

                    if (page === RIGHT_PAGE) return (
                      <li key={index} className="page-item">
                        <a className="page-link" href="#" aria-label="Next"
                        //onClick={this.handleMoveRight}
                        >

                          <span className="sr-only">...</span>
                        </a>
                      </li>
                    );
                    return (
                      <li className={currentPage === page ? "active page-item" : " page-item"} title="1"><a class="page-link" onClick={() => {
                        setCurrentPage(page);
                        getPage(page);
                      }
                      }>{page}</a></li>
                    )
                  }
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </ToolkitProvider>

      <Modal show={show} onHide={handleClose} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            Etes-vous sûr(e) de vouloir supprimer{" "}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={handleClose} style={{ color: "#006FFF" }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-primary"
            onClick={(e) => deleteParticpant(id)}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
