import CadreItems from "../components/common/CadreItems";
import Hn from "../components/common/Hn";
import Search from "../components/common/Search";
import { useEffect, useState } from "react";
import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
//import { useState } from "react";
import Mostformation from "../components/owl counter/Mostformation";
function SearchTraining() {
  const [term, setTerm] = useState();
  const [elearnings, setElearnings] = useState([]);
  const [initraining, setInitraining] = useState([]);
  const [traininge, setTraininge] = useState([]);
  const IMG_URL = process.env.REACT_APP_PIC_ELEARNING_IMG;
  const uri = process.env.REACT_APP_BASE_URL;

  function getElearnings() {
    trackPromise(
    axios.get(uri + "/elearning")
      .then((result) => {
        
        setElearnings(result?.data);
      })
      .catch((err) => {
        console.log("err elearning", err);
      }));
  }


  // useEffect(() => {
  //   // getElearnings();
  //   // getThematic();
  // }, []);

  //const [inactive, setInactive] = useState(false);
  const items = [1, 2, 3, 4, 5, 6, 7];
  return (
    <div className="container-fluid">
        <div className="row">
         
      <Search />
     
      
    </div>
    </div>
  );
};

export default SearchTraining;
