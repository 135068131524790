import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import axios from "axios";
import PopupFiltreActionformationElear from "../../../../components/popup/PopupFiltreActionformationElear";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Form } from "react-bootstrap";
import Papa from "papaparse";
import AxioServices from "../../../../services/AxioServices";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { ToastContainer, toast } from "react-toastify";
export default function ListElearningsDataGrid({
  elearnings,
  deleteEntity,
  setShow,
  show,
  getElearnings,
  learning,
  setElearnings,
}) {
  let history = useHistory();
  let toastId = null;
  const { ExportCSVButton } = CSVExport;
  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };

  const [idd, setId] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [initraining, setInitraining] = useState([]);
  const [showfiltre, setShowfiltre] = useState(false);
  const [eachEntry, setEachEntry] = useState("");
  const uri = process.env.REACT_APP_BASE_URL;
  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push("/admin/show-elearnig/" + row.id);

    },
  };
  function getElearnings() {
    axios
      .get(uri + "/elearning")
      .then((result) => {
        

        setElearnings(result?.data);
      })
      .catch((err) => {
        console.log("err elearning", err);
      });
  }
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }
  function deleteElearning(id) {

    axios
      .delete(uri + "/elearning/" + id)
      .then((response) => {
        if (response.data === "theme-null") {
          displayToast(
            "Cerror",
            "Vous ne pouvez pas supprimer cette compétence"
          );
        } else {
          displayToast("Csuccess", "Suppression effectuer avec success");
          setTimeout(() => {
            history.go(0);
          }, 3000);
        }
      })
      .catch((err) => {
        displayToast("Cerror", "Quelque chose s'est mal passé");
      });
    setShow(false);
  }
  const handleClosefiltre = () => {

    setShowfiltre(false);

  };
  function searchwithfiltre(val) {
    setInitraining(elearnings);
    setEachEntry(val);

    if (
      val.intitule === "" &&
      val.theme === "" &&
      val.status === "" &&
      val.duree === ""
    ) {
     

      setElearnings(initraining);
    } else {

      setElearnings([]);
      // let elearningsFilter = elearnings.filter(
      //   (el) =>
      //     (el.intitule.toLowerCase().includes(val.intitule.toLowerCase()) &&
      //       val.intitule.length > 0) ||
      //     el?.theme.id === val.theme ||
      //     el?.duree === parseInt(val.duree) ||
      //     el?.actif === val.status
      // );
      let elearningsFilter = elearnings.filter(el => {
        let ch = 0; let lf; let i = 0;
        if (val.intitule) {
          i = i + 1
          lf = el?.intitule?.toLowerCase().includes(val?.intitule?.toLowerCase())
          if (lf) ch = ch + 1;
        }
        if (val.theme) {
          i = i + 1
          lf = el?.theme?.id?.includes(val?.theme)
          if (lf) ch = ch + 1;
        }
        if (val.duree) {
          i = i + 1
          if (parseInt(el?.duree) >= parseInt(val.duree)) ch = ch + 1;
          if (lf) ch = ch + 1;
        }
        if (val?.theme) {
          i = i + 1
          lf = el?.theme?.id?.includes(val?.theme)
          if (lf) ch = ch + 1;
        }
        if (ch === i) { return true }
        return false
      })
      setElearnings(elearningsFilter);
    }
    
  }
  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  function updateStatus(id, checked) {
    let data = {
      actif: checked,
    };

    axios
      .put(`${uri}/elearning/edit/` + id, data, headers)
      .then((response) => {
      console.log("rep", response?.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        results.data[0].ref = results.data[0].ref + "x2";

        AxioServices.create("elearning/import", JSON.stringify(results.data), {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*",
          },
        })
          .then((result) => {
            displayToast("Csuccess", "Importation en cours, Redirection...");
            setTimeout(() => {
              history.go(0);
            }, 5000);
          })
          .catch((err) => {
            displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
          });
      },
    });
  };
  function duplicateEntity(values) {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    var data = new FormData();
    data.append("ref", values.ref);
    data.append("intitule", values.intitule);
    data.append("objectifs", values.objectifs);

    data.append("programme_de_formation", values.programme_de_formation);
    data.append("prerequis", values.prerequis);
    data.append("parcours_associe", values.parcours_associe);

    data.append("manager", values.manager);
    data.append("mis_en_avant", values.mis_en_avant);
    data.append("obligation_de_formation", values.obligation_de_formation);

    data.append("actif", values.actif);
    data.append("theme", values.theme?.id);
    data.append("validation", values.validation);

    data.append("commentaire", values.commentaire);
    data.append("image", values.image);

    data.append("duree", values.duree);
    data.append("program_file", values.fichier_programme);

    /*local*/ axios
      .post(uri + "/elearning/new", data, headersFiles)
      .then((result) => {
        displayToast(
          "Csuccess",
          "action de formation dupliqué avec success, reload..."
        );
        setTimeout(() => {
          history.go(0);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
  }

  const columns = [
    {
      dataField: "intitule",
      text: "Action de formation",
      csvText: "Action de formation",
      sort: true,
    },
    {
      dataField: "theme.name",
      text: "Thème",
      csvText: "thème",
      sort: true,
    },
    {
      dataField: "duree",
      text: "Durée",
      csvText: "nombre d'heures",
      sort: true,
    },
    {
      dataField: "actif",
      text: "actif",
      csvText: "actif",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          // <SwitchButton checked={row.status}/>
          <Form className=" form-switch">
            <Form.Check
              //  handleToggle={() => setNewstatuts(!row.status)}
              type="switch"
              id={row.id}
              onChange={(e) => {
                updateStatus(row.id, e.target.checked ? 1: 0);
               
              }}
              defaultChecked={row.actif}
            />
          </Form>
        );
      },
    },
    {
      dataField: "nbApprenants",
      text: "Apprenants en cours",
      sort: true,
      csvText: "Apprenants en cours",
    },
    {
      dataField: "follow",
      text: "",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, learning, rowIndex, formatExtraData) => {
        return (
          <div className="btn-group float-end" role="group" aria-label="Basic example">
            {localStorage.getItem("role") !== "Formateur" ? (<>
              <a

                href={"/admin/update-formation-e-learning/" + row.id}
                type="button"
                className="btn btn-link"
              >
                <img src={require("../../../../assets/icons/edit.png")} />
              </a>

              <button
                type="button"
                className="btn"
                onClick={() => {
                  duplicateEntity(row);
                }}
              >
                <img src={require("../../../../assets/icons/duplicate.png")} />
              </button>
              <button
                type="button"
                className="btn"
                onClick={() => {
                  setId(row.id);
                  handleShow();
                }}
              >
                <img src={require("../../../../assets/icons/delete.png")} />
              </button>
            </>) : ''}
          </div>

        );
      },
      sort: true,
    },
  ];
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    // hideSelectAll: true,
  };

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };
  let rows = elearnings.map((item, i) => item);
  return (
    <ToolkitProvider
      keyField="id"
      data={rows}
      columns={columns}
      exportCSV={{ onlyExportSelection: true, exportAll: false }}
      search
    >
      {(props) => (
        <div>
          <MyExportCSV {...props.csvProps} />
          <div className="lists-filter py-5">
            <div className="row">
              <div className="col-xl-4 col-lg-1"></div>
              <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                <div className="flexgy">
                  <div className="col-md-4  mr-1  px-0">
                    <div className="input-group">
                      <input
                        className="form-control height43"
                        type="search"
                        placeholder="Recherche"
                        onChange={(e => props.searchProps.onSearch(e.target.value))}
                      />
                    </div>
                  </div>
                  <div className=" px-0 myxs">
                    <div className="flex">
                      <div className="square">
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => setShowfiltre(true)}
                        >
                          <img
                            src={require("../../../../assets/icons/filter.png")}
                          />
                        </button>
                      </div>
                      <div className="square">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept={".csv"}
                          className="btn btn-link"
                          onChange={handleOnChange}
                          hidden
                        />
                        <label for="file" type="button">
                          <img
                            src={require("../../../../assets/icons/Import.png")}
                          />
                        </label>
                      </div>
                      <PopupFiltreActionformationElear
                        showfiltre={showfiltre}
                        closefiltre={handleClosefiltre}
                        searchwithfiltre={searchwithfiltre}
                        filterEntry={eachEntry}
                      />
                      <div className="square">
                        <button
                          onClick={() => props.csvProps.onExport()}
                          type="button"
                          className="btn btn-link"
                          for="export"
                        >
                          <img
                            src={require("../../../../assets/icons/Export.png")}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  {localStorage.getItem("role") !== "Formateur" ? (
                    <div className="px10">
                      <div className="d-grid gap-2">
                        <a
                          href="/admin/creer-formation-e-learning"
                          className="btn btn-primary btn-gy height43"
                          type="button"
                        >
                          Créer une action de formation
                        </a>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <BootstrapTable
            {...props.baseProps}
            classes="table-dark"
            bordered={false}
            hover
            selectRow={selectRow}
            pagination={paginationFactory(options)}
            rowEvents={tableRowEvents}
          />
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                Etes-vous sûr que vous voulez supprimer ?
              </Modal.Title>
            </Modal.Header>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Non
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  deleteElearning(idd);
                }}
              >
                Oui
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </ToolkitProvider>
  );
}
