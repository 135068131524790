import axios from "axios";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Hn from "../../../../components/common/Hn";
import AxioServices from "../../../../services/AxioServices";
import ListProvidersDataGrid from "./ListProvidersDataGrid";
import PopupFiltre from "./PopupFiltre";
import "./provider.css";

function Prestataires() {
  let history = useHistory();
  const [providers, setProviders] = useState([]);
  const [show, setShow] = useState(false);
  const [displayetat, setDisplayetat] = useState("false");
  const handleClose = () => setShow(false);
  const [showalert, setShowAlert] = useState(true);
  const [showfiltre, setShowfiltre] = useState(false);
  const handleShow = () => setShow(true);
  const [inputsearch, setSInputsearch] = useState(false);
  const [initialProvider, setInitialProvider] = useState([]);
  let toastId = null;
 
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const uri = process.env.REACT_APP_BASE_URL;
  //récupérer tous les prestataires
  function getProviders() {
    //setProviders([]);
    axios
      .get(`${uri}/provider`)
      .then((result) => {
        console.log(result.data)
        setProviders(result?.data);
      })
      .catch((err) => {
        console.log("err providers", err);
      });
  }

  useEffect(() => {
    //setFormer([]);
    getProviders();
  }, []);
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }
//supprimer un prestatire
  function deleteProvider(id) {
    
    axios
      .delete(`${uri}/provider/` + id)
      .then(() => {
        displayToast(
          "Csuccess",
          "Suppression effectuer avec success, Redirection..."
        );
        setTimeout(() => {
          getProviders();
        }, 3000);
      })
      .catch(() => {
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
    setShow(false);
  }

 

  


  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row pe-4">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn
              title="Formations présentielles"
              level="h1"
              className="pt-5"
              plus={false}
            />
            <Hn title="Prestataires" level="h2" className="pt-4" />
          </div>

         
          {/* lists-filter */}
          {displayetat === "true" ? (
            <>
              <br></br>
              <Alert
                color="warning"
                onClose={() => setShowAlert(false)}
                dismissible
              >
                Aucun resultat trouvé
              </Alert>
            </>
          ) : (
            ""
          )}
          <div className="mt-5">
          
              <ListProvidersDataGrid
                providers={providers}
                deleteProvider={deleteProvider}
                setShow={setShow}
                show={show}
                setProviders={setProviders}
                getProviders={getProviders}
              />
           
          </div>
        </div>
      </div>
    </div>
  );
}
export default Prestataires;
