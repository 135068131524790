import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import "./styles.css";
import { Bar } from "react-chartjs-2";
import { useEffect } from "react";

export default function Barchart({title, color, label, stats}) {
  ChartJS.register(ArcElement, Tooltip, Legend);
  
  const options = {
    cutoutPercentage: 100,
    borderWidth: 0,
    width: 103,
    height: 103,
  };
  const labels = label?.map(function(item, i){
    return item
  })
  const data = {
    labels,
    datasets: [
      {
        label: title,
        data: stats,
        backgroundColor: color,
      },
    ],
  };


  useEffect(() => {
    //
  }, [label]);


  return (
      <Bar data={data} options={options} />
  );
}
