import axios from "axios";
import { createBrowserHistory } from "history";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Hn from "../../../../components/common/Hn";
import "../face-training/crud/createFaceTraining.css";
import "../location/ListLocationTraining.css";
import ListLocationTrainingDataGrid from "./ListLocationTrainingDataGrid";

function ListLocationTraining() {
  const [lieuxFormation, setLieuxForamtion] = useState([]);
  const [initiallieuxFormation, setInitiallieuxFormation] = useState([]);
  const [show, setShow] = useState(false);
  const [showfiltre, setShowfiltre] = useState(false);
  const [exports, setExports] = useState([]);
  const [displayetat, setDisplayetat] = useState("false");
  const params = useParams();
  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
    searchwithfiltre(obj);
  };

  const uri = process.env.REACT_APP_BASE_URL;

  //filtre d'un lieu d'une formation présentielle
  function searchwithfiltre(vals) {
    setInitiallieuxFormation(lieuxFormation);
    if (vals) {
      setLieuxForamtion([]);
      if (vals.minvalue === undefined) {
        vals.minvalue = 0;
      }
      if (vals.maxvalue === undefined) {
        vals.maxvalue = 100;
      }
      let query = `name=${vals.name}&town=${vals.ville}&zip=${vals.zip}&capacityMin=${vals.minvalue}&capacityMax=${vals.maxvalue}`;
      let req = uri + "/location/filter?" + query;
    
      axios
        .get(req)
        .then((result) => {
      
          if (result?.data.length > 0) {
            setLieuxForamtion(result?.data);
            setDisplayetat("false");
          } else {
            setLieuxForamtion(initiallieuxFormation);
            setDisplayetat("true");
          }
        })
        .catch((err) => {
          console.log("err location", err);
          setDisplayetat("true");
        });
    }
  }

  const handleShowfiltre = () => {
    setShowfiltre(true);
  };

  /////////////filtre////////////////

  //récupérer tous les lieux des formations présentielles
  function getLocation() {
    setLieuxForamtion([]);
    axios
      .get(uri + "/location")
      .then((result) => {
        setLieuxForamtion(result?.data);
       
      })
      .catch((err) => {
        console.log("err location", err);
      });
  }
  useEffect(() => {
    getLocation();

    if (params.duplicate === "success") {
      // setShownotification(true);
    }
  }, []);

  let history = createBrowserHistory({ forceRefresh: true });
//chercher un lieu par son nom
  function findintable(e) {
    setLieuxForamtion([]);
  
    if (e.target.value) {
      axios
        .get(uri + "/location?search=" + e.target.value)
        .then((result) => {
          setLieuxForamtion(result?.data);
          
        })
        .catch((err) => {
          console.log("err location", err);
        });
    } else {
      getLocation();
    }
  }

  function handlechangefiltre(e) {}

  //exporter le fichier csv d'un lieu de formation présentielle avec ses détails
  function csvExport(id, event) {
    if (event.target.checked) {
     
      setExports([...exports, id]);
    } else {
      console.log(
        "new list",
        exports.filter((el) => el !== id)
      );
      setExports(exports.filter((el) => el !== id));
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn
              title="Formations présentielles"
              level="h1"
              className="pt-5"
              plus={false}
            />
            <Hn title="Lieux de formation" level="h2" className="pt-4" />
          </div>
        

          {/* lists-filter */}
          {displayetat === "true" ? (
            <>
              <br></br>
              <Alert color="primary">Aucun resultat trouvé</Alert>
            </>
          ) : (
            ""
          )}
          <div className="mt-20">
           
              <ListLocationTrainingDataGrid
                lieuxFormation={lieuxFormation}
                setLieuxForamtion={setLieuxForamtion}
                setShow={setShow}
                show={show}
                getLocation={getLocation}
              />
           
          </div>
        </div>
      </div>
    </div>
  );
}
export default ListLocationTraining;
