import axios from "axios";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
// import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createBrowserHistory } from "history";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { toast, ToastContainer } from "react-toastify";
import Papa from "papaparse";
import AxioServices from "../../services/AxioServices";
import "react-toastify/dist/ReactToastify.css";
import PopupFiltrecompetence from "../../components/popup/PopupFiltrecompetence";
export default function Datatable({
  getcompetence,
  competence,
  setShow,
  show,
  setCompetence
}) {
  let history = createBrowserHistory({ forceRefresh: true });
  const [loading, setLoading] = useState(false);
  const [ident, setIdent] = useState();
  const [id, setId] = useState();
  const [eachEntry, setEachEntry] = useState("");
  const [initialSkills ,setInitialSkills]=useState([]);
  let toastId = null;
  const [showfiltre, setShowfiltre] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const uri = process.env.REACT_APP_BASE_URL;
  const { ExportCSVButton } = CSVExport;
  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };
  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        
        results.data[0].brochure = e.target.files[0].name.substring(0, e.target.files[0].name.lastIndexOf('.'));
        AxioServices.create("skills/import", JSON.stringify(results.data), {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*",
          },
        })
          .then((result) => {
            displayToast("Csuccess", "Importation en cours, Redirection...");
            // setTimeout(() => {
            //   history.go(0);
            // }, 5000);
          })
          .catch((err) => {
            displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
          });
      },
    });
  };
  const handleShowfiltre = () => {
    setShowfiltre(true);
  };
  const handleClosefiltre = (obj) => {
   
    setShowfiltre(false);
  };
  function searchwithfiltre(val) {
    setInitialSkills(competence);
    setEachEntry(val);

  
    if (val.name===""
    && val.ref===""
    ) {
     
      getcompetence();
    //  setParticipant(initialparticipant)
    }else{
      setCompetence([]);
      let skillsFilter =  competence.filter(el =>    
      //( el?.name?.toLowerCase().includes(val.name.toLowerCase() )&& (val.name.length>0))||
       (el?.title?.toLowerCase().includes(val.ref.toLowerCase()) && (val.ref.length>0))
      )
  
    
      setCompetence(skillsFilter)
     
    }
   
  }
  function deletecompetence(id) {
 
    axios
      .delete(uri + "/skills/" + id)
      .then((response) => {
      
        if (response.data === "theme-null") {
          displayToast(
            "Cerror",
            "Vous ne pouvez pas supprimer cette compétence"
          );
        } else {
          displayToast("Csuccess", "Suppression effectuer avec success");
          setTimeout(() => {
            history.go(0);
          }, 3000);
        }
        //getcompetence();
      })
      .catch((response) => {
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
    setShow(false);
  }
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }
  function duplicate(data) {
 
    let sub = [];
    data.subskills.forEach((element) => {
      sub.push(element.name);
    
    });
    let datas = {
      title: "Duplicate of " + data.title,
      subskills: sub,
    };
    
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axios
      .post(uri + `/skills/new`, datas, headersFiles)
      .then((result) => {
        displayToast("Csuccess", "Compétence dupliqué avec success");
        setTimeout(() => {
          history.go(0);
        }, 3000);
      })
      .catch((err) => {
        console.log("err training", err);
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
  }
  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push("/admin/competence/" + row.id);
      
    },
  };
  const columns = [
    {
      dataField: "title",
      text: "Titre du référentiel",
      csvText: "title",
      sort: true,
    },
    {
      dataField: "subskills",
      text: "Nombre de Compétences",
      csvText: "subskills",
      formatter: (row) => {
        return <>{row?.length}</>;
      },
      sort: true,
    },

    {
      dataField: "follow",
      text: "",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="btn-group" role="group" aria-label="Basic example">
            <a
              href={"/admin/updatecompetence/" + row.id}
              type="button"
              className="btn btn-link"
            >
              <img src={require("../../assets/icons/edit.png")} />
            </a>

            <button
              type="button"
              className="btn"
              onClick={() => {
                setId(row.id);
                handleShow();
              }}
            >
              <img src={require("../../assets/icons/delete.png")} />
            </button>

            <button
              type="button"
              className="btn"
              onClick={() => {
                duplicate(row);
              }}
            >
              <img src={require("../../assets/icons/duplicate.png")} />
            </button>
          </div>
        );
      },
      sort: true,
    },
  ];
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    // hideSelectAll: true,
  };
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    const onFollowChanged = () => {
      this.setState({ isFollow: !this.state.isFollow });
     
    };

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Button
          onClick={() => {
            this.onFollowChanged(row);
          }}
        >
          Follow
        </Button>
      );
    };

    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };
  let rows = competence.map((item, i) => item);
  return (
    <>
      <ToastContainer />
      <ToolkitProvider
        keyField="id"
        data={rows}
        columns={columns}
        exportCSV={{ onlyExportSelection: true, exportAll: false }}
        search
      >
        {(props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter my-5">
            <div className="row">
              <div className="col-xl-4 col-lg-1"></div>
              <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                <div className="flexgy">
                  <div className="col-md-4  mr-1  px-0">
                    <div className="input-group">
                      <input
                        className="form-control height43"
                        type="search"
                        placeholder="Recherche"
                        onChange={(e=>props.searchProps.onSearch(e.target.value))}
                      />
                      
                    </div>
                  </div>
                  <div className="px-0 myxs">
                    <div className="flex">
                      {/* <div className="square">
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={handleShowfiltre}
                        >
                          <img src={require("../../assets/icons/filter.png")} />
                        </button>
                      </div> */}
                      <PopupFiltrecompetence
                        showfiltre={showfiltre}
                        closefiltre={handleClosefiltre}
                        searchwithfiltre={searchwithfiltre}
                        filterEntry={eachEntry}
                      />
                      <div className="square">
                      <button type="button" className="btn btn-link" onClick={()=>props.csvProps.onExport()}>
                          <img src={require("../../assets/icons/Export.png")} />
                        </button>
                      </div>
                      <div className="square">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept={".csv"}
                          className="btn btn-link"
                          onChange={handleOnChange}
                          hidden
                        />
                        <label for="file" type="button">
                          <img src={require("../../assets/icons/Import.png")} />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="px10">
                    <div className="d-grid gap-2">
                      <a
                        href="/admin/createcompetence"
                        className="btn btn-primary btn-gy height43"
                        type="button"
                      >
                        Créer une Compétence
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <BootstrapTable
              {...props.baseProps}
              classes="table-dark"
              bordered={false}
              hover
              selectRow={selectRow}
              pagination={paginationFactory(options)}
              rowEvents={tableRowEvents}
            />
          </div>
        )}
      </ToolkitProvider>

      <Modal show={show} onHide={handleClose} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            Etes-vous sûr(e) de vouloir supprimer{" "}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={handleClose} style={{ color: "#006FFF" }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-primary"
            onClick={(e) => deletecompetence(id)}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
