import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Hn from "../../components/common/Hn";
import "./action.css";
import dateFormat from "dateformat";
import Collapse from "react-bootstrap/Collapse";
import Icon from "../../components/common/Icon";
import { useEffect } from "react";
import Participant from "../admin/forms/face-training/crud/show-sessions-participants/participantSession"
import Datatable from "./Datatable";
function ListActionFormation() {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const uri = process.env.REACT_APP_BASE_URL;
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [session, setSession] = useState([]);
  const [show, setShow] = useState(false);
  const [action, setAction] = useState([]);
  //récupérer les unités dans la session récupérée par son id
  function getUnitySession() {
    axios
      .get(uri + "/session/" + params.id, headers)
      .then((result) => {
        setSession(result?.data);
      })
      .catch((err) => {
        console.log("err ", err);
      });
  }


  
  useEffect(() => {
    getUnitySession();
  }, []);

  

  return (
    <div className="bunner-provider container-fluid">
      <div className="row">
        <div className=" container lists pe-4">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="">Formations présentielles - action de formation</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Créer une action de formation</li>
            </ol>
          </nav>
          <a type="submit" className=" btn-link resetBtn" href="/">
            Retour{" "}
          </a>
          <div className="row">
            <div className="col-md-6">
              <Hn title="Nom de l'action de formation" level="h2" className="pt-4" />
            </div>
            <div className="col-md-6">
              <a
                href={"/admin/prestataire-edit/"}
                className="btn btn-primary float-end mt-4"
                type="button"
              >
                Modifier
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-xs-12 ">
              <div className="cardSession">
                <h5 className="card-title title16">Sessions planifiées</h5>
                <p className="card-text title23">1</p>
              </div>
            </div>
            <div className="col-md-3 col-xs-12 ">
              <div className="cardSession">
                <h5 className="card-title title16">Sessions en cours</h5>
                <p className="card-text title23">2</p>
              </div>
            </div>
            <div className="col-md-3 col-xs-12 ">
              <div className="cardSession">
                <h5 className="card-title title16">Total des sessions</h5>
                <p className="card-text title23">3</p>
              </div>
            </div>
            <div className="col-md-3 col-xs-12 ">
              <div className="cardSession">
                <h5 className="card-title title16">Participants inscrits</h5>
                <p className="card-text title23">
                  12
                </p>
              </div>
            </div>
          </div>

          {/* lists-filter */}
          <div className="row mt-30 mb-135">
            <div className="col-12">
              <div className="h-100 p-5 text-white bg-dark rounded-3">
                <Hn
                  title="Informations générales"
                  level="h4"
                  className="h4-size pb-5"
                  plus={false}
                />
                <div className="form-elements">
                  <div className="row g-3">
                    <div className="col-md-4">
                      <label htmlFor="ref" className="form-label title16">
                        Référence
                      </label>
                      <p>Lorem ipsum</p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="nom" className="form-label title16">
                        Intitulé
                      </label>
                      <p>Lorem ipsum dolor sit amet consectetur</p>
                    </div>
                    <div className="col-md-4">
                      
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="denomi" className="form-label title16">
                        Image
                      </label>
                      <p>lorem_ipsum.pdf</p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="denomi" className="form-label title16">
                        Thème
                      </label>
                      <p>
                      Lorem ipsum dolor sit amet
                      </p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="codepos" className="form-label">
                      Parcours associé
                      </label>
                      <p>Lorem ipsum dolor sit amet</p>
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="codepos" className="form-label">Pré-requis et public visé</label>
                      <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                      </p>
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="codepos" className="form-label">
                        Objectifs
                      </label>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="ville" className="form-label">
                      Programme de formation
                      </label>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="ville" className="form-label">
                      Programme de formation
                      </label>
                      <p>lorem_ipsum.pdf </p>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="ville" className="form-label">
                      Prestataire
                      </label>
                      <p>Lorem ipsum </p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="ville" className="form-label">
                      Durée théorique (en heures)*
                      </label>
                      <p>22:30 </p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="ville" className="form-label">
                     Tarif
                      </label>
                      <p>22:30 </p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="ville" className="form-label">
                      Actif
                      </label>
                      <p>22:30 </p>
                    </div>
                    <div className="col-md-4">
                      
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="ville" className="form-label">
                      Commentaire
                      </label>
                      <p>--- </p>
                    </div>
                    <div className="col-md-4">
                      
                    </div>
                    <div className="col-md-4">
                      
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="ville" className="form-label">
                      Mise en avant dans le catalogue.
                      </label>
                      <p>Non</p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="ville" className="form-label">
                      Spécifique au manager
                      </label>
                      <p>Non </p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="ville" className="form-label">
                      Participe à l’obligation de formation
                      </label>
                      <p>Oui </p>
                    </div>
                    <Hn
                  title="Compétences associées"
                  level="h4"
                  className="h4-size pb-5"
                  plus={false}
                />
                    <div className="col-md-4">
                      <label htmlFor="ville" className="form-label">
                      Référentiel de compétences
                      </label>
                      <p>Déontologie </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        
            <div className="row">
              <div className="col-md-12">

              
                <div className="row">

                <div className="col-md-3 pe-0">
                <Hn title="Sessions de formation" level="h4" plus={false}/>
                  </div>

                    <div className="lists-filter">
            <div className="row">
              <div className="col-xl-4 col-lg-1"></div>
              <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                <div className="flexgy">
                  <div className="col-md-4  mr-1  px-0">
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Recherche"
                       // onKeyUp={findintable}
                      />
                      <span className="input-group-append">
                        <button
                          className="btn btn-outline-dark height43"
                          type="button"
                         // onClick={findintable}
                        >
                          <img
                            src={
                              require("../../assets/icons/Search.svg")
                                .default
                            }
                          />
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className=" px-0 myxs">
                    <div className="flex">
                      <div className="square">
                        <button
                          type="button"
                          className="btn btn-link"
                         // onClick={handleShowfiltre}
                        >
                          <img
                            src={require("../../assets/icons/filter.png")}
                          />
                        </button>
                      </div>
                     
                      <div className="square">
                        <label
                          type="button"
                          className="btn btn-link"
                          for="export"
                        >
                          <img
                            src={require("../../assets/icons/Export2.png")}
                          />
                        </label>
                      </div>
                      <div className="square">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept={".csv"}
                          className="btn btn-link"
                        
                          hidden
                        />
                        <label for="file" type="button">
                          <img
                            src={require("../../assets/icons/Import.png")}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="px10">
                    <div className="d-grid gap-2">
                    <a
                        href="/admin/createthematique"
                        className="btn btn-primary btn-gy height43"
                        type="button"
                      >
                        Créer une session de formation
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                </div>
                </div>
            </div>
            <div className="pt-5">
         <table className="table table-dark table-hover">
              <thead>
                <tr>
                  <th scope="col">Actif</th>
                  <th scope="col">Dates</th>
                  <th scope="col">Ville</th>
                  <th scope="col">Formateur</th>
                  <th scope="col">Inscrits</th>
                  <th scope="col">Etat</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  className="td"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                  <td>Pathologie du batiment</td>
                  <td> 05/05/2022</td>
                  <td>Paris</td>
                  <td>John doe</td>
                  <td>12</td>
                  <td>En cours
                  <a
              
              type="button"
              className="btn btn-link"
            >
              <img src={require("../../assets/icons/edit.png")} />
            </a>

            <button
              type="button"
              className="btn"
              
            >
              <img src={require("../../assets/icons/delete.png")} />
            </button>

            <button
              type="button"
              className="btn"
              
            >
              <img src={require("../../assets/icons/duplicate.png")} />
            </button>
                  </td>
                  
                </tr>
                <tr>
                <td>Pathologie du batiment</td>
                  <td> 05/05/2022</td>
                  <td>Paris</td>
                  <td>John doe</td>
                  <td>12</td>
                  <td>En cours     
            <a
              
              type="button"
              className="btn btn-link"
            >
              <img src={require("../../assets/icons/edit.png")} />
            </a>

            <button
              type="button"
              className="btn"
              
            >
              <img src={require("../../assets/icons/delete.png")} />
            </button>

            <button
              type="button"
              className="btn"
              
            >
              <img src={require("../../assets/icons/duplicate.png")} />
            </button>
            </td>
                  
                </tr>
                <tr>
                <td>Pathologie du batiment</td>
                  <td> 05/05/2022</td>
                  <td>Paris</td>
                  <td>John doe</td>
                  <td>12</td>
                  <td>En cours             <a
              
              type="button"
              className="btn btn-link"
            >
              <img src={require("../../assets/icons/edit.png")} />
            </a>

            <button
              type="button"
              className="btn"
              
            >
              <img src={require("../../assets/icons/delete.png")} />
            </button>

            <button
              type="button"
              className="btn"
              
            >
              <img src={require("../../assets/icons/duplicate.png")} />
            </button></td>
                  
                 
                </tr>
                <tr>
                <td>Pathologie du batiment</td>
                  <td> 05/05/2022</td>
                  <td>Paris</td>
                  <td>John doe</td>
                  <td>12</td>
                  <td>En cours             <a
              
              type="button"
              className="btn btn-link"
            >
              <img src={require("../../assets/icons/edit.png")} />
            </a>

            <button
              type="button"
              className="btn"
              
            >
              <img src={require("../../assets/icons/delete.png")} />
            </button>

            <button
              type="button"
              className="btn"
             
              
            >
              <img src={require("../../assets/icons/duplicate.png")} />
            </button></td> 
                </tr>
                <tr>
                <td>Pathologie du batiment</td>
                  <td> 05/05/2022</td>
                  <td>Paris</td>
                  <td>John doe</td>
                  <td>12</td>
                  <td>En cours <a
              
              type="button"
              className="btn btn-link"
            >
              <img src={require("../../assets/icons/edit.png")} />
            </a>

            <button
              type="button"
              className="btn"
              
            >
              <img src={require("../../assets/icons/delete.png")} />
            </button>

            <button
              type="button"
              className="btn"
              
            >
              <img src={require("../../assets/icons/duplicate.png")} />
            </button></td>
                  
                </tr>
              </tbody>
            </table> 
            {/* {action.length > 0 ? (
              <Datatable
                setShow={setShow}
                show={show}
                action={action}
                getThematic={getThematic}
              />
             ) : (
              ""
            )}  */}
          </div>
          </div>


        </div>
      </div>
   
  );
}
export default ListActionFormation;
