import { gridColumnPositionsSelector } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Hn from "../common/Hn";
import Rangenumber from '../common/Rangenumber';
import "./popup.css";
export default function PopupFiltreActionformation({showfiltre, closefiltre, searchwithfiltre,
    filterEntry,minCost,maxCost,minDuration,maxDuration}) {
  
    const initialInputState = {
        name: filterEntry.name ??"",
        status:filterEntry.status?? "",
        minvalue:  minDuration,
        maxvalue:  maxDuration,
        mincout:  minCost,
        maxcout:  maxCost
    };

    const [eachEntry, setEachEntry] = useState(initialInputState);
    const [minvalue, setMinvalue] = useState(minDuration??1);
    const [maxvalue, setMaxvalue] = useState(maxDuration??10000);

    const [minCout, setMinCout] = useState(minCost);
    const [maxCout, setMaxCout] = useState(maxCost);
    const {
        name,
        status
      } = eachEntry;

    function getvalueinput(e) {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
    }
    const resetForm = () => {
        document.getElementById("filterForm").reset();
        setEachEntry({ name:"", status:""})
        setMaxvalue(16)
        setMinvalue(1)
        setMinCout('')
        setMaxCout('')

      }
    useEffect(() => {
        setEachEntry(initialInputState);
      }, [showfiltre, closefiltre]);
 
    return (
        <Modal show={showfiltre}
            onHide={closefiltre}
            size="lg"
            className="filter">
            <Modal.Header closeButton>
                <Modal.Title className="px-3 nopad">
                    <Hn title="Filtrer" level="h4"
                        plus={false}/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form id="filterForm">
                <div className="row px-3 nopad">
                    <div className="col-md">
                        <Hn title="Nom" level="h5"
                            plus={false}
                            className="label-popup"/>
                        <input type="text" className="form-control drop"
                        onChange={getvalueinput} name="name"   value={eachEntry.name}/>
                    </div>
                    <div className="col-md">
                    <Hn title="Status" level="h5"
                            plus={false}
                            className="label-popup"/>
                        <select className="form-control drop" onChange={getvalueinput}  name="status"   value={eachEntry.status}>
                        <option value="x">Sélectionner</option>
                        <option value="1">Actif</option>
                        <option value="0">Non Actif</option>
                        </select>
                    </div>
                </div>
                {/* /row */}
                <br></br>
                
               
                <div className="row px-3 nopad">
                    <div className="col-md">
                        <Hn title="Cout" level="h5"
                            plus={false}
                            className="label-popup"/>
                               <Rangenumber setMinvalue={setMinCout} setMaxvalue={setMaxCout} min={minCost} max={maxCost} />
                       </div>
                </div>
                <br></br>
                <Hn title="Nombre d'heures" level="h5"
                            plus={false}
                            className="label-popup"/>
                <div className="row px-3">
                    <Rangenumber setMinvalue={setMinvalue} setMaxvalue={setMaxvalue} min={minDuration} max={maxDuration} />
                </div>
                </form>

                {/* /row */} </Modal.Body>
            <Modal.Footer> 
            <div> <button className="btn btn-link resetBtn" onClick={closefiltre} style={{ color: "#006FFF" }}>
          Annuler
        </button></div>
      <div className="flex">  <button className="secondaryModal"
          onClick={() => {
            resetForm()
          }
          }
          disabled={eachEntry.name === "" && eachEntry.minvalue === "" && eachEntry.maxvalue === "" 
          && eachEntry.status === "" && eachEntry.mincout === "" && eachEntry.maxcout === ""  ? "disabled" : ""}
        >
          Réinitialiser
        </button>
        <Button
          variant="primary"
          onClick={() => {
            closefiltre()
       
            searchwithfiltre({minvalue, maxvalue, name, status, minCout, maxCout});
          }}

        >
          Valider
        </Button></div>
                
            </Modal.Footer>
        </Modal>
    );
}