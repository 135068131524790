import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Plus from "../../assets/icons/Plus.svg";
import Hn from "../../components/common/Hn";
import "./DetailFormation.css";
import axios from "axios";
import Placeholder from "../../assets/placeholder.png";
import moment from "moment";
import dateFormat from "dateformat";
import "moment/locale/fr";
import Popupinscrire from "../../components/popup/Popupinscrire";
import { trackPromise } from 'react-promise-tracker';
export default function DetailFormation({ title, disable, disabled, switchs }) {
  let valid = true;

  const [nbplace, setnbPlace] = useState(0);
  const [session, setSession] = useState([]);
  const [training, setTraining] = useState([]);
  const [options, setOptions] = useState([]);
  const params = useParams();
  const [showfiltre, setShowfiltre] = useState(false);
  const [dataformation, setDataformation] = useState([]);
  const [collaborateur, setCollaborateur] = useState([]);


  const uriElarning = process.env.REACT_APP_PIC_FACETRAINING_PATH;
  const docTraining = process.env.REACT_APP_PIC_FACETRAINING_FILE;


  const IMG_URL = process.env.REACT_APP_PIC_FACETRAINING_PATH;
  let history = useHistory();
  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  moment.locale("fr");
  const uri = process.env.REACT_APP_BASE_URL;
  const head = [
    "Unité d`apprentissage",
    "Durée",
    "Documents associés",
    "Score obtenu",
    "Validation",
    "",
  ];
  const body = [
    {
      one: "découverte acquéreur",
      two: "00:05:25",
      three: "Documents associés",
      four: "87%",
    },
  ];

  //récupérer les formations présentielles et afficher leurs détails 
  const role = ["Admin", "Responsable", "Super-admin"];
  function getTrainingDetails() {

    axios
      .get(uri + "/training/" + params.id, headers)
      .then((result) => {
     
        setSession(result?.data.sessions);
        setTraining(result?.data);
        let arrayContact = []
        result?.data?.sessions.forEach(el => {
          arrayContact = arrayContact.concat(el.contactSessions)
        })
        getCollaborateurs(arrayContact.map(el => el.contacts?.ContactID));

        let info = {
          title: result?.data.title,
          cat: result?.data?.type?.name,
        };
        setDataformation(info);
        let totplace = 0
        if (result?.data.sessions?.length > 0) {
          result?.data.sessions?.map((item) => {

            if (parseInt(item?.seat_capacity_max) - parseInt(item?.contactSessions.length) > 0) {              
              
              let rest = parseInt(item?.seat_capacity_max) - parseInt(item?.contactSessions.length);
              totplace = parseInt(totplace) + parseInt(rest);

              setOptions((options) => [
                ...options,
                {
                  value: item.id,
                  label: item?.location?.town + " - " + (new Date(item?.dateStart))?.toLocaleDateString('fr', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })
                },
              ]);
            }
          });
          setnbPlace(totplace)

        }

      })
      .catch((err) => {
        console.log("err training", err);
      });
  }

  //afficher les détails d'une seule agence par id
  function getCollaborateurs(arrayContact) {

    trackPromise(
      axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/agence/" + localStorage.getItem("AgenceID"),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {

          //  setCollaborateur(response.data);

          if (response?.data?.length > 0) {
            setCollaborateur(response.data?.filter((item) =>
              (!arrayContact.includes(item.ContactID))

            ).map(item => {
              return {
                value: item.ContactID,
                label: item.contactNom + " " + item.contactPrenom,
              }
            }))

          }
        })
        .catch(function (error) {
          console.log("error get collaborateur", error)
        })
    );


  }
//récupérer le nombre des sessions d'une formation présentielle
  function getSession(id_session) {
    axios
      .get(uri + "/session/" + id_session, headers)
      .then((result) => {
        setSession([...session, result?.data]);
      })
      .catch((err) => {
        console.log("err session", err);
      });
  }

  const handleClosefiltre = () => {
    setShowfiltre(false);
  };
  let img = null;
  if (training?.image) {
    img = IMG_URL + training.image;
  } else {
    img = Placeholder;
  }

  useEffect(() => {
    getTrainingDetails();

  }, []);
  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="details pe-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/formation-presentielle">Formations présentielle</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {training.title}
              </li>
            </ol>

            <a href="#" onClick={() => history.go(-1)} class="resetBtn" >
              Retour
            </a>
          </nav>
          <div className="head-details">
            <div className="row">
              <div className="col-md-6">
                <Hn
                  title={training.title}
                  level="h1"
                  dispaly
                  className="pt-4"
                  plus={false}
                />
                <p className="text text-white">
                  Parcours associé :
                  <span> {' '+training.assoc_cours}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="body-details p-3">
            <div className="row pt-3">
              <div className="col-lg-3 col-md-5 col-xs-12 pt-3">
                <div className="bg-details offset-md-2" style={{ background: `url(${img})` }}>
                  {/* <img src={img} className="float-end" width="80%" alt="..." /> */}

                </div>
              </div>
              <div className="col-lg-9 col-md-7 col-xs-6">
                <div className="row pt-3">
                  <div className="col-md-12">
                    <Hn
                      title="Prérequis et public visé"
                      level="h4"
                      plus={false}
                      className="pb-3"
                    />
                    {/* <Hn
                      title={training?.audience}
                      className="py-1 text-white"
                    /> */}
                    <p className="textBox ">
                      {training?.audience?.split(";").map((it, i) => (
                     
                        <Hn title={it.trim()} className="py-1 text-white" key={i} />
                      ))}
                    </p>
                  </div>
                </div>
                <div></div>
                <div className="row pt-4 pe-2">
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card text-white bg-dark mb-3 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Durée</h5>
                        <p className="card-text">{training?.duration} h</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card text-white bg-dark mb-3 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Tarif</h5>
                        <p className="card-text">{training?.cost} €</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card text-white bg-dark mb-3 text-center">
                     
                      <div className="card-body">
                    
                        <h5 className="card-title">Sessions</h5>
                        <p className="card-text">
                          {training?.sessions?.length}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card text-white bg-dark mb-3 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Places restantes</h5>
                        <p className="card-text">{nbplace}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 mb-5 m-1">
            <div className="col">
              <div className="row detailsSession">
                <div className="col-md-9 ">
                  <div className=" box-details">
                    <Hn
                      title="Objectifs de la formation"
                      level="h5"
                      className=""
                      plus={false}
                    />
                    {(training?.objective !== null && training?.objective != 'null') ?
                      <p className="textBox ">
                        {training?.objective?.split(";").map((it, i) => (
                          
                          <Hn title={it.trim()} className="py-1 text-white" key={i} />
                        ))}
                      </p> : <p className="text-white">Il n'y a pas d'objectifs</p>
                    }
                    <div
                      className="flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      {" "}
                      <Hn
                        title="Programme"
                        level="h5"
                        className="pt-5"
                        plus={false}
                      />
                      {(training?.programFile !== null && training?.programFile != 'null') ?
                        <a
                          href={docTraining + training?.programFile}
                          className="pt-5"
                          target="_blank"
                        >
                          Télécharger le programme
                        </a> :
                        <p className="text-white">Aucun programme à téléchrager</p>
                      }

                    </div>
                    {(training?.training_program !== null && training?.training_program != 'null') ?
                      <p className="textBox">
                        {training?.training_program?.split(";").map((it, i) => (
                       <Hn title={it.trim()} className="py-1 text-white" key={i} />
                        ))}
                      </p> : <p className="text-white">il n'a ya pas de programme</p>
                    }
                    <Hn
                      title="Commentaire"
                      level="h5"
                      className="pt-5"
                      plus={false}
                    />
                    {(training?.comment !== null && training?.comment != 'null') ?
                      <p className="textBox">

                        {training?.comment?.split(";").map((it, i) => (
                         
                          <Hn title={it.trim()} className="py-1 text-white" key={i} />
                        ))}
                      </p> : <p className="text-white">il n'a ya pas de commentaire</p>
                    }
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="box-details ">
                    <div>
                    {role.includes(localStorage.getItem("role"))
                      ?
                      <button
                        className="btn btn-primary bottomRight"
                        onClick={(e) => setShowfiltre(true)}
                      >
                        Inscription
                      </button>
                      : ""
                    }
                    </div>
                    <div className="pt-5">
                    <Hn title="Sessions" level="h5" className="" plus={false} />

                    {/* <p>{session.length}</p> */}

                    {
                      session.map((sess, key) => {
                        let place = parseInt(sess?.seat_capacity_max) - parseInt(sess?.contactSessions.length);
                        //there is no participants in the data

                        return (
                          <>
                            <p className="townSession mt-4" key={key}>
                              {sess?.location?.town}
                            </p>

                            <Hn
                              title={(new Date(sess?.dateStart))?.toLocaleDateString('fr', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              })}
                              className="text-white textBox"
                              plus={true}
                            />


                            {place <= 0 ? (
                              <p className="text-danger ps-5">Complet</p>
                            ) : (
                              <p className="text-success ps-5">
                                {place} places disponibles
                              </p>
                            )}
                          </>
                        );
                      })

                    }

</div>
                    
                    <Popupinscrire
                      showfiltre={showfiltre}
                      closefiltre={handleClosefiltre}
                      data={dataformation}
                      options={options}
                      collaborateur={collaborateur}
                      headers={headers}
                      uri={uri}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
