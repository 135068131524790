import React, { useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

export default function DateRange({valuedatarange = "", setValuedatarange }) {

  return (
    <div className='calendar'>
      <DateRangePicker
        onChange={setValuedatarange}
        value={valuedatarange}
        className="form-control flex-end" 
      />
    </div>
  );
}

