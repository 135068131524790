import axios from "axios";
import Hn from "../../../../../components/common/Hn";
import { createBrowserHistory } from "history";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "./apprenant.css";
import dateFormat from "dateformat";
export default function InfoApprenant() {
  const params = useParams();
  const uri = process.env.REACT_APP_BASE_URL;
  const [apprenant, setApprenant] = useState([]);
  const [elearnings, setElearnings] = useState([]);
  const [inputsearch, setSInputsearch] = useState(false);
  const [edit, setEdit] = useState(false);
  let history = createBrowserHistory({ forceRefresh: true });

 //récupérer un apprenant par son id
  function getapprenant(id) {
   
    axios
      .get(uri + `/participant/${id}`)
      .then((result) => {
        console.log("part", )
        setApprenant(result?.data[0]);
        getAttestations(result?.data[0]?.mailcontact);
      
      })
      .catch((err) => {
        console.log("err apprenant", err);
      });
  }
//rechercher un apprenant
  function findintable(e) {
    //  setElearnings([]);

    if (e.target.value.length > 0) {
      setSInputsearch(e.target.value);

      const filtreTexte = (arr, requete) => {
     

        return arr.filter(
          (el) =>
            el?.intitule.toLowerCase().indexOf(requete.toLowerCase()) !== -1 ||
            el?.duree
              .toString()
              .toLowerCase()
              .indexOf(requete.toLowerCase()) !== -1
          // ||(el?.score.indexOf(parseInt(requete)) !== -1)
        );
      };
      
      setElearnings(filtreTexte(elearnings, e.target.value));
    } else {
      getAttestations(apprenant?.mailcontact);
    }
  }

  //récupérer les attestations de l'apprenant choisi
  function getAttestations(userData) {
   
    axios
      .get(uri + "/watershed/get_user_data/" + userData)
      .then((result) => {
        setElearnings(result?.data);
      
      })
      .catch((err) => {
        console.log("err  attestations", err);
      });
  }
  // function getActionsByIdApprenant() {
  //     //TODO change with id apprenant
  //     axios.get(uri+"/elearning/")
  //         .then((result) => {
  //             setElearnings(result?.data);
  //         })
  //         .catch((err) => {
  //             console.log("err location", err);
  //         });
  // }

  useEffect(() => {
    getapprenant(params.id);
    // getActionsByIdApprenant();
  }, []);

  const Training = ({ item }) => {
    const [visible, setVisible] = useState(false);

    return (
      <>
        <tr>
          <td>{item?.intitule}</td>
          <td>{item?.chapitres?.length}</td>

          <td>{item?.date_debut}</td>
          <td>{item?.duree}</td>
          <td>{item?.score}</td>
          <td>
            {" "}
            <button type="button" className="btn btn-link">
              <img
                src={require("../../../../../assets/icons/ExportAttestation.png")}
              />
            </button>
          </td>
          <td>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => setVisible(!visible)}
            >
              <img
                src={
                  !visible
                    ? require("../../../../../assets/icons/dropdownclosed.png")
                    : require("../../../../../assets/icons/dropdown.png")
                }
              />
            </button>
          </td>
        </tr>

        {visible &&
          item?.chapitres.map((item, index) => (
            <tr className="expandRow" key={index}>
              {" "}
              <td></td>
              <td>{item.titre}</td>
              <td>{item.date}</td>
              <td>{item.duree}</td>
              <td>{item.score}</td>
              <td></td>
              <td></td>
            </tr>
          ))}
      </>
    );
  };

  const TrainingEdit = ({ item, onChange }) => {
    const [visible, setVisible] = useState(false);

    return (
      <>
        <tr>
          <td>{item?.intitule}</td>
          <td>{item?.chapitres?.length}</td>
          <td>
            <input
              className="form-control"
              type="date"
              value={item.date_debut}
            ></input>
          </td>
          <td>
            <input
              className="form-control"
              type="number"
              defaultValue={item.duree}
              onChange={(e) =>
                onChange(item.elearning_id, e.target.value, "duree")
              }
            ></input>
          </td>
          <td>
            <input
              className="form-control"
              type="number"
              value={item.score}
            ></input>
          </td>

          <td>
            {" "}
            <button type="button" className="btn btn-link">
              <img
                src={require("../../../../../assets/icons/ExportAttestation.png")}
              />
            </button>
          </td>
          <td>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => setVisible(!visible)}
            >
              <img
                src={
                  !visible
                    ? require("../../../../../assets/icons/dropdownclosed.png")
                    : require("../../../../../assets/icons/dropdown.png")
                }
              />
            </button>
          </td>
        </tr>

        {visible &&
          item?.chapitres.map((item, index) => (
            <tr className="expandRow" key={index}>
              {" "}
              <td></td>
              <td>{item.titre}</td>{" "}
              <td>
                <input type="date" value={item.date}></input>
              </td>
              <td>
                <input type="number" value={item.duree}></input>
              </td>
              <td>
                <input type="number" value={item.score}></input>
              </td>
              <td></td>
              <td></td>
            </tr>
          ))}
      </>
    );
  };

  const changeValue = (id, value, field) => {
    console.log(id, value);
    console.log("field", field);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists pe-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
              {params?.category == "elearning" ?
                <a href="/admin/apprenant">Formation e-learning - Apprenants</a>
:
<a href="/admin/apprenant">Formation presentielle - Apprenants</a>
}
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {apprenant?.contactPrenom + " " + apprenant?.contactNom}
              </li>
            </ol>

            <a
              type="submit"
              className="btn btn-link resetBtn"
              href="/admin/apprenant"
            >
              Retour
            </a>
            <br></br>
            <div className="row">
              <div className="col-md-6">
                <Hn
                  title={apprenant?.contactPrenom + " " + apprenant?.contactNom}
                  level="h4"
                  className="h4-size pb-5"
                  plus={true}
                />
              </div>
            </div>
          </nav>
          <div className="p-5 text-white bg-dark rounded-3">
            <Hn
              title="Informations générales"
              level="h4"
              className="h4-size pb-5"
              plus={false}
            />

            <div className="form-elements">
              <div className="row">
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Apprenant
                    </label>
                    <br></br>
                    <span>
                    {apprenant?.contactPrenom + " " + apprenant?.contactNom}
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Email
                    </label>
                    <br></br>
                    <span>{apprenant?.mailcontact}</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Métier
                    </label>
                    <br></br>
                    <span>{apprenant?.metier}</span>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="row">
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Agent immobilier
                      </label>
                      <br></br>
                      <span>{apprenant?.agencymain}</span>
                      <br></br>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Email agent immobilier
                      </label>
                      <br></br>
                      <span>{apprenant?.agenceEmail}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Nom de l'agence de rattachement
                      </label>
                      <br></br>
                      <span>{apprenant?.agenceImmobilier}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Adresse de l’agence
                      </label>
                      <br></br>
                      <span>{apprenant?.agenceAddress}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Code postal
                      </label>
                      <br></br>
                      <span>{apprenant?.codePostal}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Ville
                      </label>
                      <br></br>
                      <span>{apprenant?.ville}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Code GT
                      </label>
                      <br></br>
                      <span>{apprenant?.code_gt}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Offre de formation
                      </label>
                      <br></br>
                      <span>{apprenant?.offre}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Date d'entrée
                      </label>
                      <br></br>
                      <span>{dateFormat(apprenant?.dateentree, "dd-mm-yyyy")}</span>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {elearnings?.length > 0 ? (
            <>
              <div className="lists-filter my-5">
                <div className="row">
                  <div className="col-xl-4 col-lg-1"></div>
                  <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                    <div className="flexgy">
                      <div className="col-md-4  mr-1  px-0">
                        <div className="input-group">
                          <input
                            className="form-control height43"
                            type="search"
                            placeholder="Recherche"
                            onChange={findintable}
                            //onChange={(e=>props.searchProps.onSearch(e.target.value))}
                          />
                        </div>
                      </div>
                      <div className=" px-0 myxs">
                        <div className="flex">
                          <div className={edit ? "blue square" : "square"}>
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={() => setEdit(true)}
                            >
                              <img
                                src={
                                  edit
                                    ? require("../../../../../assets/icons/editwhite.png")
                                    : require("../../../../../assets/icons/edit.png")
                                }
                              />
                            </button>
                          </div>
                          {/* <PopupFiltre
                                                            showfiltre={showfiltre}
                                                            closefiltre={handleClosefiltre}
                                                        /> */}
                          <div className="square">
                            <button type="button" className="btn btn-link">
                              <img
                                src={require("../../../../../assets/icons/filter.png")}
                              />
                            </button>
                          </div>
                          <div className="square">
                            <button
                              //</div>onClick={()=>props.csvProps.onExport()}
                              type="button"
                              className="btn btn-link"
                            >
                              <img
                                src={require("../../../../../assets/icons/Export2.png")}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <table className="table table-dark table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Action de formation</th>
                      <th scope="col">Unités d'apprentissage</th>
                      <th scope="col">Date</th>
                      <th scope="col">Durée</th>
                      <th scope="col">Score</th>
                      <th scope="col">Attestation</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {elearnings.map((item, index) =>
                      edit ? (
                        <TrainingEdit
                          onChange={(id, value, field) =>
                            changeValue(id, value, field)
                          }
                          key={index}
                          item={item}
                        ></TrainingEdit>
                      ) : (
                        <Training item={item}></Training>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <Hn
              title="Aucune attestation trouvée"
              level="h5"
              plus={false}
              className="text-center mt-5"
            />
          )}
        </div>
      </div>
    </div>
  );
}
