import { useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import PopupFiltrethematique from "../../components/popup/PopupFiltrethematique";
import Papa from "papaparse";
import AxioServices from "../../services/AxioServices";

// import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Alert, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Datatable({
  getThematique,
  thematiques,
  setShow,
  show,
  setTematique,
}) {
  let history = createBrowserHistory({ forceRefresh: true });
  const [showfiltre, setShowfiltre] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ident, setIdent] = useState();
  const [initialThematique, setInitialThematique] = useState([]);
  const [id, setId] = useState();
  const [eachEntry, setEachEntry] = useState("");
  let toastId = null;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { ExportCSVButton } = CSVExport;
  const uri = process.env.REACT_APP_BASE_URL;
  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };
  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push("/admin/thematique/" + row.id);
  
    },
  };
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }
  function deletethematique(id) {
   
    axios.delete(uri + "/thematic/" + id).then((response) => {
      
      if (response.data === "theme-null") {
        displayToast("Cerror", "Vous ne pouvez pas supprimer cette thématique");
      } else {
        displayToast(
          "Csuccess",
          "Suppression effectuer avec success, Reload..."
        );
        setTimeout(() => {
          history.go(0);
        }, 3000);
      }
      // getThematique();
    });
    setShow(false);
  }
  function searchwithfiltre(val) {
    setInitialThematique([]);
    setEachEntry(val);
    
      if (val.ref==="" && val.name==="") {
       
        getThematique();
      //  setParticipant(initialparticipant)
      }
      else{
        setTematique([]);
        let themeFilter =  thematiques.filter(el =>    
        ( el?.ref?.toLowerCase().includes(val.ref.toLowerCase())&& (val.ref.length>0))
        || (el?.name?.toLowerCase() === val.name.toLowerCase() && (val.name.length>0))
        )
        
     
        setTematique(themeFilter)
       }
    
  }
  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        results.data[0].ref = results.data[0].ref + "x2";
      
        AxioServices.create("thematic/import", JSON.stringify(results.data), {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*",
          },
        })
          .then((result) => {
            displayToast("Csuccess", "Importation en cours, Redirection...");
            setTimeout(() => {
              history.go(0);
            }, 5000);
          })
          .catch((err) => {
            displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
          });
      },
    });
  };

  const handleShowfiltre = () => {
    setShowfiltre(true);
  };
  const handleClosefiltre = (obj) => {
   
    setShowfiltre(false);
  };
  function duplicate(data) {
   
    let sub = [];
    data.subtheme.forEach((element) => {
      sub.push(element.name);
  
    });
    let datas = {
      ref: "Duplicate of " + data.ref,
      name: data.name,
      subtheme: sub,
    };
  
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axios
      .post(uri + `/thematic/new`, datas, headersFiles)
      .then((result) => {
        displayToast("Csuccess", "Thématique dupliqué avec success");
        setTimeout(() => {
          history.go(0);
        }, 3000);
      })
      .catch((err) => {
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
  }

  const columns = [
    {
      dataField: "ref",
      text: "Réference",
      csvText: "ref",
      sort: true,
    },
    {
      dataField: "name",
      text: "Nom de la thématique",
      csvText: "name",
      sort: true,
    },
    {
      dataField: "subtheme",
      text: "Nombre de sous Thèmes",
      csvText: "subtheme",
      formatter: (row) => {
        return <>{row.length}</>;
      },
      sort: true,
    },

    {
      dataField: "follow",
      text: "",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="btn-group float-end" role="group" aria-label="Basic example">
            <a
              href={"/admin/updatethematique/" + row.id}
              type="button"
              className="btn btn-link"
              stopPropagation
            >
              <img src={require("../../assets/icons/edit.png")} />
            </a>

            <button
              type="button"
              className="btn"
              onClick={() => {
                setId(row.id);
                handleShow();
              }}
              stopPropagation
            >
              <img src={require("../../assets/icons/delete.png")} />
            </button>

            <button
              type="button"
              className="btn"
              stopPropagation
              onClick={() => {
                duplicate(row);
              }}
            >
              <img src={require("../../assets/icons/duplicate.png")} />
            </button>
          </div>
        );
      },
      sort: true,
    },
  ];
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    // hideSelectAll: true,
  };
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    const onFollowChanged = () => {
      this.setState({ isFollow: !this.state.isFollow });
    
    };

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Button
          onClick={() => {
            this.onFollowChanged(row);
          }}
        >
          Follow
        </Button>
      );
    };
    
    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };
  let rows = thematiques.map((item, i) => item);
  return (
    <>
      <ToastContainer />
      <ToolkitProvider
        keyField="id"
        data={rows}
        columns={columns}
        exportCSV={{ onlyExportSelection: true, exportAll: false }}
        search
      >
        {(props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter my-5 pe-3">
              <div className="row ">
                <div className="col-xl-4 col-lg-1"></div>
                <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                  <div className="flexgy">
                    <div className="col-md-4  mr-1  px-0">
                      <div className="input-group">
                        <input
                          className="form-control height43"
                          type="search"
                          placeholder="Recherche"
                          onChange={(e) =>
                            props.searchProps.onSearch(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="px-0 myxs">
                      <div className="flex">
                        <div className="square">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={handleShowfiltre}
                          >
                            <img
                              src={require("../../assets/icons/filter.png")}
                            />
                          </button>
                        </div>
                        <PopupFiltrethematique
                          showfiltre={showfiltre}
                          closefiltre={handleClosefiltre}
                          searchwithfiltre={searchwithfiltre}
                          filterEntry={eachEntry}
                        />
                        <div className="square">
                          <label className="btn btn-link" htmlFor="export">
                            <button
                              onClick={() => props.csvProps.onExport()}
                              type="button"
                              className="btn btn-link"
                            >
                              <img
                                src={require("../../assets/icons/Export.png")}
                              />
                            </button>
                          </label>
                        </div>
                        <div className="square">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept={".csv"}
                            className="btn btn-link"
                            onChange={handleOnChange}
                            hidden
                          />
                          <label for="file" type="button">
                            <img
                              src={require("../../assets/icons/Import.png")}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="px10">
                      <div className="d-grid gap-2">
                        <a
                          href="/admin/createthematique"
                          className="btn btn-primary btn-gy height43"
                          type="button"
                        >
                          Créer une thématique
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BootstrapTable
              {...props.baseProps}
              classes="table-dark"
              bordered={false}
              hover
              selectRow={selectRow}
              pagination={paginationFactory(options)}
              rowEvents={tableRowEvents}
            />
          </div>
        )}
      </ToolkitProvider>

      <Modal show={show} onHide={handleClose} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            Etes-vous sûr(e) de vouloir supprimer{" "}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={handleClose} style={{ color: "#006FFF" }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-primary"
            onClick={(e) => deletethematique(id)}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
