import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import * as Yup from "yup";
import "./createUnite.css";
import { trackPromise } from 'react-promise-tracker';
import toast, { Toaster } from 'react-hot-toast';
import Delete from "../../../../../assets/icons/delete.png";


function DeleteUniteDeFormation({ uniteToBeDeleted }) {
    const [showModal, setShowModal] = useState(false);
    const uri = process.env.REACT_APP_BASE_URL;

    const deleteUnite = (unite) => {
        trackPromise(
            axios.post(uri + "/unite/" + unite?.id)
                .then((result) => {
                    hideModal();
                    toast.success("Cette unité de formation a bien été supprimée!");
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                })
                .catch((err) => {
                    hideModal();
                    toast.error("Désolé vous ne pouvez pas supprimer cette unité de formation");
                    console.log("err sessions", err);
                }));
    }

    const showModalFunction = (unite) => {
        setShowModal(unite);
        console.log('setShowModal', showModal);
    }

    const hideModal = () => {
        setShowModal(false);
    }

    useEffect(() => {

    }, [uniteToBeDeleted]);


    return (
        <>
            <p><span className="cursor-pointer" onClick={(e) => showModalFunction(uniteToBeDeleted)}><img src={Delete} className="pe-1" /></span></p>
            <Modal show={showModal} onHide={(e) => hideModal()} className="deleteModal">
                <Modal.Header closeButton>
                    <Modal.Title className="modaltitle">
                        Etes-vous sûr(e) de vouloir supprimer "{uniteToBeDeleted?.titre}" ?
                    </Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <Button variant="" onClick={(e) => hideModal()} style={{ color: "#006FFF" }}>
                        Annuler
                    </Button>
                    <Button
                        type="button"
                        variant="btn btn-danger"
                        onClick={(e) => deleteUnite(uniteToBeDeleted)}
                    >
                        Supprimer
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default DeleteUniteDeFormation;
