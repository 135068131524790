import axios from "axios";
import dateFormat from "dateformat";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Hn from "../../../../../components/common/Hn";
import moment from "moment";
import "./../session.css";

import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { createBrowserHistory } from 'history';
import "./createFaceTraining.css";
import UniteApprentissage from "./UniteApprentissage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function UpdateSession() {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  var dte = new Date();
  const uri = process.env.REACT_APP_BASE_URL;
  const params = useParams();
  const [session, setSession] = useState([]);
  const [formateurs, setForamteurs] = useState([]);
  const [lieuFormation, setLieuForamtion] = useState([]);
  const [participe, setParticipe] = useState([]);
  const [changeListformer, setChangeListformer] = useState(true);
  const [training, setTraining] = useState([]);
  let toastId = null;
  let history = useHistory();
  const onHandelStatus = (touched, error, value) => {
    if (error && touched) {
      return "danger";
    } else if (value) {
      return "info";
    } else {
      return "warning";
    }
  };
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      console.log("Toast already active");
    }
  }
  const handleSubmit = (values) => {

    axios
      .put(`${uri}/session/edit/${params.id}`, values, headers)
      .then((result) => {


        displayToast("Csuccess", "Modification effectuer avec success.");
        history.push("/admin/sessions/");

      })
      .catch((err) => {
        console.log("err bd", err);
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");

      });
  };
  function getformer(type) {
    let url = "/former";
    if (type) {
      url = "/former/organize/" + type;
    }
    axios
      .get(uri + url, headers)
      .then((result) => {
        setForamteurs(result?.data);

      })
      .catch((err) => {
        console.log("err formateurs", err);
      });
  }
  function getlocation() {
    axios
      .get(uri + "/location")
      .then((result) => {
        setLieuForamtion(result?.data);
      })
      .catch((err) => {
        console.log("err location", err);
      });
  }
  function getTraining() {
    axios
      .get(uri + "/training", headers)
      .then((result) => {
        setTraining(result?.data);

      })
      .catch((err) => {
        console.log("err training", err);
      });
  }
  function getUnitySession() {
    axios
      .get(uri + "/session/" + params.id, headers)
      .then((result) => {
        setSession(result?.data);



      })
      .catch((err) => {
        console.log("err ", err);
      });
  }
  function ChangeList(value) {
    setChangeListformer(false);
    getformer(value);
  }
  useEffect(() => {
    getUnitySession();
    getlocation();
    getTraining();
    getformer(session?.former?.organize)

  }, []);


  return (
    <div className="bunner-provider container-fluid session1">
      <ToastContainer />
      <div className="row pe-3">
        <div className=" container lists pe-4">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/Sessions">Formations présentielles - session de formation</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page"></li>
            </ol>
          </nav>




          <div className="row mt-30 mb-135">
            <div className="col-12">
              <div className="h-100 p-5 text-white bg-dark rounded-3">
                <Hn
                  title={"Modifier la session : " + session.ref}
                  level="h4"
                  className="h4-size pb-5"
                  plus={false}
                />
                <Hn
                  title="Informations générales"
                  level="h5"
                  className="h4-size pb-5"
                  plus={false}
                />
                <div className="form-elements">
                  <div className="row g-3">

                    <Formik
                      initialValues={{
                        ref: session?.ref,
                        training: session?.training?.id,
                        // dateStart: dateFormat(session?.dateStart, "yyyy-mm-dd"),
                        dateStart: dateFormat(session?.dateStart, "yyyy-mm-dd"),
                        dateEnd: dateFormat(session?.dateEnd, "yyyy-mm-dd"),
                        location: session?.location?.id && "",
                        seat_capacity_min: session?.seat_capacity_min,
                        seat_capacity_max: session?.seat_capacity_max,
                        former: session?.former?.id,
                      }}
                      // validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                      enableReinitialize
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                        <form className="row g-3" onSubmit={handleSubmit}>

                          <div className="col-md-12">
                            <label className="form-label">
                              Action de formation
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              name="training"
                              //defaultValue={session?.training?.id}
                              value={values.training || session?.training?.id}
                              onChange={handleChange("training")}
                              onBlur={handleBlur("training")}
                              errors={errors.training}
                            >

                              {training.map((train) => {
                                return (
                                  <option value={train.id}
                                  >

                                    {train?.title}</option>
                                );
                              })}
                            </select>
                            <ErrorMessage name="training">
                              {(msg) => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>
                          </div>
                          <div className="col-md-4">
                            <label className="form-label">Référence*</label>
                            <input
                              status={onHandelStatus(
                                touched.ref,
                                errors.ref,
                                values.ref
                              )}
                              type="text"
                              className="form-control"
                              id="ref"
                              placeholder="Référence"
                              onChange={handleChange("ref")}

                              value={session.ref}
                              onBlur={handleBlur("ref")}
                              errors={errors.ref}
                              disabled
                            />
                            <ErrorMessage name="ref">
                              {(msg) => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>
                          </div>
                          <div className="col-md-4">
                            <label className="form-label">Date de début
                            </label>
                            <input
                              status={onHandelStatus(
                                touched.dateStart,
                                errors.dateStart,
                                values.dateStart
                              )}
                              type="date"
                              className="form-control"
                              name="dateStart"
                              id="dateStart"
                              defaultValue={session?.dateStart}

                              value={values?.dateStart}
                              min={dateFormat(session?.dateStart, "dd/mm/yyyy")}
                              max={values.dateEnd}
                              onChange={handleChange("dateStart")}

                              onBlur={handleBlur("dateStart")}
                              errors={errors.dateStart}
                            />
                            <ErrorMessage name="dateStart">
                              {(msg) => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>
                          </div>
                          <div className="col-md-4">
                            <label className="form-label">Date de fin</label>
                            <input
                              status={onHandelStatus(
                                touched.dateEnd,
                                errors.dateEnd,
                                values.dateEnd
                              )}
                              type="date"
                              className="form-control"
                              id="dateEnd"
                              placeholder="Date de fin"
                              onChange={handleChange("dateEnd")}
                              defaultValue={dateFormat(session?.dateEnd, "yyyy-mm-dd")}
                              value={values.dateEnd}
                              min={values.dateStart}
                              onBlur={handleBlur("dateEnd")}
                              errors={errors.dateEnd}
                            />
                            <ErrorMessage name="dateEnd">
                              {(msg) => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>
                          </div>


                          <div className="col-md-4">
                            <label className="form-label">Date de fin</label>
                            <input
                              status={onHandelStatus(
                                touched.dateEnd,
                                errors.dateEnd,
                                values.dateEnd
                              )}
                              type="date"
                              className="form-control"
                              id="dateEnd"
                              placeholder="Date de fin"
                              onChange={handleChange("dateEnd")}
                              defaultValue={dateFormat(session?.dateEnd, "yyyy-mm-dd")}
                              value={values.dateEnd}
                              min={values.dateStart}
                              onBlur={handleBlur("dateEnd")}
                              errors={errors.dateEnd}
                            />
                            <ErrorMessage name="dateEnd">
                              {(msg) => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>
                          </div>

                          <div className="col-md-4">
                            <label className="form-label">Lieu de formation*</label>
                            <select
                              status={onHandelStatus(
                                touched.location,
                                errors.location,
                                values.location
                              )}
                              className="form-select"
                              aria-label="Default select example"
                              name="location"
                              id="location"
                              onChange={handleChange("location")}
                              onBlur={handleBlur("location")}
                              errors={errors.location}
                            >
                              <option>sélectionner dans la liste</option>
                              {lieuFormation.map((lieu, i) => {
                                return (
                                  <option value={lieu?.id} selected={lieu?.id === session?.location?.id} key={i}>{lieu?.address}</option>
                                );
                              })}
                            </select>
                            <ErrorMessage name="location">
                              {(msg) => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>
                          </div>
                          <div className="col-md-4">
                            <label className="form-label">
                              Capacité d'acccueil minimale
                            </label>
                            <input
                              status={onHandelStatus(
                                touched.seat_capacity_min,
                                errors.seat_capacity_min,
                                values.seat_capacity_min
                              )}
                              type="number"
                              className="form-control"
                              id="seat_capacity_min"
                              // max={values.seat_capacity_max}
                              placeholder="00"
                              onChange={handleChange("seat_capacity_min")}
                              defaultValue={session?.seat_capacity_min}
                              value={values.seat_capacity_min}
                              onBlur={handleBlur("seat_capacity_min")}
                              errors={errors.seat_capacity_min}
                            />
                            <ErrorMessage name="seat_capacity_min">
                              {(msg) => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>
                          </div>
                          <div className="col-md-4">
                            <label className="form-label">
                              Capacité d'acccueil maximale*
                            </label>
                            <input
                              status={onHandelStatus(
                                touched.seat_capacity_max,
                                errors.seat_capacity_max,
                                values.seat_capacity_max
                              )}
                              type="number"
                              className="form-control"
                              id="seat_capacity_max"
                              placeholder="00"
                              //min={values.seat_capacity_min}
                              onChange={handleChange("seat_capacity_max")}
                              defaultValue={session?.seat_capacity_max}
                              value={values.seat_capacity_max}
                              onBlur={handleBlur("seat_capacity_max")}
                              errors={errors.seat_capacity_max}
                            />
                            <ErrorMessage name="seat_capacity_max">
                              {(msg) => <div className="text-danger">{msg}</div>}
                            </ErrorMessage>
                          </div>

                          <div className="col-md-4">
                            <label className="form-label">Formateur</label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              id="actif"
                              defaultValue={"sélectionner dans la liste"}
                              onChange={(event) => ChangeList(event.target.value)}
                            >
                              <option value="">sélectionner dans la liste</option>
                              <option value="interne" selected={session?.former?.organize === "interne"}>Interne</option>
                              <option value="externe" selected={session?.former?.organize === "externe"}>Externe</option>
                            </select>
                          </div>
                          <div className="col-md-4">
                            <label className="form-label">&nbsp;</label>
                            <select
                              className="form-select"
                              name="former"
                              defaultValue={session?.former?.id}
                              value={values.former || session?.former?.id}
                              onChange={handleChange("former")}
                              onBlur={handleBlur("former")}
                              errors={errors.former}
                            //disabled={changeListformer}
                            >
                              <option >
                                sélectionner dans la liste
                              </option>
                              {formateurs.map((former, i) => {
                                return (
                                  <option value={former?.id} key={i}>
                                    {former?.lastName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className="col-12 mt-5">

                            <a
                              href="/admin/Sessions"
                              className="btn btn-link text-decoration-none resetBtn"
                            >
                              Annuler
                            </a>

                            <button
                              //href="/admin/create-session-formation-apprendre"
                              type="submit"
                              className="btn btn-primary float-end"
                            >
                              Valider
                            </button>


                          </div>
                        </form>
                      )}
                    </Formik>
                    <div className="row">
                      <Hn
                        title="Unités de formation"
                        level="h4"
                        className="h4-size pb-5 pt-5"
                        plus={false}
                      />
                      <div className="row pt-5">
                        {session?.lessons?.map((item, index) => {
                          return (<>
                            <UniteApprentissage
                              session={session}
                              nbUnite={index}
                              former={formateurs}
                              lieuFormation={lieuFormation}
                              lesson={item}
                              ind={item.id}
                              key={index}
                            />
                          </>)
                        })}
                        {/* <Participant
                              setParticipe={setParticipe}
                              participe={participe}
                              sessions={session}
                            /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UpdateSession;
