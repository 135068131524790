import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Link, Font } from '@react-pdf/renderer';

import logodoc from "../../assets/logodoc.png";
import fontRegular from '../../assets/font/Montserrat/Montserrat-Regular.ttf';
import fontItalic from '../../assets/font/Montserrat/Montserrat-Italic.ttf';
import fontBold from '../../assets/font/Montserrat/Montserrat-Bold.ttf';
import { Table, TableBody, TableHeader, TableCell, DataTableCell } from '@david.kucsai/react-pdf-table';

Font.register({
  family: 'Montserrat',
  fonts: [
    { src: fontRegular }, // font-style: normal, font-weight: normal
    { src: fontItalic, fontStyle: 'italic' },
    { src: fontBold, fontStyle: 'bold' }
  ]
});

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 20,
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: 800,
    color: '#000000'
  },
  section: {
    paddingBottom: 10,
  },
  header: {
    paddingHorizontal: 30,
    paddingRight: 30,
    marginBottom: 5,
    fontFamily: "Montserrat",
    textAlign: 'center',
    fontSize: 9,
    color: '#000000',
    fontStyle: 'bold',
    fontWeight: 'bold',
    borderWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
  },
  image: {
    // marginHorizontal: 30,
    // paddingRight: 30,
    // marginVertical: 15,
    width: 150,
  },
  text: {
    // paddingHorizontal: 30,
    // paddingRight: 30,
    paddingBottom: 5,
    fontFamily: "Montserrat",
    textAlign: 'justify',
    fontSize: 9,
    color: '#000000'
  },
  title: {
    paddingBottom: 8,
    fontSize: 9,
    fontFamily: 'Montserrat',
  },
  address: {
    fontFamily: 'Montserrat',
    fontSize: 9,
    textAlign: 'left'
  },
  author: {
    fontFamily: 'Montserrat',
    fontSize: 9,
    marginBottom: 5,
    // fontWeight: 'bold',
  },
  textBold: {
    fontSize: 9,
    fontFamily: "Montserrat",
    fontStyle: 'bold'
  },
  textFooter: {
    // marginHorizontal: 30,
    paddingBottom: 12,
    textAlign: 'justify',
    fontSize: 8,
    fontFamily: "Montserrat",
    fontStyle: 'italic',
    color: 'gray'
  },
  textLeft: {
    paddingBottom: 18,
    fontFamily: "Montserrat",
    fontSize: 9,
    textAlign: 'right'
  },
  textLink: {
    fontFamily: "Montserrat",
    fontSize: 9,
    textDecoration: 'none',
    color: '#000'
  },
  texLinkFooter: {
    fontFamily: "Montserrat",
    // paddingHorizontal: 30,
    marginTop: '-10',
    paddingRight: 30,
    fontSize: 9
  },
  subText: {
    fontStyle: 'bold', fontWeight: 'bold'
  }
});

function ConventionPDFDOC(props) {

  let data = props.data;

  return (
    <Document>
      <Page size="A4" style={styles.page}>

        <Image style={styles.image} src={logodoc}></Image>
        <View style={{ paddingBottom: 10 }}>
          <Text style={styles.address}>Paris Porte de Gentilly - 39 avenue Paul Vaillant Couturier</Text>
          <Text style={styles.address}>CS 60511- 94258 Gentilly - Tél. 01 58 46 44 44</Text>
          <Link src='serviceclients@guy-hoquet.com' style={styles.textLink}>serviceclients@guy-hoquet.com</Link>
        </View>
        <Text style={styles.header}>
          CONVENTION BILATERALE DE FORMATION PROFESSIONNELLE CONTINUE
        </Text>
        <Text style={styles.title}>
          Entre les soussignés : La société <Text style={styles.subText}>{data[0]?.address} </Text>
          Et l’organisme de Formation Professionnelle Continue : Groupe Guy Hoquet l’Immobilier
          39 avenue Paul Vaillant Couturier – 94250 GENTILLY - SARL au Capital de 167.693 € R.C.S. Paris B 389011537, 
          enregistré en tant qu’organisme privé de formation professionnelle sous le N°11940521294, auprès du Préfet de la région Ile de France, 
          ci-après désigné par le terme « l’organisme » représenté par : Monsieur Laurent OTERO, directeur des opérations.
        </Text>
        <Text style={styles.author}>
          Est conclue la CONVENTION suivante, en application des dispositions du LIVRE IX du Code du Travail portant organisation de la formation professionnelle continue dans le cadre de l’éducation permanente et des articles R 950.1 et suivants de ce livre.
        </Text>

        <Text style={styles.textBold}>
          Article 1 : Objet de la convention
        </Text>
        <Text style={styles.text}>
          En exécution de la présente convention, l’organisme s’engage à organiser l’action de formation prévue à l’annexe pédagogique et dans les conditions fixées par les articles suivants.
        </Text>

        <Text style={styles.textBold}>
          Article 2 : Nature et caractéristiques des actions de formation
        </Text>
        <Text style={styles.text}>
          L’action envisagée rentre dans cette catégorie prévue à l’article L900-2 et L6313-1 du Code du travail : perfectionnement des connaissances.
        </Text>

        <Text style={styles.textBold}>
          Article 3 : Dispositions financières
        </Text>
        <Text style={styles.text}>
          Obligation de l’entreprise signataire. Le coût de la formation est de :
        </Text>

        <Table
          data={[
            {cost: 0, costHT: 0, tva: "TVA 20,00 % à ajouter au  coût de la formation ht"}
          ]}
        >
          <TableHeader textAlign='center'>
            <TableCell style={{fontSize: 10}}>
              Coût
            </TableCell>
            <TableCell style={{fontSize: 12}}>
              Montant Euros HT
            </TableCell>
            <TableCell style={{fontSize: 12}}>
              Montant Euros TTC
            </TableCell>
          </TableHeader>
          <TableBody textAlign='center'>
            <DataTableCell getContent={(r) => r.cost.toLocaleString()} />
            <DataTableCell getContent={(r) => r.costHT.toLocaleString()} />
            <DataTableCell getContent={(r) => r.tva} />
          </TableBody>
        </Table>

        <View style={{ marginTop: 8, paddingLeft: 20 }}>
          <Text style={styles.textBold}>
            a) Utilisation des fonds
          </Text>
          <Text style={styles.text}>
            En exécution de la présente convention, l’organisme s’engage à organiser l’action de formation prévue à l’annexe pédagogique et dans les conditions fixées par les articles suivants.
          </Text>

          <Text style={styles.textBold}>
            b) Obligation de l’organisme
          </Text>
          <Text style={styles.text}>
            En contrepartie des versements reçus, l’organisme s’engage à réaliser toutes les actions prévues dans le cadre de la présente convention et à fournir tous documents et pièces de nature à justifier la réalité et la validité des dépenses de formation.
          </Text>
        </View>

        <Text style={styles.textBold}>
          Article 4 : Résiliation de la convention
        </Text>
        <Text style={styles.text}>
          Il peut être mis fin par l’une ou l’autre des parties à la présente convention avant la fin de la période de validité avec un préavis de 15 jours, signifié par lettre recommandée avec accusé de réception.
        </Text>
        <Text style={styles.textBold}>
          Article 5 :  Cas de différend
        </Text>
        <Text style={styles.text}>
          Si une contestation ou un différend n’ont pu être réglés à l’amiable, le Tribunal de Commerce de Paris sera seul compétent pour régler le litige.
        </Text>

        <Text style={styles.header}>
          ANNEXE PEDAGOGIQUE A LA CONV. BILAT. DE FORMA. PROF. CONTINUE
        </Text>

        <Text style={styles.text}><Text style={styles.subText}>Catégorie du stage :</Text> Habilitation professionnelle aux métiers de l’immobilier</Text>
        <Text style={styles.text}><Text style={styles.subText}>Objectif du stage :</Text> Apporter aux participants les connaissances indispensables pour une parfaite maîtrise des sujets traités.</Text>
        <Text style={styles.textBold}>Intitulé– Lieu – Date – Durée du stage :</Text>

        <view style={{ marginTop: 8, paddingLeft: 20 }}>
          <Text style={styles.address}>Intitulé :  <Text style={styles.subText}>{data[1].intitule}</Text></Text>
          <Text style={styles.address}>Lieu : <Text style={styles.subText}>{data[0]?.address}</Text> </Text>
          <Text style={styles.address}>Date : <Text style={styles.subText}>{data[1].date_debut}, {data[1].date_fin}</Text></Text>
          <Text style={styles.address}>Durée : <Text style={styles.subText}>{data[1].duree}h</Text></Text>

          <Text style={[styles.textBold, { marginVertical: 6 }]}>Identité du(des) stagiaire(s) : {data[0]?.nom} - {data[0]?.prenom}</Text>
        </view>

        <View>
          <Text style={styles.address}><Text style={styles.subText}>Nombre de stagiaire(s) :</Text> 1</Text>
          <Text style={styles.address}><Text style={styles.subText}>Modalités de déroulement pédagogique :</Text> présentiel, vidéoprojecteur, paperboard</Text>
          <Text style={styles.address}><Text style={styles.subText}>Modalités de sanction de formation :</Text> feuille de présence, attestation de présence, évaluation du participant par le formateur</Text>
        </View>

        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginHorizontal: 20 }}>
          {/* <Text style={styles.text}>
            Fait en double exemplaire à Gentilly, le {moment().format('DD MMM YYYY')}
          </Text> */}
          <Text style={styles.text}>
            L’entreprise{"\n"}
            Signature
          </Text>
          <Text style={styles.text}>
            S.A GUY HOQUET L’IMMOBILIER{"\n"}
            Laurent OTERO – Directeur des opérations
          </Text>
          <Text style={styles.text}>
            Signature{"\n"}
            Le stagiaire
          </Text>
          {/* <Text style={styles.text}>
            Cachet de l'organisme et signature :
          </Text> */}
        </View>

        <Text style={[styles.text, { marginTop: 12 }]}>
          S.A.S. au capital de 167 693,92 euros - RCS Créteil B 389 011 537 - APE 7740Z - Carte professionnelle n° CPI 9401 2016 000 018 126 délivrée par la
          préfecture de Créteil Caisse de garantie CEGC - 16 rue Hoche Tour Kupka B - TSA 39999 92912 La Défense cedex - TVA intracommunautaire
          n° FR 75 389 011 537 – Chaque agence est juridiquement et financièrement indépendante.
        </Text>

      </Page>
    </Document>

  )
}

export default ConventionPDFDOC;