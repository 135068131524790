import axios from "axios";
import { useState } from "react";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { createBrowserHistory } from "history";

import Hn from "../../../../../../components/common/Hn";
import PopupFiltre from "../../../../../../components/popup/PopupFiltreParticipant";
export default function ParticpantDatatable({
  id_session,
  participant,
  getUnitySession,
  setParticipant,
  type
}) {

  let history = createBrowserHistory({ forceRefresh: true });

  const { ExportCSVButton } = CSVExport;
  const uri = process.env.REACT_APP_BASE_URL;
  const [show, setShow] = useState(false);
  const [initialparticipant, setInitialParticipant] = useState([]);
  const [showfiltre, setShowfiltre] = useState(false);
  const [displayetat, setDisplayetat] = useState("false");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [id, setId] = useState();
  const LEFT_PAGE = 'LEFT';
  const RIGHT_PAGE = 'RIGHT';
  let rows = [];
  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };

  const params = useParams();
  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
    searchwithfiltre(obj);
  };
  const handleShowfiltre = () => {
    setShowfiltre(true);
  };



  function searchwithfiltre(val) {
    setInitialParticipant(participant);
    if (val) {
      setParticipant([]);
      let participantsFilter = participant.filter(el =>

        (el.apprenant.toLowerCase().includes(val.collaborator.toLowerCase()) && (val.collaborator.length > 0))
        || el?.mailcontact.toLowerCase() === val.email.toLowerCase()
        || (el?.agencymain.toLowerCase().includes(val.agent.toLowerCase()) && (val.agent.length > 0))
        || el?.agenceEmail.toLowerCase() === val.emailAgent.toLowerCase()
      )
      setParticipant(participantsFilter)
    }
  }
  function updatePresence(id, presence) {
    let datasend = {
      participant: id,
      presence: presence,
    };
    axios
      .put(`${uri}/session/edit/presence/` + id_session, datasend, headers)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

  }

  function deleteParticipant(id_participant) {
    let api_delete;
    let datasend = {
      participant: id_participant,
    };

    let datasendDelete = {
      participant: [id_participant],
    };

    let datasendWebinars = {
      participant: id_participant,
    };
    let datas;
    if (type === "webinars") {
      api_delete = `webinars/delete/particiapnt/${params.id}`;
      datas = datasendWebinars
    } else {
      api_delete = `session/delete/particiapnt/` + localStorage.getItem('idsession');
      datas = datasendDelete
    }

    axios
      .put(`${uri}/${api_delete}`, datas, headers)
      .then((response) => {
        console.log();
        setShow(false);

        if (type !== "webinars") {
          setTimeout(() => {
            getUnitySession();
          }, 2000);
        }
        setTimeout(() => {
          history.go(0);
        }, 500);

      })
      .catch((error) => {
        // displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
  }

  const columns = [
    {
      dataField: "contactPrenom",
      text: "Collaborateur",
      csvText: "collaborator",

    },
    {
      dataField: "mailcontact",
      text: "Email",
      csvText: "email",
      sort: true,
    },
    {
      dataField: "agencymain",
      text: "Agent",
      csvText: "agent",
      sort: true,
    },
    {
      dataField: "agenceEmail",
      text: "Email agent",
      csvText: "emailAgent",
      sort: true,
    },

    {
      dataField: "presence",
      text: "Présence",
      csvText: "presence",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <select
            class="form-select"
            defaultValue={row?.presence}
            onChange={(event) => {
              // setPresence(event.target.value);
              updatePresence(row.id, event.target.value);
            }}
          >
            <option value="prevu" selected={row?.presence === "prevu" ? "selected" : ""}>
              Prévu{" "}
            </option>
            <option value="absent" selected={row?.presence === "absent" ? "selected" : ""}>
              Absent
            </option>

            <option value="annule" selected={row?.presence === "annule" ? "selected" : ""}>
              Annulé
            </option>

            <option value="present" selected={row?.presence === "present" ? "selected" : ""}>
              Présent
            </option>
          </select>
        );
      },
      sort: true,
    },

    {
      dataField: "offre",
      text: "Offre",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <span>{row.offre ? "Oui" : "Non"}</span>;
      },
    },

    {
      dataField: "metier",
      text: "Manager",
      csvText: "manager",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <span>{row.metier === "Manager" ? "Oui" : "Non"}</span>;
      },
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div>
            <button
              className="btn btn-link"
              onClick={() => {
                setId(row.id);
                handleShow();
              }}
            >
              <img src={require("../../../../../../assets/icons/delete.png")} />
            </button>
          </div>
        );
      },
    },
  ];
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    // hideSelectAll: true,
  };
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };
  {
    if (participant != undefined) {
      rows = participant.map((item, i) => item);
    }
  }

  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={rows}
        columns={columns}
        exportCSV={{ onlyExportSelection: true, exportAll: false }}
        search
      >
        {(props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter">
              <div className="row">
                <div className="col-xl-4 col-lg-1">
                  <Hn title="participants" level="h3" className="pt-4" plus={false} />
                </div>
                <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                  <div className="flexgy">
                    <div className="col-md-4  mr-1  px-0">
                      <div className="input-group">
                        <input
                          className="form-control height43"
                          type="search"
                          placeholder="Recherche"
                          onChange={(e) =>
                            props.searchProps.onSearch(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="px-0 myxs">
                      <div className="flex">
                        <div className="square">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={handleShowfiltre}
                          >
                            <img
                              src={require("../../../../../../assets/icons/filter.png")}
                            />
                          </button>
                        </div>
                        <div className="square">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={() => props.csvProps.onExport()}
                          >
                            <img
                              src={require("../../../../../../assets/icons/download.png")}
                            />
                          </button>
                        </div>
                        <PopupFiltre
                          showfiltre={showfiltre}
                          closefiltre={handleClosefiltre}
                        />
                        <div
                          className="btn btn-link"
                          style={{ textDecoration: "none" }}
                        >
                          {type === "session" ?
                            (
                              <a
                                href={"/addParticipant/" + params.id}
                                className="resetBtn "
                              >
                                <img
                                  src={require("../../../../../../assets/icons/add.png")}
                                />
                                <span> Ajouter des participants</span>
                              </a>
                            ) :
                            (
                              <a
                                href={"/webinair/participant/" + params.id}
                                className="resetBtn "
                              >
                                <img
                                  src={require("../../../../../../assets/icons/add.png")}
                                />
                                <span> Ajouter des participants</span>
                              </a>
                            )
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BootstrapTable
              {...props.baseProps}
              classes="table-dark mt-5"
              bordered={false}
              hover
              selectRow={selectRow}
              pagination={paginationFactory(options)}
            />

          </div>
        )}
      </ToolkitProvider>

      <Modal show={show} onHide={handleClose} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            Etes-vous sûr(e) de vouloir supprimer{" "}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={handleClose} style={{ color: "#006FFF" }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-primary"
            onClick={(e) => deleteParticipant(id)}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
