import {
  faCircleCheck,
  faCircleXmark,
  faDisplay,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PDFDownloadLink } from "@react-pdf/renderer";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { trackPromise } from 'react-promise-tracker';
import BunnerProfil from "../../components/bunnerProfil/BunnerProfil";
import BunnerProfilMobile from "../../components/bunnerProfil/Bunnerprofilmobile";
import Hn from "../../components/common/Hn";
import AttestationPDFDOC from "../../components/doc/AttestationPDFDOC";
import ConventionPDFDOC from "../../components/doc/ConventionPDFDOC";
import Popup from "../../components/popup/Popup";
import "./Profil.css";
import dateFormat from "dateformat";
export default function Profil() {
  let valid = true;
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [user, setUser] = useState([]);
  const [color, setColor] = useState("1");
  const [score, setScore] = useState([]);
  const [attestations, setAttestations] = useState([]);
  const [filtredAttestations, setFiltredAttestations] = useState([]);
  const [address, setAddress] = useState([]);
  const [collaborateur, setCollaborateur] = useState([]);
  const [showfiltre, setShowfiltre] = useState(false);
  const [eachEntry, setEachEntry] = useState("");
  const uri = process.env.REACT_APP_BASE_URL;
  //fermer le popup du filtre
  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
    // searchwithfiltre(obj);
  };

  //ouvrir le popup du filtre
  const handleShowfiltre = () => {
    setShowfiltre(true);
  };

  //récupérer l'agence du user connecté 
  function getCollaborateurs(user) {
    trackPromise(
      axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/agence/" + user,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          setCollaborateur(response.data);
        })
        .catch(function (error) {
          // setSuccess(true);
        })
    );
  }
//filtre
function searchwithfiltre(val) {
 
  setEachEntry(val)
 console.log('val',val)
  if (val) {
    // let profilFilter =  attestations.filter(el =>  
    //  ( el?.date_debut  === dateFormat (val.dateDeb, "dd-mm-yyyy") && (val.dateDeb.length>0)
    // || el?.date_fin  === dateFormat (val.dateFin, "dd-mm-yyyy") && (val.dateFin.length>0))
    // || (el?.validation>=50 && val.validation=== "oui" && val.validation.length>0
    // || el?.validation<50 && val.validation=== "non" && val.validation.length>0)
    // || (el?.passed===true && val.attestation=== "oui" && val.attestation.length>0
    // || el?.passed===false && val.attestation=== "non" && val.attestation.length>0)
    //  )
    console.log(attestations)
     let profilFilter = attestations.filter(el => {
      let ch = 0; let lf; let i = 0;
      if (val.dateDeb) {
        i = i + 1
        lf = el?.date_debut >= dateFormat(val.dateDeb, "dd-mm-yyyy")
        if (lf) ch = ch + 1;
      }
      if (val.dateFin) {
        i = i + 1
        lf = el?.date_fin <= dateFormat(val.dateFin, "dd-mm-yyyy")
        if (lf) ch = ch + 1;
      }
      if (val.validation) {
        i = i + 1
        if(val.validation=== "oui"){
          lf = el?.validation >= 50
        }else{
          lf = el?.validation < 50
        }
        if (lf) ch = ch + 1;
      }
      if (val.attestation) {
        i = i + 1
        if(val.attestation=== "oui"){
          lf = el?.passed == true
          console.log('oui', lf)
        }else{
          lf = el?.passed == false
          console.log('non', lf)

        }
        if (lf) ch = ch + 1;
      }
      
      let j = 0;
      if(val?.eachEntry?.taux_1){ // > 20
        lf = el?.score >= 20
        if (lf) j = j + 1;
      }
      if(val?.eachEntry?.taux_2){ // > 21 < 50
        lf = el?.score >= 21 && el?.score <= 50
        if (lf) j = j + 1;
      }
      if(val?.eachEntry?.taux_3){ // > 51 < 75
        lf = el?.score >= 51 && el?.score <= 75
        if (lf) j = j + 1;
      }
      if(val?.eachEntry?.taux_4){ // > 75
        lf = el?.score >= 75
        if (lf) j = j + 1;
      }
      if(j > 0){
        i = i + 1
        ch = ch + 1;
      }
      console.log('ch',ch, i)
      if (ch === i) { return true }
      return false
    })
 console.log('profilFilter',profilFilter)
  setFiltredAttestations(profilFilter)
    
  }
}
//récupérer les informations  du participant par son id
  function getuserinfo(user) {
    if (user === localStorage.getItem("ind")) {
      setColor('1');
    } else {
      setColor('0');
    }
    trackPromise(
      axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/participant/" + user,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          setUser(response.data);
          if (response.data.token) {
            localStorage.setItem("user", JSON.stringify(response.data.token));
          }
          if (response.data[0]) {
            setAddress(response.data[0]);
            getAttestations(response.data[0]);
            getstatitique(response.data[0].mailcontact);
          }
          if (user === localStorage.getItem("ind")) {
            getCollaborateurs(response.data[0].AgenceID);
          }
        })
        .catch(function (error) {
          // setSuccess(true);
        }));
  }

  // récupérer les données e-learning de l'utilisateur en question. Le score par formation, l'avancement...
  function getstatitique(email) {
    axios
      .get(uri + "/watershed/get_all_stats_for_user/" + email)
      .then((result) => {
        setScore(result?.data);
      })
      .catch((err) => {
        console.log("err statitique", err);
      });
  }
  // récupérer les attestations de l'utilisateur en question
  function getAttestations(userData) {

    if (userData.mailcontact) {
      axios
        .get(uri + "/watershed/get_user_data/" + userData.mailcontact)
        .then((result) => {
       
          setAttestations(result?.data);
          setFiltredAttestations(result?.data);
       
        })
        .catch((err) => {
          console.log("err  attestations", err);
        });
    }
  }
  useEffect(() => {
    getuserinfo(localStorage.getItem("ind"));
  }, []);

  const data = {
    nom: localStorage.getItem("nom"),
    prenom: localStorage.getItem("prenom"),
    address: address.agenceAddress+''+address.codePostal+''+address.ville,
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="profil">
          <Hn title="Mon Profil" level="h1" dispaly className="py-4" />
          {localStorage.getItem("role") === "Apprenants"
            ? ""
            : <div className="mobileSelect select col-md-12">
              <select
                className="dropdown"
                onChange={(e) => getuserinfo(e.target.value)}
              >
                <option value="1">Mes informations</option>
                {collaborateur?.map((value, i) => (
                  <option value={value.id} key={i}>{value.apprenant}</option>
                ))}
              </select>
            </div>}
          <BunnerProfil
            className="desktop"
            user={user}
            collaborateur={collaborateur}
            getuserinfo={getuserinfo}
            color={color}
            score={score}
           
          />
          <BunnerProfilMobile className="mobile" />
          <br />
          
         
            <div className="search-filtre d-flex flex-row-reverse pt-4">
              <div className="">
            
                <div className="flexgy">
                  <div className="px10">
                    <Button variant="primary " onClick={handleShowfiltre}>
                      Filtrer
                    </Button>
                  </div>
                  <input type="search" className="form-control heigth43 px-10" />
                </div>
              </div>
              <>
                <Popup showfiltre={showfiltre} closefiltre={handleClosefiltre} searchwithfiltre={searchwithfiltre} filterEntry={eachEntry}/>
              </>
            </div>
            <div className="pt-5">
              <Hn title="attestations" level="h2" />
              <table className="DesktopTable table table-dark table-hover">
                <thead>
                  <tr className="tr">
                    <th>Formation</th>
                    <th>Modalité</th>
                    <th>Date de début</th>
                    <th>Date de fin</th>
                    <th>Taux de réussite</th>
                    <th>Validation</th>
                    <th>Convention</th>
                    <th>Attestation</th>
                  </tr>
                </thead>
                <tbody>
                  {filtredAttestations?.map((item, i) => (
                    <tr className="td" key={i} >
                      <td>{item.intitule}</td>
                      <td>
                        <FontAwesomeIcon icon={faDisplay} />
                      </td>
                      <td>{item.date_debut}</td>
                      <td>{item.date_fin}</td>
                      <td>{item.score}%</td>
                      <td>
                        {item.passed ? (
                          <span className="text-success">
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              className="fa-lg"
                            />
                          </span>
                        ) : (
                          <span className="text-danger">
                            <FontAwesomeIcon icon={faCircleXmark} />
                          </span>
                        )}
                      </td>
                      <td>
                        <PDFDownloadLink document={<ConventionPDFDOC data={[data, item]} />} fileName="convention.pdf">
                          {({ loading }) =>
                            loading ? 'Loading document...' : <FontAwesomeIcon icon={faDownload} className="fa-lg" />
                          }
                        </PDFDownloadLink>
                      </td>
                      <td>
                        {item.passed ? (
                          <PDFDownloadLink document={<AttestationPDFDOC data={[data, item]} />} fileName="attestation.pdf">
                            {({ loading }) =>
                              loading ? 'Loading...' : <FontAwesomeIcon icon={faDownload} className="fa-lg" />
                            }
                          </PDFDownloadLink>
                        ) :
                          <FontAwesomeIcon icon={faDownload} className="fa-lg" />}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
        
        </div>
      </div>
    </div>
  );
}