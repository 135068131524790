import { useState, useEffect } from "react";
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import Hn from "../common/Hn";
import "./popup.css";
export default function Popup({showfiltre, closefiltre, searchwithfiltre , filterEntry}) {
  const initialInputState = {
    modalite: filterEntry?.modalite ??"",
    convention: filterEntry?.convention ??"",
    attestation: filterEntry?.attestation ??"",
    validation:filterEntry?.validation ?? "",
    dateDeb:filterEntry?.dateDeb ?? "",
    dateFin:filterEntry?.dateFin ?? "",
    
};
  const [eachEntry, setEachEntry] = useState(initialInputState);
  function getvalueinput(e) {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
    
}
const resetForm = () => { 
  document.getElementById("filterForm").reset();
  setEachEntry({ modalite:"", convention:"", attestation:"", validation:"", dateDeb:"" , dateFin:"", })
console.log("attestat", eachEntry.validation )
}
const {
  modalite,
  convention,
  attestation,
  validation,
  dateDeb,
  dateFin
} = eachEntry;
useEffect(() => {
    setEachEntry(initialInputState);
  },  [showfiltre, closefiltre]);
   
  return (
    <>
     

      <Modal show={showfiltre} onHide={closefiltre} size="lg" className="profil">
        <Modal.Header closeButton>
          <Modal.Title className="px-3">
            <Hn title="Filtrer" level="h4" plus={false} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form id="filterForm">
          <div className="row px-3">
            {/* <div className="col-md">
              <Hn
                title="Modalité"
                level="h5"
                plus={false}
                className="label-popup"
              />
              <select  className="form-select"
                          aria-label="Default select example"
                          id="modalite"
                          value={eachEntry.modalite}
                          onChange={getvalueinput}
                          name="modalite">
                <option value="">Sélectionner</option>
                <option value="elearning">Elearning</option>
                <option value="presentielle">Presentielle</option>
              </select>
            
            </div> */}
            <div className="col-md">
              <Hn
                title="Date de début"
                level="h5"
                plus={false}
                className="label-popup"
              />
              <input type="date" value={eachEntry.dateDeb} className="form-control drop"  onChange={getvalueinput} name="dateDeb"/>
            </div>
            <div className="col-md">
              <Hn
                title="Date de fin"
                level="h5"
                plus={false}
                className="label-popup"
              />
              <input type="date" className="form-control" onChange={getvalueinput} name="dateFin"/>
            </div>
          </div>
          {/* /row */}
          <div className="row px-3">
          <div className="col-md-12 pt-2">
          <Hn
                title="Taux de réussite"
                level="h5"
                plus={false}
                className="label-popup-taux"
              />
            </div>
            <div className="col-md pt-2">
              <Hn
                title=""
                level="h5"
                plus={false}
                className="label-popup-taux"
              />
              <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="20"
                id="flexCheckDefault"
                onChange={getvalueinput}
                name="taux_1"
              />
              <label className="form-check-label" >
               plus 20%
              </label>
              </div>
            </div>
            <div className="col-md pt-2">
              <Hn
                title=""
                level="h5"
                plus={false}
                className="label-popup"
              />
               <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="21"
                id="flexCheckDefaultg"
                onChange={getvalueinput}
                name="taux_2"
              />
              <label className="form-check-label" >
              Entre 21 et 50%
              </label>
              </div>
            </div>
            <div className="col-md pt-2">
              <Hn
                title=""
                level="h5"
                plus={false}
                className="label-popup"
              />
               <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="51"
                id="flexCheckDefaultxx"
                onChange={getvalueinput}
                name="taux_3"
              />
              <label className="form-check-label" >
               entre 51 et 75%
              </label>
              </div>
            </div>
            <div className="col-md pt-2">
              <Hn
                title=""
                level="h5"
                plus={false}
                className="label-popup"
              />
               <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="76"
                id="flexCheckDefaultx"
                onChange={getvalueinput}
                name="taux_4"
              />
              <label className="form-check-label" >
               plus 75%
              </label>
              </div>
            </div>
          </div>
          {/* /row */}
          <div className="row pt-4 px-3">
            <div className="col-md">
              <Hn
                title="Validation"
                level="h5"
                plus={false}
                className="label-popup"
              />
             
                <select  className="form-select"
                          aria-label="Default select example"
                          id="validation"
                          value={eachEntry.validation}
                          onChange={getvalueinput}
                          name="validation">
                <option value="" >Sélectionner</option>
                <option value="oui">Oui</option>
                <option value="non">Non</option>
              </select>
            </div>
            {/* <div className="col-md">
            <Hn
                title="Convention"
                level="h5"
                plus={false}
                className="label-popup"
              />
         
                <select  className="form-select"
                          aria-label="Default select example"
                          id="convention"
                          value={eachEntry.convention}
                          onChange={getvalueinput}
                          name="convention">
                <option value="" >Sélectionner</option>
                <option value="oui">Oui</option>
                <option value="non">Non</option>
              </select>
            </div> */}
            <div className="col-md">
            <Hn
                title="Attestation"
                level="h5"
                plus={false}
                className="label-popup"
              />
             
                <select  className="form-select"
                          aria-label="Default select example"
                          id="attestation"
                          value={eachEntry.attestation}
                          onChange={getvalueinput}
                          name="attestation">
                <option value="" >Sélectionner</option>
                <option value="oui">Oui</option>
                <option value="non">Non</option>
              </select>
            </div>
          </div>
          {/* /row */}
        </form>
        </Modal.Body>
        <Modal.Footer>
            <div> <button className="btn btn-link resetBtn" onClick={closefiltre} style={{ color: "#006FFF" }}>
          Annuler
        </button></div>
      <div className="flex">  <button className="secondaryModal"
          onClick={() => {
            resetForm()
          }
          }
          disabled={eachEntry.modalite === "" && eachEntry.dateDeb === "" && eachEntry.dateFin === "" && eachEntry.attestation === "" && eachEntry.convention === "" && eachEntry.validation === "" ? "disabled" : ""}
        >
          Réinitialiser
        </button>
        <Button
          variant="primary"
          onClick={() => {
            closefiltre()
            searchwithfiltre({modalite, attestation, convention, validation , dateDeb, dateFin, eachEntry});
          }}
        >
          Valider
        </Button></div>
              
              
            </Modal.Footer>
      </Modal>
    </>
  );
}
