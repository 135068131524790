import { useState, useEffect } from "react";
import { getTrackBackground, Range } from "react-range";

export default function Rangenumber({ setMinvalue, setMaxvalue, min, max }) {
  const STEP = 1;
  let MIN = min || 0;
  let MAX = max || 10000;
  if (MAX === MIN) {
    MAX += 1;
  }
  const [values, setValues] = useState([MIN, MAX]);

  useEffect(() => {
    setMinvalue(min);
    setMaxvalue(max);
    // setMinvalue(1);
    // setMaxvalue(1000);
  }, []);
  return (
    <>
      <Range
        values={values}
        step={STEP}
        min={MIN ?? values[0]}
        max={MAX ?? values[1]}
        onChange={val => {
          setValues(val);
          setMinvalue(val[0]);
          setMaxvalue(val[1]);
        }}
        renderTrack={({ props, children }) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%"
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values,
                  colors: ["#ccc", "#548BF4", "#ccc"],
                  min: MIN,
                  max: MAX
                }),
                alignSelf: "center"
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "42px",
              width: "42px",
              borderRadius: "4px",
              backgroundColor: "#FFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // boxShadow: "0px 2px 3px #AAA"
            }}
            className="round"
          >
            <div
              style={{
                height: "5px",
                width: "2px",
                backgroundColor: isDragged ? "#548BF4" : "#CCC"
              }}
              className="insideround"
            />

          </div>
        )}
      />
      <div className="text-center">
        <span className="text-white">Min : {values[0]}</span>||
        <span className="text-white">Max : {values[1]}</span>
      </div>
    </>
  );
};

