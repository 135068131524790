import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Hn from "../../../../components/common/Hn";
import Rangenumber from '../../../../components/common/Rangenumber';
import "./../../../../components/popup/popup.css";
export default function PopupFiltre({showfiltre, closefiltre , searchwithfiltre ,filterEntry}) {
    const initialInputState = {
        name: filterEntry?.name ??"",
        pays: filterEntry?.pays ??"",
        contact: filterEntry?.contact ??"",
        tel: filterEntry?.tel ??"",
        email:filterEntry?.email ??"",
      
    };
    const [eachEntry, setEachEntry] = useState(initialInputState);
 
    const {
        name,
        pays,
        contact,
        tel,
        email
      } = eachEntry;

    function getvalueinput(e) {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
    }
    const resetForm = () => { 
        document.getElementById("filterForm").reset();
        setEachEntry({ name:"", pays:"", contact:"", tel:"",email:"" })
      }
    useEffect(() => {
        setEachEntry(initialInputState);
      }, []);
       
    
    return (
        <Modal show={showfiltre}
            onHide={closefiltre}
            size="lg"
            className="filter">
            <Modal.Header closeButton>
                <Modal.Title className="px-3 nopad">
                    <Hn title="Filtrer" level="h4"
                        plus={false}/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form id="filterForm">  
                <div className="row px-3 nopad">
                    <div className="col-md-4">
                        <Hn title="Nom" level="h5"
                            plus={false}
                            className="label-popup"/>
                        <input type="text" className="form-control drop"
                        onChange={getvalueinput} name="name"  value={eachEntry.name}/>
                    </div>
                    <div className="col-md-4">
                    <Hn title="Pays" level="h5"
                            plus={false}
                            className="label-popup"/>
                        <input type="text" className="form-control drop"
                        onChange={getvalueinput}  name="pays"  value={eachEntry.pays}/>
                    </div>
                    <div className="col-md-4">
                    <Hn title="Contact" level="h5"
                            plus={false}
                            className="label-popup"/>
                        <input type="text" className="form-control drop"
                        onChange={getvalueinput}  name="contact"  value={eachEntry.contact}/>
                    </div>
                    <div className="col-md-4 mt-3">
                    <Hn title="Téléphone" level="h5"
                            plus={false}
                            className="label-popup"/>
                        <input type="tel" className="form-control drop"
                        onChange={getvalueinput}  name="tel"  value={eachEntry.tel}/>
                    </div>
                    <div className="col-md-4 mt-3">
                    <Hn title="email" level="h5"
                            plus={false}
                            className="label-popup"/>
                        <input type="email" className="form-control drop"
                        onChange={getvalueinput}  name="email"  value={eachEntry.email}/>
                    </div>
                </div>
                </form>
                {/* /row */}
                <br></br>
                
                </Modal.Body>
            <Modal.Footer> 
            <div>
                <button className="btn btn-link resetBtn" onClick={closefiltre} style={{ color: "#006FFF" }}>
          Annuler
        </button></div>
        <div className="flex">  <button className="secondaryModal"
                    onClick={() => {
                      resetForm()
                    }
                    }
                    disabled={eachEntry.name===""&&eachEntry.pays===""&&eachEntry.contact===""&&eachEntry.tel===""&&eachEntry.email===""?"disabled":""}
                    >
                    Réinitialiser
                </button>
        <Button
          variant="primary"
          onClick={() => {
           
            closefiltre();
            searchwithfiltre({name, pays, contact, tel,email});
          }}
        >
          Valider
        </Button></div>
          
               
            </Modal.Footer>
        </Modal>
    );
}