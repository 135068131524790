import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import CadreItems from "../common/CadreItems";
import "./mostformation.css";

export default function Mostformation({
  data,
  carousel,
  details,
  url,
  switchs,
  isflip,
  elearning,
  isElearning,
}) {

  const IMG_URL2 = process.env.REACT_APP_PIC_FACETRAINING_PATH;
  const IMG_URL = process.env.REACT_APP_PIC_ELEARNING_IMG;

  const options = {
    items: 4,
    responsiveClass: true,
    margin: 27,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className=" py-5 mostformation">
          {carousel && (
            <OwlCarousel className="owl-theme" {...options}>
              {data?.map((item, index) => {
                    
                    return (
                    <div className="item" key={index}>
                      {/* <CadreItems index={index} switchs={false} title={item?.title} image={item?.image} /> */}
                      <CadreItems
                        details={details}
                        base_url={ item.type !== "face" ? url : IMG_URL2 }
                        index={index}
                        switchs={switchs}
                        parcours={item?.assoc_cours || item?.parcours_associe}
                        title={item?.title || item?.intitule}
                        image={item?.image}
                        id={item.id}
                        isflip={isflip}
                        elearning={elearning}
                        isElearning={item?.type === 'face'}
                        
                      />
                    </div>
                  );
                })}
            </OwlCarousel>
          )}

          {!carousel && (
            <div className="row">
              {data?.map((item, index) => {
              
              
                  let xurl = '';
                  let exist = false;
                  if(item.type === 'face'){
                     xurl = IMG_URL2;
                     exist = false
                  }else{
                     xurl = IMG_URL;
                     exist = true
                  }

                  return (
                    <div className="item col-lg-3 col-sm-6" key={index}>
                      <CadreItems
                        details={details}
                        base_url={xurl}
                        index={index}
                        switchs={switchs}
                        parcours={item?.assoc_cours}
                        title={item?.title || item?.intitule}
                        image={item?.image}
                        id={item.id}
                        isflip={isflip}
                        elearning={elearning}
                        isElearning={exist}
                      />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
