import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Hn from "../common/Hn";
import axios from "axios";
import "./popup.css";
export default function PopupFiltre({showfiltre, closefiltre, searchwithfiltre,filterEntry}) {

  const initialInputState = {
        titre: filterEntry?.titre ??"",
        date: filterEntry?.date ??"",
        heure: filterEntry?.heure ??"",
        former:filterEntry?.former ?? ""
    };
    const uri = process.env.REACT_APP_BASE_URL;
    const [eachEntry, setEachEntry] = useState(initialInputState);
    const [formers,setFormers]=useState([]);
    const {
        titre,
        date,
        heure,
        former
      } = eachEntry;
      const resetForm = () => { 
        document.getElementById("filterForm").reset();
        setEachEntry({ titre:"", date:"", heure:"", former:"" })
      }
      function getFormers() {
        axios
          .get(uri + "/former")
          .then((result) => {
          
            
            setFormers(result?.data);
           // setInitraining(result?.data);
          })
          .catch((err) => {
            console.log("err formers", err);
          });
      }

      
    function getvalueinput(e) {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
    }
   
    useEffect(() => {
      getFormers()
        setEachEntry(initialInputState);
      },  [showfiltre, closefiltre]);
       
    
    return (
        <Modal show={showfiltre}
            onHide={closefiltre}
            size="lg"
            className="filter">
            <Modal.Header closeButton>
                <Modal.Title className="px-3 nopad">
                    <Hn title="Filtrer" level="h4"
                        plus={false}/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="filterForm">
                <div className="row px-3 nopad">
                    <div className="col-md-6">
                        <Hn title="Titre" level="h5"
                            plus={false}
                            className="label-popup"/>
                    
                        <input type="text" className="form-control drop"
                        onChange={getvalueinput} name="titre" value={eachEntry.titre}/>
                    </div>
                  
                    <div className="col-md-6">
                    <Hn title="Date" level="h5"
                            plus={false}
                            className="label-popup"/>
                        <input type="date" className="form-control drop"
                        onChange={getvalueinput}  name="date" value={eachEntry.date}/>
                    </div>
                    <div className="col-md-6 pt-3">
                    <Hn title="Heure" level="h5"
                            plus={false}
                            className="label-popup"/>
                        <input type="time" className="form-control drop"
                        onChange={getvalueinput}  name="heure" value={eachEntry.heure}/>
                    </div>
                   
                    <div className="col-md-6 mt-3">
                        <Hn title="Formateur" level="h5"
                            plus={false}
                            className="label-popup"/>
                            <select
                          className="form-select"
                          aria-label="Default select example"
                          id="former"
                          value={eachEntry.former}
                          onChange={getvalueinput}
                          name="former"
                        >
                          <option defaultValue>
                            Sélectionner
                          </option>
                          {formers?.map((former, index) => {
                            return (
                              <option value={former?.id}  key={index} >{former?.firstName +" "+former?.lastName}</option>
                            );
                          })}
                        </select>
                      
                    </div>
                </div>
                </form>
                {/* /row */}
                <br></br>
                
              
                {/* /row */} </Modal.Body>
            <Modal.Footer>
            <div> <button className="btn btn-link resetBtn" onClick={closefiltre} style={{ color: "#006FFF" }}>
          Annuler
        </button></div>
      <div className="flex">  <button className="secondaryModal"
          onClick={() => {
            resetForm()
          }
          }
          disabled={eachEntry.titre === "" && eachEntry.date === "" && eachEntry.heure === "" && eachEntry.former === "" ? "disabled" : ""}
        >
          Réinitialiser
        </button>
        <Button
          variant="primary"
          onClick={() => {
            closefiltre()
            searchwithfiltre({titre, date, heure, former});
          }}
        >
          Valider
        </Button></div>
              
              
            </Modal.Footer>
        </Modal>
    );
}