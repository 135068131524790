import axios from "axios";
import React, { useEffect, useState } from "react";
import Bunner from "../../components/bunner/Bunner";
import Hn from "../../components/common/Hn";
import Mostformation from "../../components/owl counter/Mostformation";
import Listformation from "../../components/owl/Listformation";
import Listwiththematic from "../../components/owl/Listwiththematic";
import ListingWebinaire from "../webinair/ListingWebinaire";
import { trackPromise } from "react-promise-tracker";
import { useHistory, useParams } from "react-router-dom";
export default function FormationElearning({ title }) {
  const [post, setPost] = React.useState(null);
  const [thematics, setThematics] = useState([]);
  const [traininge, setTraininge] = useState([]);
  const [trainingslugu, setTrainingslugu] = useState([]);
  const [encours, setEncours] = useState([]);
  const uri = process.env.REACT_APP_BASE_URL;
  let [slider, setSlider] = useState([]);
  const IMG_URL = process.env.REACT_APP_PIC_ELEARNING_IMG;
  const user = localStorage.getItem("email");
//récupérer les formations elearning par email user
  function getTraining() {
    axios
      .get(uri + "/elearning?email=" + user)
      .then((result) => {
        setTraininge(result?.data.filter((el) => el?.actif === true));

        getThematic(result?.data.filter((el) => el?.actif === true));
        let temporary_slider = [];
        result?.data.map((item, index) => {
          if (item.mis_en_avant === true) {
            let new_item = { ...item, from: "elearning" };
            temporary_slider.push(new_item);
          }
        });
        temporary_slider = [...slider, ...temporary_slider];

        slider = temporary_slider;
        setSlider(temporary_slider);
      })
      .catch((err) => {
        console.log("err training", err);
      });
  }
//récupérer tous les webinars
  function getWebinars() {
    trackPromise(
      /*local*/ axios
        .get(uri + "/webinars/")
        .then((result) => {
          let temporary_slider = [];
          result?.data.filter((item, index) => {
            if (item.forward_catalog === true) {
              let new_item = { ...item, from: "webinar" };
              temporary_slider.push(new_item);
            }
          });
          temporary_slider = [...slider, ...temporary_slider];

          slider = temporary_slider;
          setSlider(temporary_slider);
        })
        .catch((err) => {
          console.log("err webinars", err);
        })
    );
  }
//récupérer les formations elearning en cours
  function getEncours() {
    axios
      .get(
        uri +
          "/elearning/get_formations_en_cours/" +
          localStorage.getItem("email")
      )
      .then((result) => {
      
       
        setEncours(result?.data.filter((el) => el?.actif === true));
       
        let themes = {
          slug: "encours",
        };
        setTrainingslugu(themes);
      })
      .catch((err) => {
        console.log("err training", err);
      });
  }
//récupérer les thémes des formations elearning
  function getThematic(info) {
    setThematics(info.map((el)=>{return{name:el.theme.name,slug:el.theme.slug}}))
  }

  useEffect(() => {
    getTraining();
    getWebinars();
    getEncours();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row pb-3">
        <Bunner
          title="Webinair"
          data={slider}
          details="detailformations"
          url={"detailformations"}
          elearning={localStorage.getItem("elearning")}
        />
      </div>
      <div className="pb-4 pt-4">
        <ListingWebinaire />
      </div>
      {encours.length != 0 ?
      <>
       <Hn
       title="mes formations e-learning en cours"
       level="h3"
       className="pb-4 pt-5"
     />
     <Listformation
        title="Reprendre les formations en cours"
        url={IMG_URL}
        data={encours}
        details={"detailformations"}
        theme={trainingslugu}
        elearning={localStorage.getItem("elearning")}
        isflip={true}
        isElearning={true}
        category="elearning"
      />
      </>
      : ""
      }
       {traininge.length != 0 ?
<>
      <Hn title="Top 10 des formations" level="h3" className="pt-4" />
      <Mostformation
        details={"detailformations"}
        data={traininge.slice(0, 10)}
        url={IMG_URL}
        carousel
        isflip={false}
        elearning={localStorage.getItem("elearning")}
        isElearning={true}
        // nbChapitre={traininge?.chapitres.length}
      />
</>
 : ""
}
      <Hn
        title="Mon catalogue de formations e-learning"
        level="h3"
        className="pb-3"
      />
      {thematics &&
        
            thematics?.sort((a, b) => a.name > b.name ? 1 : -1).filter((v,i,a)=>a.findIndex(v2=>(v2.slug===v.slug))===i).map((item, i) => {
            return (
              <>
               
                <Listwiththematic
                  title={item?.name}
                  formation={traininge}
                  details={"detailformations"}
                  url={IMG_URL}
                  key={i}
                  theme={item.slug}
                  isflip={false}
                  elearning={localStorage.getItem("elearning")}
                  isElearning={true}
                  category="elearning"
                  // nbChapitre={traininge?.chapitres.length}
                />
              </>
            );
          })}
    </div>
  );
}
