import axios from "axios";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import PopupFiltre from "../../components/popup/PopupFiltreParticipant";

// import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { createBrowserHistory } from "history";
import { Alert, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

export default function Datatable({
  participant,
  idWeb,
}) {
  let history = createBrowserHistory({ forceRefresh: true });
  const [loading, setLoading] = useState(false);
  const [ident, setIdent] = useState();
  const [id, setId] = useState();
  const [initialparticipant, setInitialParticipant] = useState([]);
  const [showfiltre, setShowfiltre] = useState(false);
  const [displayetat, setDisplayetat] = useState("false");
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const { ExportCSVButton } = CSVExport;
  const uri = process.env.REACT_APP_BASE_URL;
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };
  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
    searchwithfiltre(obj);
  };
  const handleShowfiltre = () => {
    setShowfiltre(true);
  };

  function searchwithfiltre(val) {
//     setInitialParticipant(participant);
//     if (val) {
//       setParticipant([]);
//       let participantsFilter =  participant.filter(el =>    
//       ( el.apprenant.toLowerCase().includes(val.collaborator.toLowerCase() )&& (val.collaborator.length>0))
//       || el?.mailcontact.toLowerCase() === val.email.toLowerCase() 
//       || (el?.agencymain.toLowerCase().includes(val.agent.toLowerCase() ) && (val.agent.length>0))
//       || el?.agenceEmail.toLowerCase() === val.emailAgent.toLowerCase()
//       )


// setParticipant(participantsFilter)
      
//     }
  }
  function deleteParticpant(id) {
  
    axios.delete(`${uri}/participant/` + id).then(() => {
     // getParticipant();
    });
   // setShow(false);
  }

  const columns = [
    {
      dataField: "apprenant",
      text: "Collaborateur",
      csvText: "collaborator",
      sort: true,
      // formatter: (cell, row, rowIndex, formatExtraData) => {
      //   return (
      //     <>{row.nom} {row.prenom}</>
      //   );
      // },
    },
    {
      dataField: "mailcontact",
      text: "Email",
      csvText: "email",
      sort: true,
    },
    {
      dataField: "agencymain",
      text: "Agent",
      csvText: "agent",
      sort: true,
    },
    {
      dataField: "agenceEmail",
      text: "Email Agent",
      csvText: "emailAgent",
      sort: true,
    },
    {
      dataField: "offre",
      text: "Offre",
      csvText: "offre",
      sort: true,
    },
    {
      dataField: "agenceImmobilier",
      text: "Manager",
      csvText: "manager",
      sort: true,
    },
    {
      dataField: "actions",
      text: "",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (<>
          {localStorage.getItem("role") === "Super-admin" ? (
          <div className="btn-group" role="group" aria-label="Basic example">
            
              
          
          </div>
          ) : (
            ""
          )}</>
        );
      },
    },
  ];
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: (row, isSelect, rowIndex, e) => {
  

      /////////////////////////
      let datasend = {
        "participant":row.id
      }
    
      if (isSelect) {
        axios
          .put(`${uri}/webinars/edit/particiapnt/` + idWeb, datasend, headers)
          .then((response) => {
          
          
          })
          .catch((error) => {
            console.log("error",error)
            // displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
          });
      } else {
        console.log('delete', datasend);
  console.log('delete is selected',isSelect)
     
        axios
          .put(`${uri}/webinars/delete/particiapnt/`+ idWeb , datasend, headers)
          .then((response) => {
         
           
          })
          .catch((error) => {
            // displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
          });
      }
      ////////////////////
    }
  };
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    const onFollowChanged = () => {
      this.setState({ isFollow: !this.state.isFollow });
    
    };

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Button
          onClick={() => {
            this.onFollowChanged(row);
          }}
        >
          Follow
        </Button>
      );
    };

    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };
 

  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={participant? participant:[]}
        columns={columns}
        exportCSV={{ onlyExportSelection: true, exportAll: false }}
        search
      >
        {(props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter my-5">
            <div className="row">
            <div className="col-xl-4 col-lg-1"></div>
            <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                <div className="flexgy">
                <div className="col-md-4  mr-1  px-0">
                    <div className="input-group">
                      <input
                        className="form-control height43"
                        type="search"
                        placeholder="Recherche"
                        onChange={(e=>props.searchProps.onSearch(e.target.value))}
                      />
                      
                    </div>
                  </div>
                  <div className="px-0 myxs">
                    <div className="flex">
                      <div className="square">
                        <button type="button" 
                        className="btn btn-link"
                        onClick={handleShowfiltre}
                        >
                          <img
                            src={require("../../assets/icons/filter.png")}
                          />
                        </button>
                      </div>
                      <PopupFiltre
                        showfiltre={showfiltre}
                        closefiltre={handleClosefiltre}
                      />
                      <div className="square">
                        <button type="button" className="btn btn-link" onClick={()=>props.csvProps.onExport()}>
                          <img
                            src={require("../../assets/icons/download.png")}
                          />
                        </button>
                      </div>
                      
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
          {/* lists-filter */}
          {displayetat === "true" ? (
            <>
              <br></br>
              <Alert color="primary">Aucun resultat trouvé</Alert>
            </>
          ) : (
            ""
          )}
            <BootstrapTable
              {...props.baseProps}
              classes="table-dark "
              bordered={false}
              hover
              selectRow={selectRow}
              pagination={paginationFactory(options)}
            />
          </div>
        )}
      </ToolkitProvider>

    
    </>
  );
}
