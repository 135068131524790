import { useEffect, useState } from "react";

import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Link, Font } from '@react-pdf/renderer';
import moment from "moment";
import logodoc from "../../assets/logodoc.png";
import fontRegular from '../../assets/font/Montserrat/Montserrat-Regular.ttf';
import fontItalic from '../../assets/font/Montserrat/Montserrat-Italic.ttf';
import fontBold from '../../assets/font/Montserrat/Montserrat-Bold.ttf';
import { Table, TableBody, TableHeader, TableCell, DataTableCell } from '@david.kucsai/react-pdf-table';

Font.register({
  family: 'Montserrat',
  fonts: [
    { src: fontRegular },
    { src: fontItalic, fontStyle: 'italic' },
    { src: fontBold, fontStyle: 'bold' }
  ]
});

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 30,
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: 800,
    color: '#000000'
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    paddingBottom: 8,
    fontSize: 18,
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontStyle: 'bold',
    color: 'blue'
  },
  address: {
    fontSize: 10
  },
  author: {
    fontSize: 10,
    marginBottom: 5,
    fontWeight: 'bold',
    fontStyle: 'bold',
  },
  subtitle: {
    paddingTop: 5,
    fontSize: 10,
    fontFamily: 'Montserrat'
  },
  object: {
    paddingTop: 12,
    textAlign: 'justify',
    fontSize: 12,
    fontWeight: 'bold',
    fontStyle: 'bold',
  },
  text: {
    margin: 14,
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontStyle: 'bold',
  },
  image: {
    marginTop: 15,
    width: 150
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  attesText: {
    fontFamily: 'Montserrat',
    fontSize: 10,
    textAlign: 'justify',
  },
  tableText: {
    fontFamily: 'Montserrat',
    fontSize: 10,
    textAlign: 'center',
    fontWeight: 'bold',
    fontStyle: 'bold',
  }
});

function AttestationPDFDOC(props) {

  let data = props.data;
  
  return (
    <Document>
      <Page size="A4" style={styles.page}>

        <Image style={styles.image} src={logodoc}></Image>
        <View>
          <Text style={styles.address}>Paris Porte de Gentilly - 39 avenue Paul Vaillant Couturier</Text>
          <Text style={styles.address}>CS 60511- 94258 Gentilly - Tél. 01 58 46 44 44</Text>
        </View>
        <Text style={styles.header} fixed>
        </Text>
        <Text style={styles.title}>ATTESTATION DE FORMATION</Text>
        <Text style={styles.author}>Le présent document atteste que :{data[0]?.nom} {data[0]?.prenom}</Text>

        <Text style={styles.subtitle}>
          A suivi avec succès la formation :{data[1]?.intitule}
        </Text>
        <Text style={styles.subtitle}>
          Qui a été validée le : 04/08/2021
        </Text>
        <Text style={styles.subtitle}>
          Durée estimée : 2 h 03 min 00 s
        </Text>

        <View style={{ paddingTop: 14 }}>
          <Table
            style={{ borderWidth: 0 }}
            data={data[1]?.chapitres}
          >
            <TableHeader renderTopBorder style={{ borderWidth: 0 }}>
              <TableCell style={{ backgroundColor: '#ebebeb', paddingTop: 5, paddingBottom: 5, borderWidth: 0 }}>
                Détail des Chapitres / UA
              </TableCell>
              <TableCell includeTopBorder style={{ backgroundColor: '#ebebeb', paddingTop: 5, paddingBottom: 5, borderWidth: 0 }}>
                Temps passé
              </TableCell>
              <TableCell style={{ backgroundColor: '#ebebeb', paddingTop: 5, paddingBottom: 5, borderWidth: 0 }}>
                Score
              </TableCell>
            </TableHeader>
            <TableBody style={{ borderWidth: 0 }}>
              <DataTableCell style={{ paddingTop: 5, paddingBottom: 5,borderWidth: 0 }} getContent={(r) => r.titre} />
              <DataTableCell style={{ paddingTop: 5, paddingBottom: 5,borderWidth: 0 }} getContent={(r) => r.temps} />
              <DataTableCell style={{ paddingTop: 5, paddingBottom: 5,borderWidth: 0 }} getContent={(r) => r.score.toLocaleString() + '%'} />
            </TableBody>
          </Table>
          <Text style={styles.tableText}>Temps total passé sur le parcours : 2 h 02 min 16 s</Text>
        </View>

        <Text style={styles.object}>Objectifs :</Text>
        <Text style={styles.text}>
          {data[1]?.objectifs ? data[1]?.objectifs  : 'Aucun objectifs' }
        </Text>

        <Text style={styles.text}>
          Je soussigné Stéphane FRITZ agissant en ma qualité de Président de l'Organisme GUY HOQUET atteste que {data[0]?.nom} 
           {data[0]?.prenom} a validé le parcours : {data[1]?.intitule}.
        </Text>

        <View style={{ paddingHorizontal: 18 }}>
          <Text style={styles.attesText}>
            Cette attestation est délivrée pour faire valoir ce que de droit.
          </Text>
          <Text style={styles.attesText}>
            Cachet de l'organisme et signature :
          </Text>
        </View>
        <View style={{ paddingTop: 14, paddingHorizontal: 18 }}>
          <Text style={styles.attesText}>
            Cette attestation a été réalisée à Paris, le {moment().format('DD/MM/YYYY')}
          </Text>
        </View>

        <Text style={styles.text}>
          S.A.S. au capital de 167 693,92 euros - RCS Créteil B 389 011 537 - APE 7740Z - Carte professionnelle n° CPI 9401
          2016 000 018 126 délivrée par la préfecture de Créteil Caisse de garantie CEGC - 16 rue Hoche Tour Kupka B - TSA
          39999 92912 La Défense cedex - TVA intracommunautaire n° FR 75 389 011 537 - Chaque agence est juridiquement et

          financièrement indépendante. - Guy Hoquet l'Immobilier est enregistré sous le numéro de déclaration d'activité 119 405
          212 94 auprés de la DIRECCTE
        </Text>

        <Text style={styles.text}>
        </Text>

      </Page>
    </Document>

  )
}

export default AttestationPDFDOC;