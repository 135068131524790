import axios from "axios";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Hn from "../../../../components/common/Hn";

import Datatable from "./Datatable";
import "./participant.css";

function ListParticipant() {

  const headers = {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  };
  const uri = process.env.REACT_APP_BASE_URL;
  let history = useHistory();
  const [total, setTotal] = useState();
  const [participant, setParticipant] = useState([]);
  const [show, setShow] = useState(false);
  const [initialparticipant, setInitialParticipant] = useState([]);
  const [showfiltre, setShowfiltre] = useState(false);
  const [displayetat, setDisplayetat] = useState("false");
  let toastId = null;
 

  //récupérer une liste de 50 participants 
  function getParticipant() {
    setParticipant([]);
   

    axios
      .get(uri+"/participant/pagination/?page=1")
      .then((result) => {
       setParticipant(result?.data?.data);
       setTotal(result?.data?.count)
       
       
      })
      .catch((err) => {
        console.log("err participant", err);
      });
  }


  useEffect(() => {
    setParticipant([]);
    getParticipant();
  }, []);

 
  

 

  return (
    <div className="container-fluid"><ToastContainer />
      <div className="row pe-3">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn
              title="Formations présentielles"
              level="h1"
              className="pt-5"
              plus={false}
            />
            <Hn title="participants" level="h2" className="pt-4" />
          </div>

         
          {/* lists-filter */}
          {displayetat === "true" ? (
            <>
              <br></br>
              <Alert color="primary">Aucun resultat trouvé</Alert>
            </>
          ) : (
            ""
          )}
          <div>
        
              <Datatable
                setShow={setShow}
                show={show}
                participant={participant}
                getParticipant={getParticipant}
                setParticipant={setParticipant}
                total={total}
                from="presentielle"
              />
           
          </div>
        </div>
      </div>
    </div>
  );
}
export default ListParticipant;
