import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import CadreItems from "../common/CadreItems";
import "./listformation.css";
export default function Listformation({ title, url, data, disable, disabled, details, theme,elearning, isflip, isElearning,nbChapitre, category = "training"}) {
  const [openDetails, setOpenDetails] = useState(false);
  const [trainingMost, setTrainingMost] = useState([]);
  const IMG_URL = process.env.REACT_APP_PIC_FACETRAINING_PATH;
 
  if(disabled)
    disabled = 'visually-hidden';

  const options = {
    items: 4,
    responsiveClass: true,
    margin: 27,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1.25,
      },
      400: {
        items: 1.3,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className={`e-learning ${disable} p-3`}>
         <div className="row">
          <div className="col offset-1">
            <div className={`titreE pt-4 ${disable}`}>
              <p>{title}</p>
            </div>
          </div>
          <div className="col">
            <div className={`more-info ${disabled}`}>
            
            <a href={`/listmore/${theme?.slug}/` + category}>Voir tout</a>
            </div>

          </div>
          </div> 
          <OwlCarousel className="owl-theme" {...options}>
            {data && data?.map((item, index) => {
              return (
                <div className="item" key={index}>
                  <CadreItems title={item?.title || item?.intitule} parcours={item?.assoc_cours||item?.parcours_associe} id={item?.id} 
                  base_url={url} image={item?.image} index={index} details={details} isflip={isflip} disable elearning={elearning}  isElearning={isElearning} enCours={true}/>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}
