import axios from "axios";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import Hn from "../../../../../components/common/Hn";
import "../../../../admin/forms/face-training/crud/createFaceTraining.css";
import "../../../../admin/forms/location/ListLocationTraining.css";
import Datatable from "../../participant/Datatable";

function Listapprenant() {
  const [total, setTotal] = useState();
  const [displayetat, setDisplayetat] = useState("false");
  const [show, setShow] = useState(false);
  const [apprenant, setApprenant] = useState([]);
  const [errorexist, setErrorexist] = useState("false");
  const [succesdelete, setSuccesdelete] = useState(false);
  const uri = process.env.REACT_APP_BASE_URL;

  //récupérer les premiers 50 des participants
  function getApprenant() {
    setApprenant([]);
    
    axios
      .get(uri+"/participant/pagination/?page=1")
      .then((result) => {  
       console.log(result?.data?.data)
        setApprenant(result?.data?.data);
        setTotal(result?.data?.count)
      })
      .catch((err) => {
        console.log("err apprenant", err);
      });
  }
  useEffect(() => {
    getApprenant();
  }, []);
  //l'api retourne une erreur si le theme relié avec une formation, mais ici onDismiss s'il ya une erreur affiche une Alerte qui contient un message
  const onDismiss = () => {
    setErrorexist("false");
    setSuccesdelete("false");
  };

  return (
    <div className="container-fluid">
      <div className="row pe-4">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn
              title="Formations E-learning"
              level="h1"
              className="pt-5"
              plus={true}
            />
            <Hn title="Apprenants" level="h3" className="pt-5" plus={true} />
          </div>
       

          {/* lists-filter */}
          {displayetat === "true" ? (
            <Alert color="primary">Aucun resultat trouvé</Alert>
          ) : (
            ""
          )}
          <div className="mt-20">
            {errorexist === "true" ? (
              <Alert color="warning" className="alert-danger">
                Impossible de supprimer ! thématique associée à une formation
                <span
                  style={{ position: "absolute", right: "20px" }}
                  onClick={onDismiss}
                >
                  X
                </span>
              </Alert>
            ) : (
              ""
            )}
            {succesdelete === "true" ? (
              <Alert color="warning" className="alert-success">
                Suppression effectuée avec success
                <span
                  style={{ position: "absolute", right: "20px" }}
                  onClick={onDismiss}
                >
                  X
                </span>
              </Alert>
            ) : (
              ""
            )}
        {apprenant &&
             <Datatable
             setShow={setShow}
             show={show}
             total={total}
             from="elearning"
           />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
export default Listapprenant;
