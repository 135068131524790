import { Button, Modal } from "react-bootstrap";
import Hn from "../common/Hn";
import "./popup.css";
export default function PopupSubscribe({
  showfiltreSubscribe,
  closefiltreSubscribe,
  title,
  setShowfiltreSubscribe
}) {
  
  return (
    <Modal show={showfiltreSubscribe} onHide={() => setShowfiltreSubscribe(false) } size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="px-3 nopad">
          <Hn title={title} level="h4" plus={false} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <p className=" text-white"><span>
      Si vous êtes intéressé pour souscrire à la formule e-learning 
      merci de contacter Dave Ziskind du pôle formation : <a href="mailto:d.ziskind@guy-hoquet.com">d.ziskind@guy-hoquet.com</a>
      </span></p>
      </Modal.Body>
      <Modal.Footer>
        {" "}
        {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
        <Button
          variant="primary"
          //onClick={closefiltre}
          onClick={() => setShowfiltreSubscribe(false) }
        >
          Valider
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
