import { useEffect, useRef, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import { createBrowserHistory } from 'history';
import * as Yup from "yup";
import Add from "../../../../../assets/icons/add.png";
import Hn from "../../../../../components/common/Hn";
import "./createFaceTraining.css";
//const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']
const FILE_SIZE = 2048 * 2048;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png"
];
const validationSchema = Yup.object().shape({
  reference: Yup.string().required("Champ obligatoire."),
  intitule: Yup.string().required("Champ obligatoire."),
  image: Yup.mixed()
  .test(
    "fileFormat",
    "Format non supporté.",
    value => value && SUPPORTED_FORMATS.includes(value.type)
  )
    .nullable()
    .required("Champ obligatoire choissisez une image."),
  theme: Yup.string().required("Champ obligatoire."),
 // duree: Yup.string().matches(/^[0-9]+':'+[0-9]+$/, "Format : 00:00")
 // associe: Yup.string().required("Champ obligatoire."),

});
function CreateFaceTraining() {
  const fileRef = useRef();
  const pdfRef = useRef();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [providers, setProviders] = useState([]);
  const [trainings, setTrainings]=useState([]);
  const [elearnings, setElearnings]=useState([]);
  const [themes, setThemes] = useState([]);
  const imageValue =Yup.ref('image');
  const uri = process.env.REACT_APP_BASE_URL;
 
  //récupérer la liste des formations présentielles
  function getTrainings() {
    
    axios.get(uri + "/training")
      .then((result) => {
        setTrainings(result?.data);
      })
      .catch((err) => {
        console.log("err trainning", err);
      });
  }

   //récupérer la liste des formations elearning
  function getElearnings() {
    
    axios.get(uri + "/elearning")
      .then((result) => {
        setElearnings(result?.data);
      })
      .catch((err) => {
        console.log("err elearning", err);
      });
  }
  let history = createBrowserHistory({forceRefresh:true})
//créer une nouvelle formation présentielle
  const handleSubmit = (values) => {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
            setUploadPercentage(percent);
        }
    }
    };

    
    //let target = ""+values.manager+","+ values.miseavant +","+values.obligation
   
  
    var data = new FormData();
    data.append('ref', values.reference);
    data.append('title', values.intitule);
    data.append('objective', values.object);
    data.append('training_program', values.program);
    data.append('audience', values.prerequis);
    data.append('assoc_cours', values.associe);
    data.append('manager_spec',values.manager);
    data.append('forward_catalog',values.miseavant);
    data.append('obligation',values.obligation);
    data.append('status', values.etat);
    data.append('theme', values.theme);
    data.append('type', '1');
    data.append('comment', values.commentaire);
    data.append('imagefile', values.image);
    data.append('brochure', values.pdf);
    // data.append('duration', values.duree);
 
    let initialVar = values.duree.split(':');
    let hourtominute = parseInt(initialVar[0]) * 60;
    let finalvar = parseInt(hourtominute)+parseInt(initialVar[1]);
    data.append('duration', finalvar);

    data.append('cost', values.tarif);
    data.append('provider', values.prestataire);
    
    axios.post(uri +'/training/new', data, headersFiles)
     .then((result) => {
       setUploadPercentage(100);
       setUploadPercentage(100);

    history.push("/admin/create-session-formation/"+result.data.id);
      console.log(result)
    })
    .catch((err) => {
      console.log("err training", err);
    });
     
  };

  //récupérer la liste de tous les prestataires
  function getProviders()  {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };
    axios
    .get(uri +"/provider", headersFiles)
    .then((result) => {
      setProviders(result?.data);
    
    })
    .catch((err) => {
      console.log("err prestataire", err);
    });
   
   
  }

  //récupérer la liste des thémes des formations présentielles
  function getThem()  {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };
    axios
    .get(uri +"/thematic", headersFiles)
    .then((result) => {
      setThemes(result?.data);
      
    })
    .catch((err) => {
      console.log("err theme", err);
    });
  }
  useEffect(() => {
    
    getProviders();
    getThem();
    getElearnings();
    getTrainings();
  }, []);

  return (
    <div className="container-fluid mb-5">
      <div className="row">
        <div className="container lists pe-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/formation-presentielle">Formations présentielles - Action de formation</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Créer une action de formation
              </li>
            </ol>
          </nav>
          <div className="p-5 text-white bg-dark rounded-3">
            <Hn
              title="Créer une action de formation"
              level="h4"
              className="h4-size"
              plus={false}
            />
            <Hn
              title="Étape 1 : Informations générales"
              level="p"
              className="py-2 fw-bold"
              plus={false}
            />

            <div className="form-elements">
              <Formik
                initialValues={{
                  reference: "",
                  intitule: "",
                  image: null,
                  theme: "",
                  associe: "",
                  prerequis: "",
                  object: "",
                  program: "",
                  pdf: null,
                  prestataire: "",
                  duree: "",
                  tarif: "",
                  etat: false,
                  commentaire: "",
                  miseavant: false,
                  manager: false,
                  obligation: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
                  /* and other goodies */
                }) => (
                  <form className="row g-3" onSubmit={handleSubmit}>
                    <div className="col-md-4">
                      <label  className="form-label">
                        Référence*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ref"
                        name="reference"
                        placeholder="Référence"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.reference}
                      />
                      <ErrorMessage name="reference">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-8">
                      <label  className="form-label">
                        Intitulé*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="intitule"
                        id="init"
                        placeholder="Intitulé"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.intitule}
                      />
                      <ErrorMessage name="intitule">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label  className="form-label">
                        Image*
                      </label>
                      <span
                        className="form-control"
                        onClick={() => fileRef.current.click()}
                      >
                        
                        <img src={Add} className="pe-1" />  {fileRef?.current?.value ? fileRef?.current?.value : 'Ajouter une image'}
                      </span>
                      <p className="formatImg">Format : jpg, png / 10 mo max / Résolution conseillée : xxx</p>
                      <input
                        ref={fileRef}
                        multiple={false}
                        type="file"
                        name="image"
                        onChange={(event) => {
                          setFieldValue("image", event.currentTarget.files[0]);
                        }}
                        hidden
                        accept=".png,.jpg,.gif"
                      />
                      <ErrorMessage name="image">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                      {/* <input className="form-control" type="file" id="image" placeholder="Ajouter une image" /> */}
                    </div>
                    
                       <div className="col-md-4">
                      <label  className="form-label">
                      Thème*
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="theme"
                        id="theme"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.theme}
                      >
                        <option defaultValue>Sélectionner dans la liste</option>
                        {themes.map((theme, i) => {
                            return (
                              <option value={theme.id} key={i}>{theme.name}</option>
                            );
                          })}
                      </select>
                      <ErrorMessage name="themes">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label  className="form-label">
                        Parcours associé
                      </label>
                      {/* <input
                        type="text"
                        className="form-control"
                        id="assoc"
                        name="associe"
                        placeholder="Parcours associé"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.associe}
                      /> */}
                        <select
                      
                      className="form-select"
                      name="associe"
                      id="associe"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.associe}
                    >
                      <option value="">Sélectionner dans la liste</option>

                      {trainings.map((training, i) => {
                        return (
                          <option value={training.title} key={i}>{training.title}</option>
                        );
                      })}

                      {elearnings.map((elearning ,i) => {
                        return (
                          <option value={elearning.id} key={i}>{elearning.intitule}</option>
                        );
                      })}

                    </select>
                      <ErrorMessage name="associe">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label  className="form-label">
                        Pré-requis et public visé
                      </label>
                      <textarea
                        className="form-control"
                        id="pre"
                        rows="3"
                        name="prerequis"
                        placeholder="Détailler"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.prerequis}
                      ></textarea>
                      <ErrorMessage name="prerequis">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label  className="form-label">
                        Objectifs
                      </label>
                      <textarea
                        className="form-control"
                        id="object"
                        rows="3"
                        name="object"
                        placeholder="Description des objectifs"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.object}
                      ></textarea>
                      <ErrorMessage name="object">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label  className="form-label">
                        Programme de la formation
                      </label>
                      <textarea
                        className="form-control"
                        id="pre"
                        rows="3"
                        name="program"
                        placeholder="Description du programme"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.program}
                      ></textarea>
                      <ErrorMessage name="program">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label  className="form-label">
                        Programme de la formation
                      </label>
                      <span
                        className="form-control"
                        onClick={() => pdfRef.current.click()}
                      >
                        <img src={Add} className="pe-1" />  {pdfRef?.current?.value ? pdfRef?.current?.value : 'Ajouter un pdf'}
                      </span>

                      <input
                          ref={pdfRef}
                          multiple={false}
                          type="file"
                          name="pdf"
                          onChange={(event) => {
                            setFieldValue("pdf", event.currentTarget.files[0]);
                          }}
                          hidden
                          accept=".pdf"
                      />
                      <ErrorMessage name="pdf">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                      {values.pdf && uploadPercentage > 0 && (
                                      <div className="row mt-3">
                                          <div className="col pt-1">
                                              <ProgressBar
                                                  now={uploadPercentage}
                                                  striped={true}
                                                  label={`${uploadPercentage}%`}
                                              />
                                          </div>
                                      </div>
                      )}
                    </div>
                    <div className="col-md-4">
                      <label  className="form-label">
                        Prestataire
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="prestataire"
                        id="prestat"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.prestataire}
                      >
                        <option defaultValue>Sélectionner dans la liste</option>
                        {providers.map((provider, i) => {
                            return (
                              <option value={provider.id} key={i}>{provider?.name} |{provider?.contact_name}</option>
                            );
                          })}
                      </select>
                      <ErrorMessage name="prestataire">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label  className="form-label">
                        Durée théorique (en heure)*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="duree"
                        id="duree"
                        placeholder="00:00"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.duree}
                        pattern="\d\d:\d\d"
                      />
                      <ErrorMessage name="duree">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label  className="form-label">
                        Tarif
                      </label>
                      <input
                        type="number"
                        step="0.5"
                        className="form-control cost"
                        name="tarif"
                        id="tarif"
                        placeholder="00"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tarif}
                      />
                      <ErrorMessage name="tarif">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        Actif
                      </label>
                      <select
                        className="form-select"
                        name="etat"
                        aria-label="Default select example"
                        id="actif"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.etat}
                      >
                        <option value="1" defaultValue>
                          Oui
                        </option>
                        <option value="2">Non</option>
                      </select>
                      <ErrorMessage name="etat">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label  className="form-label">
                        Commentaire
                      </label>
                      <textarea
                        className="form-control"
                        name="commentaire"
                        id="comment"
                        rows="3"
                        placeholder=""
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.commentaire}
                      ></textarea>
                      <ErrorMessage name="commentaire">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4 col-xs-12">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          name="miseavant"
                          type="checkbox"
                          id="check"
                          //onChange={handleChange}
                          //onBlur={handleBlur}
                          value={values.miseavant}
                        />
                        <label className="form-check-label" >
                          Mettre en avant dans le catalogue
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          name="manager"
                          type="checkbox"
                          id="check2"
                         // onChange={handleChange}
                         //onChange={handleChange}
                          //onBlur={handleBlur}
                          value={values.manager}
                        />
                        <label className="form-check-label" >
                          Spécifique au manager
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          name="obligation"
                          type="checkbox"
                          id="check3"
                          //onChange={handleChange}
                        
                          value={values.obligation}
                        />
                        <label className="form-check-label" >
                          Participe à l'obligation de formation
                        </label>
                      </div>
                    </div>

                    <div className="col-12 mt-5">
                      <a
                      href="/admin/formation-presentielle"
                     
                        className="btn btn-link text-decoration-none resetBtn"

                      >
                        Annuler
                      </a>
                      <button
                        type="submit"
                        className="btn btn-primary float-end"
                       // disabled={isSubmitting}
                      >
                        Suivant
                      </button>
                      {/* <a href="/admin/create-session-formation-presentielle" className="btn btn-primary float-end">
                                                Suivant
                                            </a> */}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* lists */}
      </div>
    </div>
  );
}

export default CreateFaceTraining;
