import { useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import Papa from "papaparse";
import AxioServices from "../../../../services/AxioServices";
import { Alert, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PopupFiltreActionformation from "../../../../components/popup/PopupFiltreActionformation";
export default function Datatable({
  getTraining,
  training,
  setShow,
  show,
  setTraining,
}) {
  let history = createBrowserHistory({ forceRefresh: true });
  const getMinutes = (item) => {
    let duration = item?.split(":");
  
    return (parseInt(duration[0]) * 60) + parseInt(duration[1]);
  };
  const minCost = Math.min(...training?.map((item) => item?.cost));
  const maxCost = Math.max(...training?.map((item) => item?.cost));

  const minDuration = 1;
  const maxDuration = 16;

  const [id, setId] = useState();

  const [showfiltre, setShowfiltre] = useState(false);
  const [eachEntry, setEachEntry] = useState("");
  let toastId = null;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { ExportCSVButton } = CSVExport;
  const uri = process.env.REACT_APP_BASE_URL;
  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }

  function deleteTraining(id) {
    axios
      .delete(uri + "/training/" + id)
      .then((response) => {
        if (response.data === "theme-null") {
          displayToast(
            "Cerror",
            "Vous ne pouvez pas supprimer cette formation"
          );
        } else {
          displayToast("Csuccess", "Suppression effectuer avec success");
          setTimeout(() => {
            history.go(0);
          }, 3000);
        }
      })
      .catch((err) => {
        displayToast("Cerror", "Quelque chose s'est mal passé");
      });
    setShow(false);
  }

  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
  };
  function searchwithfiltre(val) {
   
    setEachEntry(val);
    if (val) {
 
      if (val.minvalue.trim  === "Infinity") {
        val.minvalue = minDuration;
      }
      if (val.maxvalue.trim  === "-Infinity") {
        val.maxvalue = maxDuration;
      }
      if (val.minCout.trim  === "Infinity") {
        val.minCout = 1;
      }
      if (val.maxCout.trim  === "-Infinity") {
        val.maxCout = 10000;
      }
 
    }

    
  
      let query = "";

      if(val.name !== ""){
        query += `title=${val.name}&`;
      }
      if(val.status ){
        query += `status=${val.status}`;
      }
      if(val.minCout){
        query += `costmin=${val.minCout}`;
      }
      if(val.maxCout ){
        query += `costmax=${val.maxCout}`;
      }

      let MaxD = parseInt(val.maxvalue) * 60;
      let MinD = parseInt(val.minvalue) * 60;
      if(MaxD){
        query += `&durationmax=${MaxD}`;
      }
      if(MinD){
        query += `&durationmin=${MinD}`;
      }
      
      //  `title=${val.name}
      // &durationmin=${val.minvalue}&durationmax=${val.maxvalue}
      // &status=${val.status}&costmin=${val.minCout}&costmax=${val.maxCout}`;
      
      let req = uri + "/training/filter?" + query;

      axios
        .get(req)
        .then((result) => {
       
     
      
            setTraining(result?.data);
          
        })
        .catch((err) => {
          console.log("err location", err);
        });
    
  }
  function duplicate(id) {
    axios
      .post(`${uri}/training/duplicate/${id}`, headers)
      .then((response) => {
        displayToast("Csuccess", "Formation dupliquée avec success...");
        setTimeout(() => {
          getTraining();
        }, 3000);
      })
      .catch((error) => {
        displayToast("error", "Quelque chose s'est mal passé, Réessayer");
      });
  }
  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push("/admin/showActionFormationPres/" + row.id);
    },
  };

  function updateStatus(id, checked) {
    let data = {
      status: checked,
    };

    axios
      .put(`${uri}/training/edit/status/` + id, data, headers)
      .then((response) => {
   
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        results.data[0].ref = results.data[0].ref + "x2";

        AxioServices.create("training/import", JSON.stringify(results.data), {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*",
          },
        })
          .then((result) => {
            displayToast("Csuccess", "Importation en cours, Redirection...");

            // setTimeout(() => {
            //   history.go(0);
            // }, 5000);
          })
          .catch((err) => {
            displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
          });
      },
    });
  };
  const columns = [
    {
      dataField: "title",
      text: "Action de formation",
      csvText: "title",
      sort: true,
    },
    {
      dataField: "ref",
      text: "ref",
      csvText: "ref",
      sort: true,
      hidden: true,
    },
    {
      dataField: "theme.name",
      text: "Thème",
      csvText: "theme",
      sort: true,
    },
    {
      dataField: "duration",
      text: "Nombre d'heures",
      csvText: "duration",
      sort: true,
    },
    {
      dataField: "statuts",
      text: "Actif",
      csvText: "status",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          // <SwitchButton checked={row.status}/>
          <Form className=" form-switch">
            <Form.Check
              //  handleToggle={() => setNewstatuts(!row.status)}
              type="switch"
              id={row.id}
              onChange={(e) => {
                updateStatus(row.id, e.target.checked);
              }}
              defaultChecked={row.status}
            />
          </Form>
        );
      },
    },
    {
      dataField: "sessions",
      text: "Sessions",
      csvText: "sessions",
      formatter: (row) => {
        return <>{row?.length}</>;
      },
    },
    {
      dataField: "cost",
      text: "Cout total",
      csvText: "cost",
      sort: true,
    },
    {
      dataField: "audience",
      text: "audience",
      csvText: "audience",
      sort: true,
      hidden: true,
    },
    {
      dataField: "image",
      text: "image",
      csvText: "image",
      sort: true,
      hidden: true,
    },
    {
      dataField: "programFile",
      text: "programFile",
      csvText: "programFile",
      sort: true,
      hidden: true,
    },
    {
      dataField: "objective",
      text: "objective",
      csvText: "objective",
      sort: true,
      hidden: true,
    },
    {
      dataField: "assoc_cours",
      text: "assoc_cours",
      csvText: "assoc_cours",
      sort: true,
      hidden: true,
    },
    {
      dataField: "training_program",
      text: "training_program",
      csvText: "training_program",
      sort: true,
      hidden: true,
    },
    {
      dataField: "forward_catalog",
      text: "forward_catalog",
      csvText: "forward_catalog",
      sort: true,
      hidden: true,
    },
    {
      dataField: "manager_spec",
      text: "manager_spec",
      csvText: "manager_spec",
      sort: true,
      hidden: true,
    },
    {
      dataField: "manager_spec",
      text: "manager_spec",
      csvText: "manager_spec",
      sort: true,
      hidden: true,
    },
    {
      dataField: "obligation",
      text: "obligation",
      csvText: "obligation",
      sort: true,
      hidden: true,
    },
    {
      dataField: "createdAt",
      text: "createdAt",
      csvText: "createdAt",
      sort: true,
      hidden: true,
    },
    {
      dataField: "comment",
      text: "comment",
      csvText: "comment",
      sort: true,
      hidden: true,
    },
    {
      dataField: "actions",
      text: "",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="btn-group float-end" role="group" aria-label="Basic example">
            <a
              href={"/admin/update-formation-presentielle/" + row.id}
              type="button"
              className="btn btn-link"
            >
              <img src={require("../../../../assets/icons/edit.png")} />
            </a>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                duplicate(row.id);
              }}
            >
              <img src={require("../../../../assets/icons/duplicate.png")} />
            </button>
            {localStorage.getItem("role") === "Super-admin" ? (
              <button
                type="button"
                className="btn"
                onClick={() => {
                  setId(row.id);
                  handleShow();
                }}
              >
                <img src={require("../../../../assets/icons/delete.png")} />
              </button>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    // hideSelectAll: true,
  };
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };
 
  return (
    <>
      <ToastContainer />
      <ToolkitProvider
        keyField="id"
        data={training}
        columns={columns}
        exportCSV={{
          onlyExportSelection: true,
          exportAll: false,
          fileName: "FormationPrésentielles.csv",
        }}
        search
      >
        {(props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter my-5">
              <div className="row">
                <div className="col-xl-4 col-lg-1"></div>
                <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                  <div className="flexgy">
                    <div className="col-md-4  mr-1  px-0">
                      <div className="input-group">
                        <input
                          className="form-control height43"
                          type="search"
                          placeholder="Recherche"
                          onChange={(e) =>
                            props.searchProps.onSearch(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className=" px-0 myxs">
                      <div className="flex">
                        <div className="square">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={() => setShowfiltre(true)}
                          >
                            <img
                              src={require("../../../../assets/icons/filter.png")}
                            />
                          </button>
                        </div>
                        <PopupFiltreActionformation
                          showfiltre={showfiltre}
                          closefiltre={handleClosefiltre}
                          searchwithfiltre={searchwithfiltre}
                          filterEntry={eachEntry}
                          minCost={minCost}
                          maxCost={maxCost}
                          minDuration={minDuration}
                          maxDuration={maxDuration}
                        />
                        <div className="square">
                          <button
                            className="btn btn-link"
                            htmlFor="export"
                            onClick={() => props.csvProps.onExport()}
                          >
                            <img
                              src={require("../../../../assets/icons/Export.png")}
                            />
                          </button>
                        </div>
                        <div className="square">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept={".csv"}
                            className="btn btn-link"
                            onChange={handleOnChange}
                            hidden
                          />
                          <label for="file" type="button">
                            <img
                              src={require("../../../../assets/icons/Import.png")}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="px10">
                      <div className="d-grid gap-2">
                        <a
                          href="/admin/create-formation-presentielle"
                          className="btn btn-primary  btn-gy height43"
                          type="button"
                        >
                          Créer une action de formation
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <BootstrapTable
              {...props.baseProps}
              classes="table-dark"
              bordered={false}
              hover
              selectRow={selectRow}
              pagination={paginationFactory(options)}
              sizePerPageList={[100]}
              rowEvents={tableRowEvents}
            />
          </div>
        )}
      </ToolkitProvider>

      <Modal show={show} onHide={handleClose} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            Etes-vous sûr(e) de vouloir supprimer?
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={handleClose} style={{ color: "#006FFF" }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-primary"
            onClick={(e) => deleteTraining(id)}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
