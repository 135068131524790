import axios from "axios";
import { useEffect, useState } from "react";
import AxioServices from "../../../../services/AxioServices";
import Papa from "papaparse";
import { createBrowserHistory } from "history";
import Hn from "../../../../components/common/Hn";
import "./faceTraining.css";
import Datatable from "./Datatable.js";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function FaceTraining() {
  const [training, setTraining] = useState([]);
  const [initraining, setInitraining] = useState([]);
  
  const [term, setTerm] = useState();
  const [show, setShow] = useState(false);
 
  let toastId = null;
  const uri = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    getTraining();
  }, []);

  let history = createBrowserHistory({ forceRefresh: true });
  //récupérer toutes les formations présentielles
  function getTraining() {
    axios
      .get(uri + "/training")
      .then((result) => {
       
        setTraining(result?.data);
       
        //result?.data.filter((el) =>)
        setInitraining(result?.data);
      })
      .catch((err) => {
        console.log("err location", err);
      });
  }
 //affichage de la notification(toast)
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }

  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn
              title="Formations présentielles"
              level="h1"
              className="pt-5"
              plus={false}
            />
            <Hn title="Actions de formation" level="h2" className="pt-4" />
          </div>

          <div className="mt-20 trainingTable">
           
              <Datatable
                setShow={setShow}
                show={show}
                training={training}
                getTraining={getTraining}
                setTraining={setTraining}
              />
           
          </div>
        
        </div>
      </div>
    </div>
  );
}

export default FaceTraining;
