import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useHistory } from "react-router-dom";
import { date, number, object, ref, string } from "yup";
import Hn from "../../../../components/common/Hn";
import Icon from "../../../../components/common/Icon";
import "./createFaceTraining.css";
const uri = process.env.REACT_APP_BASE_URL;
let headers = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};
var dte = new Date();

const validationSchema = object().shape({
  ref: string().required("champ obligatoire"),
  dateStart: date().default(() => new Date()),
  dateEnd: date().default(() => new Date()),
  location_id: string().required("champ obligatoire"),
  // seat_capacity_min: number().required("champ obligatoire"),
  seat_capacity_min: number()
    .max(
      ref("seat_capacity_max"),
      "Must be less than Capacité d'acccueil maxinale"
    )
    .required("champ obligatoire"),
  seat_capacity_max: number()
    .min(
      ref("seat_capacity_min"),
      "Must be more than Capacité d'acccueil minimale  "
    )
    .required("champ obligatoire"),
  former: string().required("champ obligatoire"),
});

function CreateFaceSessionElearning5() {
  const fileRef = useRef();
  const [modalShow, setModalShow] = useState(false);
  const [lieuFormation, setLieuForamtion] = useState([]);
  const [formateurs, setForamteurs] = useState([]);
  const [sessions, setSessions] = useState([]);
  const onHandelStatus = (touched, error, value) => {
    if (error && touched) {
      return "danger";
    } else if (value) {
      return "info";
    } else {
      return "warning";
    }
  };

  function getformer(type) {
    axios
      .get(uri+"/former")
      .then((result) => {
        setForamteurs(result?.data);
      })
      .catch((err) => {
        console.log("err formateurs", err);
      });
  }
  function getSessions() {
    axios
      .get(uri+"/session")
      .then((result) => {
        setSessions(result?.data);
       
      })
      .catch((err) => {
        console.log("err sessions", err);
      });
  }
  function ChangeList(value) {
    getformer(value);
  }
  useEffect(() => {
    getSessions();
    getformer("");
    axios
      .get(uri+"/location")
      .then((result) => {
        setLieuForamtion(result?.data);
      })
      .catch((err) => {
        console.log("err location", err);
      });
  }, []);

  
  let history = useHistory();
  const handleSubmit = (values) => {
    axios
      .post(`${uri}/session/new`, values, headers)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Formations présentielles - Actions de formation</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Créer une action de formation
              </li>
            </ol>
          </nav>
          <div className="h-100 p-5 text-white bg-dark rounded-3">
            <Hn
              title="Créer une action de formation"
              level="h4"
              className="h4-size"
              plus={false}
            />
            <Hn
              title="Etape 3 : Compétences associées"
              level="p"
              className="py-2 fw-bold"
              plus={false}
            />
            <br/>
           <div className="dropref col-md-4">
            <label htmlhtmlFor="actif" className="form-label">
            Référentiel de compétences:
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="Référenciel"
                        id="Référenciel" 
                      >   
                       <option>Sélectionner un référenciel</option>
                 
                      </select>
                      
                      </div>
            <div className="form-elements mt-2">
             
              <div className="col-12 mt-5">
                <a
                  href="/admin/create-formation-presentielle"
                  className="btn btn-link resetBtn"
                >
                  Annuler
                </a>
                <a
                  type="submit"
                  className="btn btn-primary float-end"
                  disabled
                  href="/admin/create-session-formation-elearning2"
                >
                  Valider
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateFaceSessionElearning5;
