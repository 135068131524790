import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import { createBrowserHistory } from "history";
import { useEffect, useRef, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import Hn from "../../../../../components/common/Hn";
import { ProgressBar } from "react-bootstrap";

const validationSchema = Yup.object().shape({
  reference: Yup.string().required("La référence est obligatoire"),
  intitule: Yup.string().required("L'intitulé de formation est obligatoire"),
  //image: Yup.mixed().nullable().required("Choissisez une image SVP"),

  theme: Yup.string().required("Veuillez SVP choisir un thème"),
  parcours_associe: Yup.string().nullable(),
  prerequis: Yup.string().nullable(),
  object: Yup.string().nullable(),
  program: Yup.string().nullable(),
  validation: Yup.string().required("Le taux de validation est obligatoire"),

  duree: Yup.number().required("La durée de la formation est obligatoire"),
  commentaire: Yup.string().nullable(),
});
function UpdateElearning() {
  const fileRef = useRef();
  const pdfRef = useRef();
  const [providers, setProviders] = useState([]);
  const [trainingDetails, setTrainingDetails] = useState([]);
  const [elearning, setElearning] = useState([]);
  const imageValue = Yup.ref("image");
  const [localState, setLocalstate] = useState("initial");
  const [themes, setThemes] = useState([]);
  let toastId = null;
  const { id } = useParams();
  const [elearnings, setElearnings] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const managerRef = useRef();
  const misEnAvantRf = useRef();
  const ObligationDeFormationRef = useRef();

  const uri = process.env.REACT_APP_BASE_URL;
  const uriElarning = process.env.REACT_APP_PIC_ELEARNING_IMG;
  const docElarning = process.env.REACT_APP_PIC_ELEARNING_FILE;

  const [uploadPercentage, setUploadPercentage] = useState(0);

 // Récupérer la liste des formations elearning
  function getElearnings() {
    trackPromise(
      /*local*/ axios
        .get(uri + "/elearning")
        .then((result) => {
          setElearnings(result?.data);
        })
        .catch((err) => {
          console.log("err location", err);
        })
    );
  }
  // Récupérer le détail de la formation elearning récupérée par (id)
  function getElearning() {
    trackPromise(
      axios
        .get(uri + "/elearning/" + id)
        .then((response) => {
          setElearning(response?.data);

          if (response?.data?.mis_en_avant == 1) {
            misEnAvantRf.current.checked = true;
          } else {
            misEnAvantRf.current.checked = false;
          }

          if (response?.data?.manager == 1) {
            managerRef.current.checked = true;
          } else {
            managerRef.current.checked = false;
          }

          if (response?.data?.obligation_de_formation == 1) {
            ObligationDeFormationRef.current.checked = true;
          } else {
            ObligationDeFormationRef.current.checked = false;
          }
        })
        .catch((error) => {
          // TODO 404 page
          console.log("Erreur", error.response.data);
          return error.response;
        })
    );
  }

  // Récupérer la liste des formations présentielles

  function getTrainings() {
    trackPromise(
      axios
        .get(uri + "/training")
        .then((result) => {
          setTrainings(result?.data);
        })
        .catch((err) => {
          console.log("err location", err);
        })
    );
  }

  // Changer la valeur d'un checkbox

  const handleChangecheckbox = (e) => {
    if (e.target.checked) {
      e.target.value = 1;
    } else {
      e.target.value = 0;
    }
  };

  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }

  let history = createBrowserHistory({ forceRefresh: true })
  // Modification d'une formation elearning

  const handleSubmit = (values) => {

    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
          setUploadPercentage(percent);
        }
      }
    };

    var data = new FormData();
    data.append("ref", values.reference);
    data.append("intitule", values.intitule);
    data.append("objectifs", values.object);

    data.append("programme_de_formation", values.program);
    data.append("prerequis", values.prerequis);
    data.append("parcours_associe", values.parcours_associe);

    data.append("mis_en_avant", 0);
    data.append("manager", 0);
    data.append("obligation_de_formation", 0);

    if (misEnAvantRf.current.checked) {
      data.append("mis_en_avant", 1);
    }
    if (managerRef.current.checked) {
      data.append("manager", 1);
    }
    if (ObligationDeFormationRef.current.checked) {
      data.append("obligation_de_formation", 1);
    }

    data.append("actif", values.etat ==="1"?1:0);
    data.append("theme", values.theme);
    data.append("validation", values.validation);

    data.append("commentaire", values.commentaire);
    data.append("image", values.image);

    data.append("duree", values.duree);

    if (typeof values.pdf == "undefined") {
      data.append("fichier_programme", values.fichier_programme);
    } else {
      data.append("fichier_programme", values.pdf);
    }

    trackPromise(
      /*local*/ axios
        .post(uri + "/elearning/edit/" + id, data, headersFiles)
        .then((result) => {

          setUploadPercentage(100);

          setTimeout(() => {
            setUploadPercentage(0);
          }, 1000);

          history.push("/admin/creer-chapitre-e-learning/" + result?.data?.id);
        })

        .catch((err) => {
          console.log("err training", err);
        })
    );
  };
  // lister toutes les thématiques
  function getThem() {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    trackPromise(
      axios
        .get(uri + "/thematic", headersFiles)
        .then((result) => {
          setThemes(result?.data);
          //console.log(result?.data)
        })
        .catch((err) => {
          console.log("err theme", err);
        })
    );
  }
// Reprendre la liste des prestataires
  function getProviders() {
    trackPromise(
      axios
        .get(uri + "/provider")
        .then((result) => {
          setProviders(result?.data);
        })
        .catch((err) => {
          console.log("err prestataire", err);
        })
    );
  }

  const params = useParams();
  useEffect(() => {
    getElearning();
    getElearnings();
    getTrainings();
    getThem();
    getProviders();
  }, []);

  return (
    <div className="container-fluid mb-5">
      <ToastContainer />
      <div className="row">
        <div className="container lists pe-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/formation-e-learning">Formations e-learning - Actions de formation</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                modifier une action de formation
              </li>
            </ol>
          </nav>
          <div className="p-5 text-white bg-dark rounded-3">
            <Hn
              title="modifier une action de formation"
              level="h4"
              className="h4-size"
              plus={false}
            />
            <Hn
              title="Étape 1 : Informations générales"
              level="p"
              className="py-2 fw-bold"
              plus={false}
            />

            <div className="form-elements">
              <Formik
                initialValues={{
                  reference: elearning?.ref,
                  intitule: elearning?.intitule,
                  image: elearning?.image,
                  theme: elearning?.theme?.id,
                  parcours_associe: elearning?.parcours_associe,
                  prerequis: elearning?.prerequis ?elearning?.prerequis :"",
                  object: elearning?.objectifs?elearning?.objectifs : "",
                  program: elearning?.programme_de_formation?elearning?.programme_de_formation : "",
                  fichier_programme: elearning?.fichier_programme,
                  duree: elearning?.duree,
                  etat: elearning?.actif,
                  commentaire: elearning?.commentaire?elearning?.commentaire : "",
                  miseavant: 0,
                  manager: "0",
                  validation: elearning?.validation,
                  obligation: 0,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <form className="row g-3" onSubmit={handleSubmit}>
                    <div className="col-md-4">
                      <label for="ref" className="form-label">
                        Référence*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ref"
                        name="reference"
                        placeholder="Référence"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.reference}
                      />
                      <ErrorMessage name="reference">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-8">
                      <label for="init" className="form-label">
                        Intitulé*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="intitule"
                        id="init"
                        placeholder="Intitulé"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.intitule}
                      />
                      <ErrorMessage name="intitule">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label for="image" className="form-label">
                        Image
                      </label>
                      <span
                        className="form-control"
                        onClick={() => fileRef.current.click()}
                      >
                        <img
                          src={uriElarning + elearning.image}
                          className="pe-1"
                          style={{ width: "50px", height: "30px" }}
                        />{" "}
                        {fileRef?.current?.value
                          ? fileRef?.current?.value
                          : "Ajouter une image"}
                      </span>

                      <input
                        ref={fileRef}
                        multiple={false}
                        type="file"
                        name="image"
                        onChange={(event) => {
                          setFieldValue("image", event.currentTarget.files[0]);
                        }}
                        hidden
                        accept=".png,.jpg,.gif"
                      />
                      <ErrorMessage name="image">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                      {/* <input className="form-control" type="file" id="image" placeholder="Ajouter une image" /> */}
                    </div>

                    <div className="col-md-4">
                      <label for="theme" className="form-label">
                        Thème*
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="theme"
                        id="theme"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.theme}
                        required
                      >
                        <option>Sélectionner dans la liste</option>
                        {themes.map((theme, i) => {
                          return <option value={theme.id}  key={i}>{theme.name}</option>;
                        })}
                      </select>
                      <ErrorMessage name="themes">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label for="parcours_associe" className="form-label">
                        Parcours associé
                      </label>

                      <select
                        className="form-select"
                        name="parcours_associe"
                        id="parcours_associe"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.parcours_associe}
                      >
                        <option defaultValue>Sélectionner dans la liste</option>

                        {elearnings.map((learning, i) => {
                          return (
                            <option value={"elearning-" + learning.id}  key={i}>
                              {learning.intitule}
                            </option>
                          );
                        })}

                        {trainings.map((train, i) => {
                          return (
                            <option value={"training-" + train.id}  key={i}>
                              {train.title}
                            </option>
                          );
                        })}
                      </select>

                      <ErrorMessage name="parcours_associe">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label for="pre" className="form-label">
                        Pré-requis et public visé
                      </label>
                      <textarea
                        className="form-control"
                        id="pre"
                        rows="3"
                        name="prerequis"
                        placeholder="Détailler"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.prerequis}
                      ></textarea>
                      <ErrorMessage name="prerequis">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label for="object" className="form-label">
                        Objectifs
                      </label>
                      <textarea
                        className="form-control"
                        id="object"
                        rows="3"
                        name="object"
                        placeholder="Description des objectifs"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.object}
                      ></textarea>
                      <ErrorMessage name="object">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label for="preg" className="form-label">
                        Programme de la formation
                      </label>
                      <textarea
                        className="form-control"
                        id="pre"
                        rows="3"
                        name="program"
                        placeholder="Description du programme"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.program}
                      ></textarea>
                      <ErrorMessage name="program">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label for="prog" className="form-label">
                        Programme de la formation
                      </label>
                      <span
                        className="form-control"
                        onClick={() => pdfRef.current.click()}
                      >
                        {elearning?.fichier_programme !== null &&
                          elearning?.fichier_programme != "null" && (
                            <a
                              href={docElarning + elearning?.fichier_programme}
                              className="pe-1"
                            >
                              Télécharger
                            </a>
                          )}

                        {pdfRef?.current?.value
                          ? pdfRef?.current?.value
                          : "Ajouter un pdf"}
                      </span>

                      <input
                        ref={pdfRef}
                        multiple={false}
                        type="file"
                        name="pdf"
                        onChange={(event) => {
                          setFieldValue("pdf", event.currentTarget.files[0]);
                        }}
                        hidden
                        accept=".pdf"
                      />
                      <ErrorMessage name="pdf">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>

                      {values.pdf && uploadPercentage > 0 && (
                        <div className="row mt-3">
                          <div className="col pt-1">
                            <ProgressBar
                              now={uploadPercentage}
                              striped={true}
                              label={`${uploadPercentage}%`}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label for="duree" className="form-label">
                        Durée théorique (en minutes)*
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="duree"
                        id="duree"
                        placeholder="00:00"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.duree}
                      />
                      <ErrorMessage name="duree">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label for="actif" className="form-label">
                        Actif
                      </label>
                      <select
                        className="form-select"
                        name="etat"
                        id="etat"
                        aria-label="Default select example"
                        // defaultValue={values.etat?"1":"0"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // value={values.etat?"1":"0"}
                      >
                        <option value={1} selected={values.etat == '1'}>
                          Oui
                        </option>
                        <option value={0} selected={values.etat == '0'}>Non</option>
                 </select>
                      <ErrorMessage name="etat">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label for="comment" className="form-label">
                        Commentaire
                      </label>
                      <textarea
                        className="form-control"
                        name="commentaire"
                        id="comment"
                        rows="3"
                        placeholder=""
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.commentaire}
                      ></textarea>
                      <ErrorMessage name="commentaire">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label for="validation" className="form-label">
                        Valdation
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="validation"
                        id="validation"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.validation}
                      />
                      <ErrorMessage name="validation">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4"></div>
                    <div className="col-md-4"></div>

                    <div className="col-md-4 col-xs-12">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          ref={misEnAvantRf}
                          name="miseavant"
                          type="checkbox"
                          id="miseavant"
                          onChange={(e) => handleChangecheckbox(e)}
                        />
                        <label className="form-check-label" for="miseavant">
                          Mettre en avant dans le catalogue
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          ref={managerRef}
                          name="manager"
                          type="checkbox"
                          id="manager"
                          onChange={(e) => handleChangecheckbox(e)}
                        />
                        <label className="form-check-label" for="manager">
                          Spécifique au manager
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          ref={ObligationDeFormationRef}
                          name="obligation"
                          type="checkbox"
                          id="obligation"
                          onChange={(e) => handleChangecheckbox(e)}
                        />
                        <label className="form-check-label" for="obligation">
                          Participe à l'obligation de formation
                        </label>
                      </div>
                    </div>

                    <div className="col-12 mt-5">
                      <a
                        type="reset"
                        className="btn btn-link text-decoration-none"
                        href="/admin/formation-e-learning"
                      >
                        Annuler
                      </a>
                      <button
                        type="submit"
                        className="btn btn-primary float-end"
                      >
                        Suivant
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* lists */}
      </div>
    </div>
  );
}

export default UpdateElearning;
