import { createBrowserHistory } from "history";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Hn from "../../components/common/Hn";
import "./Login.css";

export default function Login() {
  const [accept, setAccept] = useState(false);
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [agence, setAgence] = useState([]);
  let toastId = null;
  let history = createBrowserHistory({ forceRefresh: true });
  
  
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }
//connexion que pour les comptes autorisés qui sont présentés dans le composant connexion selon chaque role et chaque leur redirection 
  function checkcgv() {
 
    if (accept) {
      localStorage.setItem("authorise", "yes");
      //role
      if(localStorage.getItem("role") === "Admin"){
          history.push("/admin/apprenant");
      }
      else if(localStorage.getItem("role") === "Super-admin")
        {
          history.push("/admin/dashboard");
        }
      else{
        history.push("/Formations-e-learning");
      }
      
    } else {
      localStorage.setItem("authorise", "no");
      displayToast("Cerror", "Vous n'avez pas accepté les CGV, CGU");
    }
  }
  useEffect(() => {
    var result = localStorage.getItem("agence")?.split(",");
    result?.forEach(function (val, index, array) {
      array[index] = val;
    });
  
    setAgence(result); //est un useState pour afficher le nom de l'agence du user connecté qui s'affiche dans le popup loginProfil
  }, []);

  return (
    <div className="container-Bienvenue">
      <ToastContainer />
      <div className="row">
        <div className="card-body text-center py-5">
          <Hn title="Bienvenue" level="h1" />
          <Hn
            title={
              localStorage.getItem("nom") + " " + localStorage.getItem("prenom")
            }
            level="h5"
            plus={false}
          />

          {agence &&
            agence?.map((val, i) => (
              <>
                <span className="nameAgence" key={i}>{val}</span>
                <br></br>
              </>
            ))}
          <div className="form-check d-flex justify-content-center pt-3">
            <input
              className="form-check-input plus-right"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              onChange={() => setAccept(!accept)} //accept est un state pour accepter les CGV( les conditions générales de vente ),CGU(les conditions générales d'utilisation) 
              defaultChecked={accept}
            />
            <label
              className="form-check-label "
              htmlFor="flexCheckDefault"
              style={{ color: "white" }}
            >
              j'accepte les CGV,CGU et réglement intérieur de la plateforme
            </label>
          </div>

          <div className="pt-3">
            {/* href="/formation-presentielle" */}
            <button className="btn btn-primary" onClick={checkcgv}>
              Accéder à la plateforme
            </button>
          </div>
        </div>
        <div className="bunner-info text-center pt-3" style={{ zIndex: -1 }}>
          <ul className="list-inline">
            <li className="list-inline-item">
              <a href="#" className="stretched-link" style={{ color: "gray" }}>
                Règlement intérieur
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#" className="stretched-link" style={{ color: "gray" }}>
                CGV
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#" className="stretched-link" style={{ color: "gray" }}>
                CGU
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#" className="stretched-link" style={{ color: "gray" }}>
                Cookies
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
