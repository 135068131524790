import axios from "axios";
import * as Yup from "yup";
import "./InfoApprenant3.css";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import Collapse from "react-bootstrap/Collapse";
import Hn from "../../../../../components/common/Hn";
import Add from "../../../../../assets/icons/add.png";
import Close from "../../../../../assets/icons/Close.png";
import ChevronDown from "../../../../../assets/icons/ChevronDown.svg";
import { createBrowserHistory } from "history";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button } from "bootstrap";

export default function InfoApprenant3() {
  const params = useParams();
  const [apprenant, setApprenant] = useState([]);
  const [open, setOpen] = useState(false);
  const uri = process.env.REACT_APP_BASE_URL;
  let history = createBrowserHistory({ forceRefresh: true });
//récupérer un apprenant choisi par son id
  function getapprenant(id) {
    axios
      .get(
        uri+`/participant/${params.id}`
      )
      .then((result) => {
        setApprenant(result?.data);
      
      })
      .catch((err) => {
        console.log("err apprenant", err);
      });
  }

  useEffect(() => {
    getapprenant(params.id);
  }, []);

  return (
    <div className="apprenant container-fluid">
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Formation e-learning - Apprenants</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Nom de l'apprenant3
              </li>
            </ol>

            <a type="submit" className="btn btn-link resetBtn">
              Retour
            </a>
            <br></br>
            <div className="row">
              <div className="col-md-6">
                <Hn
                  title="Nom de l'apprenant"
                  level="h4"
                  className="h4-size pb-5"
                  plus={true}
                />
              </div>
            </div>
          </nav>
          <div className="h-50 p-5 text-white bg-dark rounded-3">
            <Hn
              title="Informations générales"
              level="h4"
              className="h4-size pb-5"
              plus={false}
            />

            <div className="form-elements">
              <div className="row">
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Apprenant
                    </label>
                    <br></br>
                    <span>{apprenant.collaborator}</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Email
                    </label>
                    <br></br>
                    <span>{apprenant.email}</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Métier
                    </label>
                    <br></br>
                    <span>{apprenant.job}</span>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="row">
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Agent immobilier
                      </label>
                      <br></br>
                      {apprenant?.subtheme?.map((val, index) => (
                        <span key={index}>
                          {val.name}
                          <br></br>
                        </span>
                      ))}{" "}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Email agent immobilier
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Nom de l'agence de rattachement
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Adresse de l’agence
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Code postal
                      </label>
                      <br></br>
                      <span>{apprenant.zip}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Ville
                      </label>
                      <br></br>
                      <span>{apprenant.ville}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Code GT
                      </label>
                      <br></br>
                      <span>{apprenant.codeGt}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Offre de formation
                      </label>
                      <br></br>
                      <span>{apprenant.offre}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="lists-filter pt-5">
            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6 pe-0">
                <div className="row">
                  <div className="col-md-9 pe-0">
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Recherche"
                      />
                      <span className="input-group-append">
                        <button className="btn btn-outline-dark" type="button">
                          <img
                            src={
                              require("../../../../../assets/icons/Search.svg")
                                .default
                            }
                          />
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="d-flex">
                      <div className="square" style={{backgroundColor:"#006FFF"}}>
                        <img
                          src={require("../../../../../assets/icons/editwhite.png")}
                          
                        />
                        <img
                          style={{marg:"80px"}}
                          src={require("../../../../../assets/icons/trait.png")}
                        />
                      </div>
                      <div className="square">
                        <button type="button" className="btn btn-link">
                          <img
                            src={require("../../../../../assets/icons/filter.png")}
                          />
                        </button>
                      </div>
                      <div className="square">
                        <button type="button" className="btn btn-link">
                          <img
                            src={require("../../../../../assets/icons/Export2.png")}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* lists-filter */}

          <div className="pt-5">
            <table className="table table-dark table-hover">
              <thead>
                <tr>
                  <th scope="col">Action de formation</th>
                  <th scope="col">Unités d'apprentissage</th>
                  <th scope="col">Date</th>
                  <th scope="col">Durée</th>
                  <th scope="col">Score</th>
                  <th scope="col">Attestation</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                >
                  <td>Pathologie du batiment</td>
                  <td>2</td>
                  <td style={{fontWeight: "bold"}} className="cadreapprenant">02/01/2022</td>
                  <td style={{fontWeight: "bold"}} className="cadreapprenant">12:06</td>
                  <td style={{fontWeight: "bold"}} className="cadreapprenant">52%</td>
                  <td className="text-center">
                    {" "}
                    <img
                      src={require("../../../../../assets/icons/Export2.png")}
                    />
                  </td>
                  <td className="text-center">
                    {" "}
                    <img
                      src={require("../../../../../assets/icons/dropdown.png")}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Pathologie du batiment</td>
                  <td>2</td>
                  <td style={{fontWeight: "bold"}} className="cadreapprenant">02/01/2022</td>
                  <td style={{fontWeight: "bold"}} className="cadreapprenant">12:06</td>
                  <td style={{fontWeight: "bold"}} className="cadreapprenant">52%</td>
                  <td className="text-center">
                    {" "}
                    <img
                      src={require("../../../../../assets/icons/Export2.png")}
                    />
                  </td>
                  <td className="text-center">
                    {" "}
                    <img
                      src={require("../../../../../assets/icons/dropdown.png")}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Pathologie du batiment</td>
                  <td>2</td>
                  <td style={{fontWeight: "bold"}} className="cadreapprenant">02/01/2022</td>
                  <td style={{fontWeight: "bold"}} className="cadreapprenant">12:06</td>
                  <td style={{fontWeight: "bold"}} className="cadreapprenant">52%</td>
                  <td className="text-center">
                    {" "}
                    <img
                      src={require("../../../../../assets/icons/Export2.png")}
                    />
                  </td>
                  <td className="text-center">
                    {" "}
                    <img
                      src={require("../../../../../assets/icons/dropdown.png")}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Pathologie du batiment</td>
                  <td>2</td>
                  <td style={{fontWeight: "bold"}} className="cadreapprenant">02/01/2022</td>
                  <td style={{fontWeight: "bold"}} className="cadreapprenant">12:06</td>
                  <td style={{fontWeight: "bold"}} className="cadreapprenant">52%</td>
                  <td className="text-center">
                    {" "}
                    <img
                      src={require("../../../../../assets/icons/Export2.png")}
                    />
                  </td>
                  <td className="text-center">
                    {" "}
                    <img
                      src={require("../../../../../assets/icons/dropdown.png")}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Pathologie du batiment</td>
                  <td>2</td>
                  <td style={{fontWeight: "bold"}} className="cadreapprenant">02/01/2022</td>
                  <td style={{fontWeight: "bold"}} className="cadreapprenant">12:06</td>
                  <td style={{fontWeight: "bold"}} className="cadreapprenant">52%</td>
                  <td className="text-center">
                    {" "}
                    <img
                      src={require("../../../../../assets/icons/Export2.png")}
                    />
                  </td>
                  <td className="text-center">
                    {" "}
                    <img
                      src={require("../../../../../assets/icons/dropdown.png")}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
