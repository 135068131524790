import { FormGroup, Input } from "reactstrap";
import Pagination from 'react-bootstrap/Pagination';

import Hn from "../../components/common/Hn";
import { useEffect, useState } from "react";
import axios from "axios";
import Mostformation from "../../components/owl counter/Mostformation";
import "./Header.css";
import "./Search.css";
import { translateThumbs } from "react-range/lib/utils";

export default function Search() {

  const [term, setTerm] = useState();
  const [elearnings, setElearnings] = useState([]);
  
  const [initraining, setInitraining] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);
  const uri = process.env.REACT_APP_BASE_URL;
  const IMG_URL = process.env.REACT_APP_PIC_ELEARNING_IMG;
  const IMG_URL2 = process.env.REACT_APP_PIC_FACETRAINING_PATH;

  let active = 2;
  let items = [];

  for (let number = 1; number <= total / 50; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active} onClick={() => setCurrentPage(number)}>
        {number}
      </Pagination.Item>
    );
  }

  const paginationBasic = (
    <div className="float-e">
      <Pagination size="lg">{items}</Pagination>
    </div>
  );


  const search= (event)=>{

    axios
      .get(uri + "/training/search?page="+currentPage+"&search="+event.target.value )
      .then((result) => {
        
        setElearnings(result?.data);
        setTotal(result?.data.total);
      
      })


  }

  const getPage = (search) => {
 
    axios
      .get(uri + "/training/search?page="+currentPage+"&search="+search )
      .then((result) => {
     
        setElearnings(result?.data);
        setTotal(result?.data.total);
      })
      // .catch((err) => {
      //   console.log("err training", err);
      // });
  };

  useEffect(() => {
    getPage('');
  }, []);

  return (
    <div className="search p-3">
      <FormGroup className="searchinput mb-0">
        {/* <img src={require("../../assets/icons/Vector.svg").default} className="nav_icon" alt="vector" /> */}
        {/* <Input placeholder="RECHERCHE" className="input" style={{paddingLeft: "30px !important"}}  /> */}

        <input
          type="recherche"
          placeholder="Recherche"
        onChange={search}
         // onKeyUp={(e =>getPage(e.target.value))}
        />
      </FormGroup>
      
     
      <Mostformation
        details={"detailformations"}
        //  data={elearnings.slice(0,100)}
        data={elearnings?.data}
        url={IMG_URL}
        carousel={false}
        switchs={true}
        
      />
      

       {paginationBasic}

    </div>
  );
}
