import axios from "axios";
// import { format } from 'date-fns';
import dateFormat from "dateformat";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import moment from "moment";
import { createBrowserHistory } from 'history';
import PopupFiltreWebinaire from '../../components/popup/PopupFiltreWebinaire';
import AxioServices from "../../services/AxioServices";
import Papa from "papaparse";
import { ToastContainer, toast } from "react-toastify";
export default function ListWebinarDataGrid({

}) {
  let history = createBrowserHistory({forceRefresh:true})
  const [webinair, setWebinair] = useState([]);
  const { ExportCSVButton } = CSVExport;
  const uri = process.env.REACT_APP_BASE_URL;
  const [show, setShow] = useState(false);
  const [initialparticipant, setInitialParticipant] = useState([]);
  const [showfiltre, setShowfiltre] = useState(false);
  const [displayetat, setDisplayetat] = useState("false");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [eachEntry, setEachEntry] = useState("");
  let toastId = null;
  const [id, setId] = useState();

  function getwebinair() {
    axios
     
      .get(uri + `/webinars/all`)
      .then((result) => {
       
        setWebinair(result?.data);
      
      })
      .catch((err) => {
        console.log("err webinar", err);
      });
  }
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }
  useEffect(() => {
    getwebinair();
  }, [show]);

  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };
  const params = useParams();
  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
    searchwithfiltre(obj);
  };
  const handleShowfiltre = () => {
    setShowfiltre(true);
  };

  function searchwithfiltre(val) {
    setInitialParticipant(webinair);
    setEachEntry(val)
    if (val) {
      setWebinair([]);
      let webinarsFilter = webinair.filter(el =>    
      ( el?.title.toLowerCase().includes(val?.titre.toLowerCase() )&& (val.titre.length>0))
      || moment(el?.date).format("YYYY-MM-DD")  === val.date && (val.date.length>0)
      || (moment(el?.hour).format("kk:mm")===val.heure && (val.heure.length>0))
      || el?.former?.id === val.former && val.former.length>0
      )
   
    setWebinair(webinarsFilter)
      
    }
  }
  /***********Begin Import **********/
  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        results.data[0].ref = results.data[0].ref + "x2";
       
        AxioServices.create("webinars/import", JSON.stringify(results.data), {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*",
            
          },
          
        })
          .then((result) => {
            
            displayToast("Csuccess", "Importation en cours, Redirection...");
        
            setTimeout(() => {
              history.go(0);
             }, 5000);
          })
          .catch((err) => {
            displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
          });
      },
    });
  };
  /*****************End import */
  function duplicate(id_web){
    axios
    .post(`${uri}/webinars/duplicate/`+ id_web)
    .then((response) => {

    
      
      setTimeout(() => {
              history.go(0);
             }, 500);
         
    })
    .catch((error) => {
      // displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
    });
  }
  function deleteWebinare(id_web) {

   
  
    axios
      
      .delete(uri + "/webinars/" + id_web)
      .then((response) => {
      
        setShow(false);
        
      })
      .catch((error) => {
        // displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
  }

  const columns = [
    {
      dataField: "title",
      text: "Webinaire",
      csvText: "webinar",

    },
    {
      dataField: "audience",
      text: "audience",
      csvText: "audience",
      hidden: true

    },
    {
      dataField: "capacity_max",
      text: "capacity_max",
      csvText: "capacity_max",
      hidden: true,

    },
    {
      dataField: "capacity_min",
      text: "capacity_min",
      csvText: "capacity_min",
      hidden: true,

    },
    {
      dataField: "cost",
      text: "cost",
      csvText: "cout",
      hidden: true,

    },
    {
      dataField: "duration",
      text: "duration",
      csvText: "durée",
      hidden: true,

    },
    
    {
      dataField: "forward_catalog",
      text: "forward_catalog",
      csvText: "forward_catalog",
      hidden: true,

    },
    {
      dataField: "manager_spec",
      text: "manager_spec",
      csvText: "manager_spec",
      hidden: true,

    },
    {
      dataField: "obligation",
      text: "obligation",
      csvText: "obligation",
      hidden: true,

    },
    {
      dataField: "programFile",
      text: "programFile",
      csvText: "programFile",
      hidden: true,

    },
    {
      dataField: "ref",
      text: "ref",
      csvText: "ref",
      hidden: true,

    },
    {
      dataField: "status",
      text: "status",
      csvText: "status",
      hidden: true,

    },
    {
      dataField: "objects",
      text: "objects",
      csvText: "objectif",
      hidden: true,

    },
    {
      dataField: "theme.name",
      text: "theme",
      csvText: "theme",
      hidden: true,

    },
    {
      dataField: "editor.title",
      text: "editor",
      csvText: "editor title",
      hidden: true,

    },
    {
      dataField: "provider.name",
      text: "provider",
      csvText: "provider ",
      hidden: true,

    },
    {
      dataField: "training_program",
      text: "training_program",
      csvText: "training_program ",
      hidden: true,

    },
   
    {
      dataField: "date",
      text: "Date",
      csvText: "date",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>{moment(row?.date).format("YYYY/MM/DD")}</>
        );

      },
    },
    {
      dataField: "hour",
      text: "Heure",
      csvText: "heure",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>{row?.hour} </>
          // {moment(row?.hour).format("kk:mm:ss")}
          
        );

      },
    },
    {
      dataField: "former",
      text: "Formateur",
      csvExport: false,
      sort: true,
     
       formatter: (cell, row) => {
        
       
         return (
         
          //  <> {row?.former?.lastName +" "+ row?.former?.firstName}</>
           <> {row?.former.map(el=>el?.lastName + ' '+el?.firstName).join(' - ')}</>
           
         );
         
       },
       
    },


    {
      dataField: "",
      text: "",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="btn-group" role="group" aria-label="Basic example">
          <a
            href={"/update-webinar/" + row.id}
            type="button"
            className="btn btn-link"
            stopPropagation
          >
            <img src={require("../../assets/icons/edit.png")} />
          </a>

          <button
            type="button"
            className="btn"
            onClick={() => {
              setId(row.id);
              handleShow();
            }}
            stopPropagation
          >
            <img src={require("../../assets/icons/delete.png")} />
          </button>

          <button
            type="button"
            className="btn"
            stopPropagation
            onClick={() => {
              duplicate(row?.id);
            }}
          >
            <img src={require("../../assets/icons/duplicate.png")} />
          </button>
        </div>
          
        );
      },
    },
  ];
  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push("/detailWebinar/" + row.id);
    },
  }
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    // hideSelectAll: true,
  };
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
};
    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };
 

  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={webinair}
        columns={columns}
        exportCSV={{ onlyExportSelection: true, exportAll: false , fileName: 'Webinaires.csv'}}
        search
      >
        {(props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter"> <ToastContainer />
            <div className="row">
              <div className="col-xl-4 col-lg-1"></div>
              <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                <div className="flexgy">
                  <div className="col-md-4  mr-1  px-0">
                    <div className="input-group">
                      <input
                        className="form-control height43"
                        type="search"
                        placeholder="Recherche"
                        onChange={(e=>props.searchProps.onSearch(e.target.value))}
                      />
                     
                    </div>
                  </div>
                  <div className="px-0 myxs">
                    <div className="flex">
                      <div className="square">
                        <button
                          type="button"
                          className="btn btn-link"
                           onClick={handleShowfiltre}
                        >
                          <img src={require("../../assets/icons/filter.png")} />
                        </button>
                      </div>

                      <PopupFiltreWebinaire
                      showfiltre={showfiltre} closefiltre={handleClosefiltre} searchwithfiltre={searchwithfiltre} filterEntry={eachEntry}
                      />
                      {/* <div className="square">
                        <button
                          type="button"
                          className="btn btn-link"
                       
                        >
                          <img src={require("../../assets/icons/download.png")} />
                        </button>
                      </div> */}
                      <div className="square">
                          <button className="btn btn-link" htmlFor="export" onClick={() => props.csvProps.onExport()}>
                            <img
                              src={require("../../assets/icons/Export.png")}
                            />
                          </button>
                        </div>
                        <div className="square">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept={".csv"}
                            className="btn btn-link"
                            onChange={handleOnChange}
                            hidden
                          />
                          <label for="file" type="button">
                            <img
                              src={require("../../assets/icons/Import.png")}
                            />
                          </label>
                        </div>
                      
                    </div>
                  </div>
                  <div className="px10">
                    <div className="d-grid gap-2">
                      <a
                        href="/createWebinair"
                        className="btn btn-primary btn-gy height43"
                        type="button"
                      >
                        Créer un Webinaire
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <BootstrapTable
              {...props.baseProps}
              classes="table-dark mt-5"
              bordered={false}
              hover
              selectRow={selectRow}
              pagination={paginationFactory(options)}
              rowEvents={tableRowEvents}
            />
          </div>
        )}
      </ToolkitProvider>

      <Modal show={show} onHide={handleClose} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            Etes-vous sûr(e) de vouloir supprimer{" "}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={handleClose} style={{ color: "#006FFF" }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-primary"
           onClick={(e) => deleteWebinare(id)}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
