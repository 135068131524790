import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import Hn from "../../../../../../components/common/Hn";
import ParticipantDatatable from "./participantDatatable";
function Participant({ participants, getUnitySession ,setParticipant, type = "session"}) {
  
  const params = useParams();

  return (
    <div className="container-fluid">
      <div className="row">
        <div className=" container lists pe-4">
         
          <div className="pt-5">
            <ParticipantDatatable
              type={type}
              getUnitySession={getUnitySession}
              participant={participants}
              id_session={params.id}
              setParticipant={setParticipant}
            />
          </div>
          
        </div>
      </div>
    </div>
  );
}
export default Participant;
