import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Hn from "../common/Hn";
import "./popup.css";
export default function PopupFiltrecompetence({ showfiltre, closefiltre , searchwithfiltre ,filterEntry }) {
  const initialInputState = {
    ref: filterEntry?.ref ??"",
    name: filterEntry?.name ??"",
  };
  const [eachEntry, setEachEntry] = useState(initialInputState);
  const { ref, name } = eachEntry;

  function getvalueinput(e) {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  }
  const resetForm = () => { 
    document.getElementById("filterForm").reset();
    setEachEntry({ name:"", ref:""})
  }
  useEffect(() => {
    setEachEntry(initialInputState);
  },  [showfiltre, closefiltre]);

  return (
    <Modal show={showfiltre} onHide={closefiltre} size="lg" className="filter">
      <Modal.Header closeButton>
        <Modal.Title className="px-3">
          <Hn title="Filtrer" level="h4" plus={false} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form id="filterForm"> 
        <div className="row px-3">
          <div className="col-md-6">
            <Hn
              title="Référence"
              level="h5"
              plus={false}
              className="label-popup"
            />
            <input
              type="text"
              className="form-control drop"
              onChange={getvalueinput}
              name="ref"
              value={eachEntry.ref}
            />
          </div>
          <div className="col-md-6">
            <Hn
              title="Nom"
              level="h5"
              plus={false}
              className="label-popup"
            />
            <input
              type="text"
              className="form-control drop"
              onChange={getvalueinput}
              name="name"
            />
          </div>
        </div>
        </form>
        <br></br>
      </Modal.Body>
      <Modal.Footer> 
            <div>
                <button className="btn btn-link resetBtn" onClick={closefiltre} style={{ color: "#006FFF" }}>
          Annuler
        </button></div>
        <div className="flex">  <button className="secondaryModal"
                    onClick={() => {
                      resetForm()
                    }
                    }
                    disabled={eachEntry.name===""&&eachEntry.ref===""
                    ?"disabled":""}
                    >
                    Réinitialiser
                </button>
        <Button
          variant="primary"
          onClick={() => {
           
            closefiltre();
            searchwithfiltre({ ref, name });
          }}
        >
          Valider
        </Button></div>
          
               
            </Modal.Footer>
     
    </Modal>
  );
}
