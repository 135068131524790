import { ErrorMessage, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { createBrowserHistory } from 'history';

import axios from "axios";
import Add from "../../../../../assets/icons/add.png";
import Hn from "../../../../../components/common/Hn";
import "./createFaceTraining.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const validationSchema = Yup.object().shape({
  reference: Yup.string().required("Champ obligatoire."),
  intitule: Yup.string().required(),
  image: Yup.string().nullable(),
  theme: Yup.string(),
 // associe: Yup.string().required(),
  duree: Yup.number().required(),

});
function UpdateFaceTraining() {
  const fileRef = useRef();
  const pdfRef = useRef();
  const [competence, setCompetence]=useState([]);
  const [providers, setProviders] = useState([]);
  const [trainingDetails, setTrainingDetails] = useState([]);
  const imageValue = Yup.ref("image");
  const [localState, setLocalstate] = useState("initial");
  const [themes, setThemes] = useState([]);
  let toastId = null;

  const [trainings, setTrainings]=useState([]);
  const [elearnings, setElearnings]=useState([]);

  //récupérer les formations présentielles par id
  function getTrainingById(id) {
    const uri = process.env.REACT_APP_BASE_URL;
  
    let headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axios
      .get(uri + "/training/" + id, headers)
      .then((response) => {
       
        setTrainingDetails(response.data);
        
      })
      .catch((error) => {
        // TODO 404 page
        console.log(error.response.data);

        return error.response;
      });
  }
  const uri = process.env.REACT_APP_BASE_URL;
  const uripresent = process.env.REACT_APP_PIC_FACETRAINING_PATH;
  const docpresent = process.env.REACT_APP_PIC_FACETRAINING_FILE;
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      console.log("Toast already active");
    }
  }

  //récupérer les compétences
  function getcompetence() {
  
    axios
      .get(uri + "/skills")
      .then((result) => { 
       
        setCompetence(result?.data);
       
      })
      .catch((err) => {
        console.log("err skills", err);
      });
  }

  //modifier une formation présentielle
  const handleSubmit = (values) => {


    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    
    var data = new FormData();
    let url = null;

   
    data.append("ref", values.reference);
    data.append("title", values.intitule);
    data.append("duration", values.duree);
    data.append("assoc_cours", values.associe);
    data.append("theme", values.theme);

    data.append("objective", values.object);
    data.append("training_program", values.program);
    data.append("audience", values.prerequis);
    data.append('manager_spec',values.manager?1:0);
    data.append('forward_catalog',values.miseavant?1:0);
    data.append('obligation',values.obligation?1:0);
    data.append("status", values.etat ==="1"?1:0);
    data.append("comment", values.commentaire);
   data.append("imagefile", values.image);
   data.append("brochure", values.pdf);
 
    data.append("cost", values.tarif);
    data.append("provider", values.prestataire);
    data.append("skills", values.skills);
  //   data.append("type_training", "presentielle");
  //   data.append("id", params.id);
   
    url = uri + "/training/edit/" + params.id;
    let history = createBrowserHistory({ forceRefresh: true });
  
    axios
      .post(url, data, headersFiles)
      .then((result) => {
     
        displayToast("Csuccess", "Modification effectuer avec success...");
        setTimeout(() => {
          history.push("/admin/formation-presentielle");
        }, 3000);
      })
      .catch((err) => {
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
  };
//récupérer tous les thémes
  function getThem() {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axios
      .get(
        uri + "/thematic",
        headersFiles
      )
      .then((result) => {
        setThemes(result?.data);
       
      })
      .catch((err) => {
        console.log("err theme", err);
      });
  }

  function getProviders() {
    axios
      .get(uri + "/provider")
      .then((result) => {
        setProviders(result?.data);
       
      })
      .catch((err) => {
        console.log("err prestataire", err);
      });
  }
  function getTrainings() {
    
    axios.get(uri + "/training")
      .then((result) => {
        setTrainings(result?.data);
      })
      .catch((err) => {
        console.log("err trainning", err);
      });
  }
  function getElearnings() {
    
    axios.get(uri + "/elearning")
      .then((result) => {
        setElearnings(result?.data);
      })
      .catch((err) => {
        console.log("err elearning", err);
      });
  }
 
  const params = useParams();
  useEffect(() => {
    getcompetence()
    getTrainingById(params.id);
    getThem();
    getProviders();
    getElearnings();
    getTrainings();
  }, []);

  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/formation-presentielle">Formations présentielles - Actions de formation</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                modifier une action de formation
              </li>
            </ol>
          </nav>
          <div className="h-100 p-5 text-white bg-dark rounded-3">
            <Hn
              title="modifier une action de formation"
              level="h4"
              className="h4-size"
              plus={false}
            />
            <Hn
              title="Informations générales"
              level="h5"
              className="py-2 fw-bold"
              plus={false}
            />

            <div className="form-elements">
              <Formik
                initialValues={{
                  reference: trainingDetails?.ref,
                  intitule: trainingDetails?.title ? trainingDetails?.title:"",
                  image: trainingDetails?.image ,
                  theme: trainingDetails?.theme?.name,
                  associe: trainingDetails?.assoc_cours ? trainingDetails?.assoc_cours:"",
                  prerequis: trainingDetails?.audience ?trainingDetails?.audience :"",
                  object: trainingDetails?.objective ? trainingDetails?.objective:"",
                  program: trainingDetails?.training_program ?trainingDetails?.training_program :"",
                  program_file: trainingDetails?.program_file,
                  prestataire: "",
                  duree: trainingDetails?.duration,
                  tarif: trainingDetails?.cost,
                  etat: trainingDetails?.status,
                  commentaire: trainingDetails?.comment ? trainingDetails?.comment:"",
                  skills:'',
                  miseavant: trainingDetails?.forward_catalog,
                  manager: trainingDetails?.manager_spec,
                  obligation: trainingDetails?.obligation,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <form className="row g-3" onSubmit={handleSubmit}>
                    <div className="col-md-4">
                      <label className="form-label">Référence*</label>
                      <input
                        type="text"
                        className="form-control"
                        id="ref"
                        name="reference"
                        placeholder="Référence"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.reference || trainingDetails?.ref}
                        disabled
                      />
                      <ErrorMessage name="reference">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-8">
                      <label className="form-label">Intitulé*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="intitule"
                        id="init"
                        placeholder="Intitulé"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.intitule}
                      />
                      <ErrorMessage name="intitule">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">Image*</label>
                      <span
                        className="form-control"
                        onClick={() => fileRef.current.click()}
                      >
                        <img src={uripresent + trainingDetails.image} style={{ width: "50px", height: "30px" }} className="pe-1" />{" "}
                        {fileRef?.current?.value
                          ? fileRef?.current?.value
                          : "Ajouter une image"}
                      </span>

                      <input
                        ref={fileRef}
                        multiple={false}
                        type="file"
                        name="image"
                        onChange={(event) => {
                          setFieldValue("image", event.currentTarget.files[0]);
                        }}
                        hidden
                      />
                      <ErrorMessage name="image">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                      {/* <input className="form-control" type="file" id="image" placeholder="Ajouter une image" /> */}
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Thème*</label>

                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="theme"
                        id="theme"
                        onChange={handleChange}
                      >
                        <option>Sélectionner dans la liste</option>
                        {themes.map((theme, i) => {
                          return (
                            <option
                              selected={
                                theme?.id === trainingDetails?.theme?.id
                              }
                              value={theme.id}
                              key={i}
                            >
                              {theme.name}
                            </option>
                          );
                        })}
                      </select>
                      <ErrorMessage name="theme">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Parcours associé</label>
                     
                       <select
                      
                      className="form-select"
                      name="associe"
                      id="associe"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.associe}
                    >
                      <option value="">Sélectionner dans la liste</option>

                      {trainings.map((training, i) => {
                        return (
                          <option value={training.id} key={i}>{training.title}</option>
                        );
                      })}

                      {elearnings.map((elearning, i) => {
                        return (
                          <option value={elearning.id} key={i}>{elearning.intitule}</option>
                        );
                      })}

                    </select>
                      <ErrorMessage name="associe">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label className="form-label">
                        Pré-requis et public visé
                      </label>
                      <textarea
                        className="form-control"
                        id="pre"
                        rows="3"
                        name="prerequis"
                        placeholder="Détailler"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.prerequis}
                      ></textarea>
                      <ErrorMessage name="prerequis">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label className="form-label">Objectifs</label>
                      <textarea
                        className="form-control"
                        id="object"
                        rows="3"
                        name="object"
                        placeholder="Description des objectifs"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.object}
                      ></textarea>
                      <ErrorMessage name="object">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label className="form-label">
                        Programme de formation
                      </label>
                      <textarea
                        className="form-control"
                        id="pre"
                        rows="3"
                        name="program"
                        placeholder="Description du programme"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values.program
                        }
                      ></textarea>
                      <ErrorMessage name="program">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">
                        Programme de formation
                      </label>
                      <span
                        className="form-control"
                        onClick={() => pdfRef.current.click()}
                      >
                        {docpresent + trainingDetails?.programFile ?
                        <a href={docpresent + trainingDetails?.programFile} 
                        className="pe-1" 
                        target="_blank">Télécharger</a> :""}
                        {" "}
                        {pdfRef?.current?.value
                          ? pdfRef?.current?.value
                          : "Ajouter un pdf"}
                      </span>

                      <input
                        ref={pdfRef}
                        multiple={false}
                        type="file"
                        name="pdf"
                        onChange={(event) => {
                          setFieldValue("pdf", event.currentTarget.files[0]);
                        }}
                        hidden
                      />
                      <ErrorMessage name="pdf">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Prestataire</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="prestataire"
                        id="prestat"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.prestataire.name}
                      >
                        <option>Sélectionner dans la liste</option>
                        {providers.map((provider, i) => {
                          return (
                            <option
                              value={trainingDetails?.provider?.id}
                              selected={
                                provider?.id === trainingDetails?.provider?.id
                              }
                              key={i}
                            >
                              {provider?.contact_surname}{" "}
                              {provider?.contact_name}
                            </option>
                          );
                        })}
                      </select>
                      <ErrorMessage name="prestataire">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        Durée théorique (en heure)*
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="duree"
                        id="duree"
                        placeholder="00:00"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.duree}
                      />
                      <ErrorMessage name="duree">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">Tarif</label>
                      <input
                        type="number"
                        step="0.5"
                        className="form-control cost"
                        name="tarif"
                        id="tarif"
                        placeholder="00"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={trainingDetails.cost}
                        value={values.tarif}
                      />
                      <ErrorMessage name="tarif">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Actif</label>
                     
                      <select
                        className="form-select"
                        name="etat"
                        id="etat"
                        aria-label="Default select example"
                        // defaultValue={values.etat?"1":"0"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // value={values.etat?"1":"0"}
                      >
                        <option value={1} selected={values.etat == '1'}>
                          Oui
                        </option>
                        <option value={0} selected={values.etat == '0'}>Non</option>
                 </select>
                      <ErrorMessage name="etat">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label className="form-label">Commentaire</label>
                      <textarea
                        className="form-control"
                        name="commentaire"
                        id="comment"
                        rows="3"
                        placeholder="Ajouter un commentaire"
                        onChange={handleChange}
                        onBlur={handleBlur}              
                        value={values.commentaire}
                      ></textarea>
                      <ErrorMessage name="commentaire">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-4">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          name="miseavant"
                          type="checkbox"
                          id="check"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={
                            values.miseavant
                          }
                          checked={
                            values.miseavant ? "checked" : ""
                          }
                        />
                        <label className="form-check-label">
                          Mettre en avant dans le catalogue
                        </label>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          name="manager"
                          type="checkbox"
                          id="check2"
                          // onChange={handleChange}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.manager}
                          checked={values.manager ? "checked" : ""}
                        />
                        <label className="form-check-label">
                          Spécifique au manager
                        </label>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          name="obligation"
                          type="checkbox"
                          id="check3"
                          onChange={handleChange}
                          value={
                            values.obligation
                          }
                          checked={values.obligation ? "checked" : ""}
                        />
                        <label className="form-check-label">
                          Participe à l'obligation de formation
                        </label>
                      </div>
                    </div>
                    <Hn
                      title="Compétences associées"
                      level="h5"
                      className="py-2 fw-bold"
                      plus={false}
                    />
                        <div className="col-md-6">
                      <label className="form-label">Référentiel de compétences</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="skills"
                        id="skills"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      
                      >
                        <option>Sélectionner dans la liste</option>
                        {competence.map((comp, i) => {
                          return (
                            <option
                              value={comp.id}
                              selected={
                                comp?.id === trainingDetails?.skills?.id
                              }
                              key={i}
                            >
                              {comp?.title}
                             
                            </option>
                          );
                        })}
                      </select>
                      <ErrorMessage name="skills">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-12 mt-5">
                      <a
                        href="/admin/formation-presentielle"
                        type="reset"
                        className="btn btn-link text-decoration-none resetBtn"
                      // onClick={() => setLocalstate("belgacem")}
                      >
                        Annuler
                      </a>
                      <button
                        type="submit"
                        className="btn btn-primary float-end"
                      // disabled={isSubmitting}
                      >
                        Suivant
                      </button>
                      {/* <a href="/admin/create-session-formation-presentielle" className="btn btn-primary float-end">
                                                Suivant
                                            </a> */}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* lists */}
      </div>
    </div>
  );
}

export default UpdateFaceTraining;
