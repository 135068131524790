import axios from "axios";
import { useState , useEffect , useRef} from "react";
import { useHistory, useParams } from "react-router-dom";
import Hn from "../../components/common/Hn";
// import webinarsParticipant from "./webinarsParticipant";
import "../admin/forms/formationelearning/elearningForm.css";
import React from "react";
import "./webinar.css";
import JoditEditor from "jodit-react";
import { Editor } from "@tinymce/tinymce-react";
import Participant from "../admin/forms/face-training/crud/show-sessions-participants/participantSession";

function MyEditor() {
  const [content, setContent] = useState("")
  const editor = useRef(null)
 
  const [contentVisible, setContentVisible] = useState(true)

  function handleChange(content, editor) {
    setContent(content)
  }
  
  function handleSubmit(event) {
    setContentVisible(true)
    event.preventDefault();
  }


  return (
    <form onSubmit={handleSubmit}>
      <div className=" editor col-md-12">

        <button className="squarevisulaisation btn btn-link float-end" onClick={() => setContentVisible(false)}>
          <img src={require("../../assets/icons/Visualisation.png")} />
        </button>


        <button className=" btn btn-link float-end" onClick={() => setContentVisible(true)} >

          <img src={require("../../assets/icons/CTAIcon.png")} />
        </button>

      </div>

      <div className=" col-md-12 pb-3">
        <Editor
          apiKey="qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"

          value={content}
          init={{
            height: 200,
            content_style: "body { background-color: #151C25; color: #fff; }",
            menubar: false
          }}
          onEditorChange={handleChange}
        />
      </div>
      {contentVisible ? (<textarea

        type="text"
        className="area form-control"
        id="titre"
        name="titre"
        placeholder=""
        rows="10" cols="50"
        value={content}

      />) : ''}{contentVisible}

    </form>
  );
}


function DetailWebinar() {
  const [content, setContent] = useState("")
  const editor = useRef(null)
  const config = {
    // all options from https://xdsoft.net/jodit/doc/,
    readonly: false,
    placeholder: '...',
    theme: 'dark',
    hidePoweredByJodit: true,
    height: 400,
    defaultActionOnPaste: "INSERT_AS_HTML",
    processPasteHTML: true,

    editHTMLDocumentMode:true

  }

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const [show, setShow] = useState(false);
  const [webinarOne, setWebinarOne]=useState([]);
  const [participants, setParticipants] = useState([]);
  const params = useParams();
  const [session, setSession] = useState([]);
  const [webinars, setWebinars] = useState([]);
  let history = useHistory();
  
  const uri = process.env.REACT_APP_BASE_URL;

  //récupérer un webinaire choisi par son id
  function getWebinarById() {
    
    axios.get(uri + `/webinars/${params.id}`)
      .then((result) => {
        setWebinarOne(result?.data);
      
      })
      .catch((err) => {
        console.log("err webinars", err);
      });
  }
//récupérer toute la liste des participants dans le webinaire récupéré par son id 
  function getUnityProvider() {
    axios
      
      .get(uri + `/participant/${params.id}/webinars/` , headers)
      .then((result) => {
        setWebinars(result?.data);
        
      })
      .catch((err) => {
        console.log("err ", err);
      });
  }
  useEffect(() => {
    getWebinarById();
    getUnityProvider();
  }, []);

  
  return (
    <div className="container-fluid">
      <div className="row pe-4">
        <div className="container lists">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/webinair">Webinars</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {webinarOne?.title}
              </li>
            </ol>
            <a href="/webinair" className="btn btn-link resetBtn">Retour</a>
            <br></br>
            <div className="row">
              <div className="col-md-6"><Hn
                title={webinarOne?.title}
                level="h4"
                className="h4-size pb-5"
                plus={true}
              />
              </div>
              <div className="col-md-6">
                <a
                  href={"/update-webinar/" + webinarOne?.id}
                  className="btn btn-primary float-end mt-4"
                  type="button"
                >
                  Modifier
                </a>
              </div>
              <div className="row">
                
                <div className="col-md-4 col-xs-4 mb-3">
                  <div className="cardSession">
                    <h5 className="card-title title16">Participants inscrits</h5>
                    <p className="card-text title23">
                    {webinarOne.contacts?.length}
                    </p>
                  </div>
                </div>
              </div>

            </div>

          </nav>
        </div>
        <div className="" >
          <div className=" p-5 text-white bg-dark rounded-3">
            <Hn
              title="Informations générales"
              level="h4"
              className="h4-size pb-5"
              plus={false}
            />
            <div className="form-elements">
              <form className="row g-3">
                <div className="col-md-4">
                  <label htmlFor="ref" className="form-label">
                    Référence
                  </label>
                  <p> {webinarOne?.ref}</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="nom" className="form-label">
                    Intitulé
                  </label>
                  <p>{webinarOne?.title}</p>
                </div>
                <div className="col-md-4"></div>

                <div className="col-md-4">
                  <label htmlFor="denomi" className="form-label">
                    Thème
                  </label>
                  <p>{webinarOne?.theme?.name}</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="adre" className="form-label">
                    Date
                  </label>
                  <p>{webinarOne?.date}</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="codepos" className="form-label">
                    Heure
                  </label>
                  <p>{webinarOne?.hour}</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="ref" className="form-label">
                    Formateur
                  </label>
                  { webinarOne?.former && webinarOne?.former.map((item, i) => 
                    <p key={i}> {item?.organize}</p>
                  )}
                </div>
                <div className="col-md-4">
                  <label htmlFor="nom" className="form-label">

                  </label>
                  { webinarOne?.former && webinarOne?.former.map((item, i) => 
                    <p key={i}>{item?.lastName} {item?.firstName}</p>
                  )}
                  {/* <p>{webinarOne?.former?.lastName +" "+ webinarOne?.former?.firstName}</p> */}
                </div>
                <div className="col-md-12">
                  <label htmlFor="ville" className="form-label">
                    Pré-requis et public visé
                  </label>
                  <p>
                  {webinarOne?.audience}
                  </p>
                </div>
                <div className="col-md-12">
                  <label htmlFor="pays" className="form-label">
                    Objectifs
                  </label>
                  <p>
                  {webinarOne?.objects}
                  </p>
                </div>
                <div className="col-12">
                  <label htmlFor="comm" className="form-label">
                    Programme de formation
                  </label>
                  <p>
                  {webinarOne?.training_program}
                  </p>
                </div>

                <div className="col-md-4">
                  <label htmlFor="coutj" className="form-label">
                    Programme de formation
                  </label>
                  <p>{webinarOne?.programFile}</p>
                </div>

                <div className="col-md-4">
                  <label htmlFor="interloc" className="form-label">
                    Prestataire
                  </label>

                  <p>{webinarOne?.provider?.contact_name} {webinarOne?.provider?.contact_surname }</p>
                 
              
                  
                </div>
                <div className="col-md-4">
                  <label htmlFor="phone" className="form-label">
                    Durée théorique (en heures)*
                  </label>
                  <p>{webinarOne?.duration}</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="coutj" className="form-label">
                    Capacité d'accueil minimale
                  </label>
                  <p>{webinarOne?.capacity_min}</p>
                </div>

                <div className="col-md-4">
                  <label htmlFor="interloc" className="form-label">
                  Capacité d'accueil maximale
                  </label>
                  <p>{webinarOne?.capacity_max}</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="phone" className="form-label">
                    Tarif
                  </label>
                  <p>{webinarOne?.cost} €</p>
                </div>
                <div className="col-md-12">
                  <label htmlFor="email" className="form-label">
                    Actif
                  </label>
                  <p>{webinarOne?.status ? "Oui":"Non"}</p>
                </div>
                <div className="col-md-12">
                  <label htmlFor="email" className="form-label">
                    commentaire
                  </label>
                  <p>{webinarOne?.comment}</p>
                </div>

                <div className="col-md-4">
                  <label htmlFor="email" className="form-label">
                    Mise en avant dans le catalogue
                  </label>
                  <p>{webinarOne?.forward_catalog ? "Oui":"Non"}</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="email" className="form-label">
                    Spécifique au manager
                  </label>
                  <p>{webinarOne?.manager ? "Oui":"Non"}</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="email" className="form-label">
                    Participe à l'obligation de formation
                  </label>
                  <p>{webinarOne?.obligation ? "Oui":"Non"}</p>
                </div>


              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="container lists p-3 pb-0">
          <div className=" p-5 text-white bg-dark rounded-3">

            <div className="row">
              <div className="col-md-6">
                <Hn
                  title="Editeur"
                  level="h4"
                  className="h4-size pb-5"
                  plus={false}
                />
              </div>
              <div className="col-md-6 ">
                <img className="float-end"
                  src={require("../../assets/icons/edit.png")} />
              </div>
            </div>
            <div className="col-md-12">
              <label htmlFor="email" className="form-label">
                Intitulé*
              </label>
              <p>{webinarOne?.editor?.title}</p>
            </div>

            <div className=" editor col-md-12 ">
              {/* <MyEditor></MyEditor> */}
              <JoditEditor
                ref={editor}
                value={webinarOne?.editor?.source}
                config={config}
                tabIndex={1} // tabIndex of textarea
               
                // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => { setContent(newContent) }}
              />
            </div>
           {/* <ParticpantDatatable
              participant={webinarOne?.contacts}
              idWeb={params.id}
            ></ParticpantDatatable>  */}


          </div>
          <div className="lists-filter my-5">

          {/* <Participant participants={participants} setParticipant={setParticipants}/> */}
          <Participant participants={webinars} setParticipant={setParticipants} type="webinars"/>

          </div>
        </div>

      </div>
    </div>
  );
}

export default DetailWebinar;
