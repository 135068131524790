import { useEffect, useState } from "react";

import { createBrowserHistory } from "history";
import { NavLink } from "react-router-dom";
import axios from "axios";

import { ReactComponent as Elearning } from "../../assets/icons/Elearning.svg";
import { ReactComponent as Gh } from "../../assets/icons/Gh.svg";
import { ReactComponent as Icon } from "../../assets/icons/Icon.svg";
import { ReactComponent as Vector } from "../../assets/icons/Vector.svg";
import { ReactComponent as Profil } from "../../assets/icons/profil.svg";
import { ReactComponent as Envelope } from "../../assets/icons/envelope.svg";

import "./Sidebar.css";

const SideBar = () => {

  const uri = process.env.REACT_APP_BASE_URL;
  let history = createBrowserHistory({ forceRefresh: true });
  const [notify, setNotify] = useState(0);

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.clear();
    history.push("/login");
  };
// lister toutes les conventions par participant_id
  function getNotification() {
    let nb=[];
       axios
         .get(uri + "/document/convention/"+ localStorage.getItem("ind"))
         .then((result) => {
          setNotify(result?.data.filter((el)=>el.view===false).length);
         })
         .catch((err) => {
           console.log("err  attestations", err);
         });
     
   }
  

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <div>
      <header className="header" id="header">
        <div className="header_toggle">
          {" "}
          <i className="bx bx-menu" id="header-toggle"></i>{" "}
        </div>
      </header>
      <div className="l-navbar" id="nav-bar">
        <nav className="nav">
          <div>
            <a href="/login" className="nav_logo">
             
              <Gh />
            </a>
            <div className="nav_list">
              <NavLink
                to="/Formations-e-learning"
                activeClassName="active"
                className="nav_link"
              >
                <Elearning />
                <span className="nav_name">Formations e-learning</span>
              </NavLink>
              <NavLink
                to="/formation-presentielle"
                activeClassName="active"
                className="nav_link"
              >
                <Icon />
                <span className="nav_name">Catalogue de formations</span>
              </NavLink>

              <NavLink
                to="/search"
                activeClassName="active"
                className="nav_link"
              >
                <Vector />
                <span className="nav_name">Recherche</span>
              </NavLink>

              <NavLink
                to="/profil"
                activeClassName="active"
                className="nav_link"
              >
                <Profil />
                <span className="nav_name">Profil</span>
              </NavLink>
              

              <NavLink
                to="/email"
                activeClassName="active"
                className="nav_link"
              >
                <div type="button" className="position-relative">
                  <Envelope />
                  
                  <span className={`position-absolute top-4 start-100 translate-middle p-1 bg-danger rounded-circle badge ${notify ? "notify" : "" }`}>
                    {notify != 0 ? notify : ''}
                    <span className="visually-hidden">Boite de réception</span>
                  </span>
                </div>
                <span className="nav_name">Mes documents</span>
              </NavLink>
              <div style={{ paddingTop: "100px" }}>
                <NavLink
                  to="/login"
                  activeClassName="active"
                  className="nav_link"
                >
                  <img
                    src={require("../../assets/icons/Deconnexion.svg").default}
                    className="nav_icon"
                    alt="deconnexion"
                  />
                    { localStorage.getItem("role") === "Super-admin" ?
                    <span className="nav_name" onClick={()=>{
                      localStorage.setItem("role2", "");
                      history.push("/admin/dashboard");
                  }}>  
                  Administrateur
                  </span>:
                  <span className="nav_name" onClick={logout}>
                    Déconnexion
                  </span>
                  }
                </NavLink>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default SideBar;