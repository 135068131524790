import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import App from "./App";
import "./index.css";
import * as Loader from "react-loader-spinner";
import { Toaster } from 'react-hot-toast';
import reportWebVitals from './reportWebVitals';
import { createRoot } from "react-dom/client";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div id="spinner">
        <Loader.TailSpin
          type="ThreeDots"
          color="#2BAD60"
          height="100"
          width="100"
        />
      </div>
    )
  );
};
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <>
    <Toaster position="top-right" reverseOrder={false} />
    <App />
    <LoadingIndicator />
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
