import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Hn from "../common/Hn";
import "./popup.css";
export default function PopupFiltre({showfiltre, closefiltre, searchwithfiltre,filterEntry}) {
    const initialInputState = {
        nom: filterEntry?.nom ?? "",
        prenom: filterEntry?.prenom ?? "",
        organize:filterEntry?.organize ?? "",
        tel:filterEntry?.tel ?? "",
        email :filterEntry?.email ?? "",
    };
    const [eachEntry, setEachEntry] = useState(initialInputState);
    const {
        nom,
        prenom,
        organize,
        tel,
        email
      } = eachEntry;

    function getvalueinput(e) {
        setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
    }
    const resetForm = () => {
        document.getElementById("filterForm").reset();
        setEachEntry({ nom:"", prenom:"", organize:"", tel:"", email:"" })
      }
    useEffect(() => {
        setEachEntry(initialInputState);
      },  [showfiltre, closefiltre]);
       
    
    return (
        <Modal show={showfiltre}
            onHide={closefiltre}
            size="lg"
            className="filter">
            <Modal.Header closeButton>
                <Modal.Title className="px-3 nopad">
                    <Hn title="Filtrer" level="h4"
                        plus={false}/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form id="filterForm">
                <div className="row px-3 nopad">
                    <div className="col-md-4">
                        <Hn title="Nom formateur" level="h5"
                            plus={false}
                            className="label-popup"/>
                        <input type="text" className="form-control drop"
                        onChange={getvalueinput} name="nom"  value={eachEntry.nom}/>
                    </div>
                    <div className="col-md-4">
                    <Hn title="Prenom formateur" level="h5"
                            plus={false}
                            className="label-popup"/>
                        <input type="text" className="form-control drop"
                        onChange={getvalueinput}  name="prenom"  value={eachEntry.prenom}/>
                    </div>
                    <div className="col-md-4">
                        <Hn title="Organize" level="h5"
                            plus={false}
                            className="label-popup"/>
                       <select
                className="form-select"
                aria-label="Default select example"
                id="organize"
                value={eachEntry.organize}
                onChange={getvalueinput}
                name="organize"
                
              >
                <option defaultValue>
                  Sélectionner
                </option>
                <option value="interne">Interne</option>
                <option value="externe">Externe</option>
              </select>
                    </div>
                    <div className="col-md-4 mt-3">
                        <Hn title="Téléphone" level="h5"
                            plus={false}
                            className="label-popup"/>
                        <input type="tel" className="form-control drop"
                        onChange={getvalueinput} name="tel"  value={eachEntry.tel}/>
                    </div>
                    <div className="col-md-4 mt-3">
                        <Hn title="Email" level="h5"
                            plus={false}
                            className="label-popup"/>
                        <input type="email" className="form-control drop"
                        onChange={getvalueinput} name="email"  value={eachEntry.email}/>
                    </div>
                </div>
                </form>
                {/* /row */}
                <br></br>
                
                {/* /row */} </Modal.Body>
            <Modal.Footer> 
            <div> <button className="btn btn-link resetBtn" onClick={closefiltre} style={{ color: "#006FFF" }}>
          Annuler
        </button></div>
      <div className="flex">  <button className="secondaryModal"
          onClick={() => {
            resetForm()
          }
          }
          disabled={eachEntry.nom === "" && eachEntry.prenom === "" && eachEntry.organize === "" && eachEntry.tel === ""&&eachEntry.email==="" ? "disabled" : ""}
        >
          Réinitialiser
        </button>
        <Button
          variant="primary"
          onClick={() => {
            closefiltre()
            searchwithfiltre({nom, prenom,organize,tel,email});
          }}
        >
          Valider
        </Button></div>
               
            </Modal.Footer>
        </Modal>
    );
}