import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Hn from "../common/Hn";
import axios from "axios";
import Select from 'react-select'
import "./popup.css";
export default function PopupFiltre({ showfiltre, closefiltre, searchwithfiltre, filterEntry }) {
  const initialInputState = {
    collaborator: filterEntry?.collaborator ?? "",
    offre: filterEntry?.offre ?? "",
    agent: filterEntry?.agent ?? "",
    agence: filterEntry?.agence ?? "",


  };
  const uri = process.env.REACT_APP_BASE_URL;
  const [eachEntry, setEachEntry] = useState(initialInputState);
  const [listAgence, setListAgence] = useState([]);
  const [agence, setAgence] = useState('');
  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontSize: "16px",
      fontWeight: state.isSelected ? "600" : "400",
      backgroundColor: "#3A4048",
      color: "white",
      border: "1px solid #006FFF",
      borderRadius: "none",
    }),
    dropdownIndicator: (base) => ({
      color: "#006FFF",
      marginRight: "10px",
    }),
    input: () => ({
      color: "white"
    }),
    menu: (base) => ({
      backgroundColor: "#212730",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#1E2E45" : "#212730",
      fontWeight: state.isSelected ? "600" : "400",
      borderBottom: "1px solid #3A4048",
      color: "white",
      margin: "5px 14px 5px 10px",
    }),
    placeholder: () => ({
      color: 'white',

    }),
    singleValue: () => ({
      color: "white",
    }),
  };
  const resetForm = () => {
    document.getElementById("filterForm").reset();
    setEachEntry({ collaborator: "", agent: "", offre: "" })
    setAgence('')
  }
  function getAgency() {
    axios
      .get(`${uri}/agence`)
      .then((response) => {
        // setListAgence(response?.data)
        response?.data.map((agence) => {
          setListAgence((listAgence) => [
            ...listAgence,
            { value: agence.AgenceID, label: agence.agencymain || agence.agenceImmobilier },
          ]);
        });
      })
      .catch((error) => {
        console.log(error, "Quelque chose s'est mal passé, Réessayer");
      });
  }

  const {
    collaborator,
    offre,
    agent,


  } = eachEntry;

  function getvalueinput(e) {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  }
  useEffect(() => {
    getAgency()
    setEachEntry(initialInputState);
  }, [showfiltre, closefiltre]);


  return (
    <Modal show={showfiltre}
      onHide={closefiltre}
      size="lg"
      className="filter">
      <Modal.Header closeButton>
        <Modal.Title className="px-3 nopad">
          <Hn title="Filtrer" level="h4"
            plus={false} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="filterForm">
          <div className="row px-3 nopad">
            <div className="col-md-6">
              <Hn title="Collaborateur" level="h5"
                plus={false}
                className="label-popup" />
              <input type="text" className="form-control drop"
                onChange={getvalueinput} name="collaborator" value={eachEntry.collaborator} />
            </div>

            <div className="col-md-6 ">
              <Hn title="Agent" level="h5"
                plus={false}
                className="label-popup" />
              <input type="text" className="form-control drop"
                onChange={getvalueinput} name="agent" value={eachEntry.agent} />
            </div>
            <div className="col-md-6 mt-3">
              <Hn title="Offre" level="h5"
                plus={false}
                className="label-popup" />

              <select className="form-control drop"
                onChange={getvalueinput} name="offre" value={eachEntry.offre}>
                <option >Sélectionner</option>
                <option value="oui">Oui</option>
                <option value="non">Non</option>
              </select>
            </div>
            <div className="col-md-6 mt-3">
              <Hn title="Agence" level="h5"
                plus={false}
                className="label-popup" />
                <Select options={listAgence.filter((item, index) => listAgence.item === item.name)} styles={customStyles} placeholder={"Sélectionner...."} onChange={(value) => setAgence(value.value)} />
            </div>
          </div>
        </form>
        <br></br>
      </Modal.Body>
      <Modal.Footer>
        <div> <button className="btn btn-link resetBtn" onClick={closefiltre} style={{ color: "#006FFF" }}>
          Annuler
        </button></div>
        <div className="flex">  <button className="secondaryModal"
          onClick={() => {
            resetForm()
          }
          }
          disabled={eachEntry.collaborator === "" && eachEntry.offre === "" && eachEntry.agent === "" && eachEntry.agence === "" ? "disabled" : ""}
        >
          Réinitialiser
        </button>
          <Button
            variant="primary"
            onClick={() => {

              closefiltre();
              searchwithfiltre({ collaborator, offre, agent, agence });
            }}
          >
            Valider
          </Button></div>
      </Modal.Footer>
    </Modal>
  );
}