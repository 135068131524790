import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useHistory } from "react-router-dom";
import { date, number, object, ref, string } from "yup";
import Hn from "../../../../components/common/Hn";
import Icon from "../../../../components/common/Icon";
import Add from "../../../../assets/icons/add.png";
import "./createFaceTraining.css";
const uri = process.env.REACT_APP_BASE_URL;
let headers = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};
var dte = new Date();

const validationSchema = object().shape({
  ref: string().required("champ obligatoire"),
  dateStart: date().default(() => new Date()),
  dateEnd: date().default(() => new Date()),
  location_id: string().required("champ obligatoire"),
  // seat_capacity_min: number().required("champ obligatoire"),
  seat_capacity_min: number()
    .max(
      ref("seat_capacity_max"),
      "Must be less than Capacité d'acccueil maxinale"
    )
    .required("champ obligatoire"),
  seat_capacity_max: number()
    .min(
      ref("seat_capacity_min"),
      "Must be more than Capacité d'acccueil minimale  "
    )
    .required("champ obligatoire"),
  former: string().required("champ obligatoire"),
});

function CreateFaceSessionElearning2() {
  const fileRef = useRef();
  const [modalShow, setModalShow] = useState(false);
  const [lieuFormation, setLieuForamtion] = useState([]);
  const [formateurs, setForamteurs] = useState([]);
  const [sessions, setSessions] = useState([]);
  const onHandelStatus = (touched, error, value) => {
    if (error && touched) {
      return "danger";
    } else if (value) {
      return "info";
    } else {
      return "warning";
    }
  };

  function getformer(type) {
    axios
      .get(uri+"/former")
      .then((result) => {
        setForamteurs(result?.data);
      })
      .catch((err) => {
        console.log("err formateurs", err);
      });
  }
  function getSessions() {
    axios
      .get(uri+"/session")
      .then((result) => {
        setSessions(result?.data);
        
      })
      .catch((err) => {
        console.log("err sessions", err);
      });
  }
  function ChangeList(value) {
    getformer(value);
  }
  useEffect(() => {
    getSessions();
    getformer("");
    axios
      .get(uri+"/location")
      .then((result) => {
        setLieuForamtion(result?.data);
      })
      .catch((err) => {
        console.log("err location", err);
      });
  }, []);

  
  let history = useHistory();
  const handleSubmit = (values) => {
    axios
      .post(`${uri}/session/new`, values, headers)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Formations présentielles - Actions de formation</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Créer une action de formation
              </li>
            </ol>
          </nav>
          <div className="h-100 p-5 text-white bg-dark rounded-3">
            <Hn
              title="Créer une action de formation"
              level="h4"
              className="h4-size"
              plus={false}
            />
            <Hn
              title="Etape 2 : Ajout de contenu"
              level="p"
              className="py-2 fw-bold"
              plus={false}
            />
            <div className="form-elements mt-2">
              <div className="form-aria p-4">
              <Accordion>
                <Accordion.Item>
                  <Accordion.Header>
                    <span className="header-text">
                     Déontologie
                    </span>
                    <br />
                    <br />
                    
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row g-3">
                    
                      
                    </div>

                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

                <Formik
                  initialValues={{
                    titre: "",
                    
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form className="row g-3" onSubmit={handleSubmit}>
                      <Hn
                        title="Création unité de formation"
                        level="p"
                        className="py-2 fw-bold"
                        plus={false}
                      />
                      <div className="col-md-6">
                        <label className="form-label">Titre</label>
                        <input
                          status={onHandelStatus(
                            touched.ref,
                            errors.ref,
                            values.ref
                          )}
                          type="text"
                          className="form-control"
                          id="titre"
                          placeholder="Ajouter un titre"
                          onChange={handleChange("titre")}
                          value={values.titre}
                          onBlur={handleBlur("titre")}
                          errors={errors.titre}
                        />
                        <ErrorMessage name="ref">
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-md-6">
                      <label  className="form-label">
                        Document
                      </label>
                      <span
                        className="form-control"
                        onClick={() => fileRef.current.click()}
                      >
                        
                        <img src={Add} className="pe-1" />  {fileRef?.current?.value ? fileRef?.current?.value : 'Ajouter une image'}
                      </span>

                      <input
                        ref={fileRef}
                        multiple={false}
                        type="file"
                        name="image"
                        onChange
                        hidden
                        accept=".png,.jpg,.gif"
                      />
                      <ErrorMessage name="image">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                      {/* <input className="form-control" type="file" id="image" placeholder="Ajouter une image" /> */}
                    </div>
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6">
                      <p> Format : pdf ,jpg, png /2ko </p>
              
                    </div>
                      <div className="col-md-12 pt-4">
                        <button
                          //href="/admin/create-session-formation-apprendre"
                          type="submit"
                          className="btn btn-outline-primary float-end"
                        >
                          Ajouter
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
              <div className="col-12 mt-5">
                <a
                  href="/admin/create-formation-presentielle"
                  className="btn btn-link resetBtn"
                >
                  Annuler
                </a>
                <a
                  type="submit"
                  className="btn btn-primary float-end"
                  disabled
                  href="/admin/create-session-formation-elearning3"
                >
                  Suivant
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateFaceSessionElearning2;
