import axios from "axios";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import Hn from "../../components/common/Hn";
import PopupFiltrethematique from "../../components/popup/PopupFiltrethematique";
import "../admin/forms/face-training/crud/createFaceTraining.css";
import "../admin/forms/location/ListLocationTraining.css";
import Datatable from "./Datatable";

function Listthematique() {
  const [showfiltre, setShowfiltre] = useState(false);
  const [displayetat, setDisplayetat] = useState("false");
  const [show, setShow] = useState(false);
  const [thematique, setTematique] = useState([]);
  const [errorexist, setErrorexist] = useState("false");
  const [succesdelete, setSuccesdelete] = useState(false);
  const uri = process.env.REACT_APP_BASE_URL;

  //récupérer tous les thématiques
  function getThematique() {
    setTematique([]);
    axios
      .get(uri + "/thematic")
      .then((result) => {
        setTematique(result?.data);
     
      })
      .catch((err) => {
        console.log("err location", err);
      });
  }
  useEffect(() => {
    getThematique();
  }, []);

  //rechercher une thématique
  function findintable(e) {
    setTematique([]);
   
    if (e.target.value) {
      axios
        .get(uri + "/thematic?search=" + e.target.value)
        .then((result) => {
          setTematique(result?.data);
         
        })
        .catch((err) => {
          console.log("err location", err);
        });
    } else {
      getThematique();
    }
  }


  const handleOnChange = (e) => {};
  return (
    <div className="container-fluid">
      <div className="row">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn title="Thématiques" level="h1" className="pt-5" plus={false} />
          </div>
       

          {/* lists-filter */}
          {displayetat === "true" ? (
            <Alert color="primary">Aucun resultat trouvé</Alert>
          ) : (
            ""
          )}
          <div className="mt-20 me-3">
         
              <Datatable
                setShow={setShow}
                show={show}
                thematiques={thematique}
                getThematique={getThematique}
                setTematique={setTematique}
              />
           
          </div>
        </div>
      </div>
    </div>
  );
}
export default Listthematique;
