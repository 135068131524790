import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import { useEffect, useState, useRef, useMemo } from "react";
import { trackPromise } from "react-promise-tracker";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import Add from "../../assets/icons/add.png";
import Hn from "../../components/common/Hn";
import "./webinar.css";
import JoditEditor from "jodit-react";


function CreateWebinair2() {
  const editor = useRef(null)
  const [content, setContent] = useState('')
  const [title, setTitle] = useState('')
  
  const config = {
    // all options from https://xdsoft.net/jodit/doc/,
    readonly: false,
    placeholder: '...',
    theme: 'dark',
    hidePoweredByJodit: true,
    height: 400,
    defaultActionOnPaste: "INSERT_AS_HTML",
    processPasteHTML: true,

    editHTMLDocumentMode:true

  }
 
  const params= useParams();
  const { elearning_id } = useParams();
  const uri = process.env.REACT_APP_BASE_URL;
  
  useEffect(() => {
    
    
  }, []);

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  let dataSend={}
  //créer un nouveau editeur pour un webinaire nouveau crée
const saveEditor=()=>{

  
  dataSend =
  {
    "webinars": params.id,
    "title":title,
    "source":content
  }

  axios
  .post(uri + "/webinars/editor/new", dataSend,headers)
  .then((result) => {
    setTimeout(() => {
      history.push("/create-webinar-etape3/"+ params.id);
      
       }, 1500);
    
  
  })
  .catch((err) => {
    console.log("err edit editor", err);
  })
}
  let history = useHistory();

 

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Webinar</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Créer un webinaire
              </li>
            </ol>
          </nav>
          <div className="h-100 p-5 text-white bg-dark rounded-3">
            <Hn
              title="Créer un webinar"
              level="h4"
              className="h4-size"
              plus={false}
            />
            <div className="row">
              <div className="col-md-6">
                <Hn
                  title="Etape 2 : Editeur"
                  level="p"
                  className="py-2 fw-bold"
                  plus={false}
                />
              </div>
              <div className="col-md-12 my-3">
              <input

                type="text"
                className="form-control"
                id="titre"
                name="titre"
                placeholder="Intitulé"
                value={title}
                onChange={(e)=> {setTitle(e.target.value)}}
              />
              </div>
              {/* begin editeur html */}
              <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1} // tabIndex of textarea
                // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => { setContent(newContent) }}
              />
              {/* end editeur html */}
              <div className="row">
                <div className="col-10 mt-5">
                  <a type="submit" className=" btn-link resetBtn" href={"/update-webinar/"+ params.id} >
                    Retour{" "}
                  </a>
                </div>
              <div className="col-2 mt-5">
                  <button
                  onClick={()=>saveEditor()}
                   // href={"/update-webinar-etape3/"+params.id}
                    className="btn btn-primary float-end"
                  >
                    Suivant
                  </button>
                </div>
                </div>
              
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateWebinair2;
