import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CadreItems from "../../components/common/CadreItems";
import Hn from "../../components/common/Hn";
import "../../components/owl/listformation.css";


import axios from "axios";
import { createBrowserHistory } from "history";
//import { useState } from "react";

const ListMore = () => {
  const IMG_URL = process.env.REACT_APP_PIC_FACETRAINING_PATH;
  const IMG_URL_E = process.env.REACT_APP_PIC_ELEARNING_IMG;
  //const [inactive, setInactive] = useState(false);
  let params = useParams();
  const [training, setTraining] = useState([]);
  const [elearning, setElearning] = useState([]);
  const [encours, setEncours] = useState([]);
  const uri = process.env.REACT_APP_BASE_URL;

  let history = createBrowserHistory({ forceRefresh: true });
//récupérer les formations présentielles par théme
  function getMorePresentiel() {
    axios
      .get(uri + "/training/theme/" + params.theme)
      .then((result) => {
       
        setTraining(...training, result.data);
      })
      .catch((err) => {
        console.log("err theme", err);
      });
  }
//récupérer les formations elearning par théme
  function getElearning() {
    axios
      .get(uri + "/training/elearning/theme/" + params.theme)
      .then((result) => {
       
        setElearning(...training, result.data);
      })
      .catch((err) => {
        console.log("err theme", err);
      });
  }

//récupérer les formations e-learning en cours
  function getEncours() {
    axios
      .get(uri + "/elearning/get_formations_en_cours/" + localStorage.getItem("email"))
      .then((result) => {
       
        setEncours(...elearning, result.data);
        console.log('daya', encours);
      })
      .catch((err) => {
        console.log("err theme", err);
      });
  }

  useEffect(() => {
    if (params.theme === "encours") {
      getEncours();
    } else {
      getMorePresentiel();
      getElearning();
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <nav aria-label="breadcrumb pb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#" onClick={()=>history.go(-1)}>{params?.category}</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {params?.theme==="encours"?"En cours":params?.theme}
            </li>
          </ol>
        </nav>
        <Hn title={params?.theme} level="h4" className="mb-3 searchTitle" />
        
        {params?.category == "training" &&
          <div>
            <div className={`titreE pt-4`}>
              <p>Formations présentielles</p>
            </div>

            <div className="row">
              {training?.map((item, index) => {
                return (
                  <div className="col-3">
                    <CadreItems
                      title={item?.title || item?.intitule}
                      id={item?.id}
                      base_url={IMG_URL}
                      details={"detailformation"}
                      image={item?.image}
                      index={index}
                      switchs
                      key={index}
                      isflip={false}
                      isElearning={false}
                    />
                  </div>
                );
              })}


              {/* {encours.map((item, index) => {
                return (
                  <div className="col-3">
                    <CadreItems
                      title={item?.title || item?.intitule}
                      id={item?.id}
                      base_url={IMG_URL_E}
                      details={"detailformations"}
                      image={item?.image}
                      index={index}
                      switchs
                      elearning={localStorage.getItem('elearning')}
                      isflip={false}
                    />
                  </div>
                );
              })} */}
            </div>

          </div>
        }


        {params?.category == "elearning" &&
          <>

            <div className={`titreE pt-4`}>
                  <p>Formations E-learning</p>
            </div>

            <div className="row">
              {encours?.map((item, index) => {
                return (
                  <div className="col-3">
                    <CadreItems
                      title={item?.title || item?.intitule}
                      id={item?.id}
                      base_url={IMG_URL_E}
                      details={"detailformations"}
                      image={item?.image}
                      index={index}
                      key={index}
                      switchs
                      elearning={localStorage.getItem('elearning')}
                      isflip={false}
                      isElearning={true}
                    />
                  </div>
                );
              })}


            </div>

          </>
        }
      

      </div>
    </div>
  );
};

export default ListMore;
