export default function Cordion() {

  return (
    
    <div className="container-fluid">
    <div className="table-responsive bg-white pb-5">
    <table className="table table-hover m-0">
        <thead className="text-white">
            <tr>
                <th className="border-0 ps-4"></th>
                <th className="border-0 p-4 pe-3 ps-0">
                    <span>Name</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                </th>
                <th className="border-0 p-4 pe-3 ps-0 text-center">
                    <span>Date</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                </th>
                <th className="border-0 p-4 pe-0 ps-0 text-end">
                    <span>Budget</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                </th>
                <th className="border-0 pe-4"></th>
            </tr>
        </thead>
        <tbody className="accordion" id="accordionExample">
            <tr>
                <th className="border-0 ps-4"></th>
                <td className="p-4 pe-3 ps-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    <span>NameExample</span>
                </td>
                <td className="p-4 pe-3 ps-0 text-center">2017 — 2020</td>
                <td className="p-4 pe-0 ps-0 text-end"><span>100000</span>₴</td>
                <th className="border-0 pe-4"></th>
            </tr>
            <div className="accordion-item">
                <tr className="accordion-header" id="headingOne" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <th className="border-0 ps-4"></th>
                    <td className="p-4 pe-3 ps-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                        </svg>
                        <span>NameExample</span>
                    </td>
                    <td className="p-4 pe-3 ps-0 text-center">2007 — 2008</td>
                    <td className="p-4 pe-0 ps-0 text-end"><span>100000</span>₴</td>
                    <th className="border-0 pe-4"></th>
                </tr>
                <tr>
                    <th className="border-0 ps-4"></th>
                    <td colspan="3" className="p-0">
                        <div id="collapseOne" className="accordion-collapse collapse sow" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. </div>
                        </div>
                    </td>
                    <th className="border-0 pe-4"></th>
                </tr>
            </div>
            <tr>
                <th className="border-0 ps-4"></th>
                <td className="p-4 pe-3 ps-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    <span>NameExample</span>
                </td>
                <td className="p-4 pe-3 ps-0 text-center">1995 — 2050</td>
                <td className="p-4 pe-0 ps-0 text-end"><span>100000</span>₴</td>
                <th className="border-0 pe-4"></th>
            </tr>
            <tr>
                <th className="border-0 ps-4"></th>
                <td className="p-4 pe-3 ps-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    <span>NameExample</span>
                </td>
                <td className="p-4 pe-3 ps-0 text-center">2017 — 2019</td>
                <td className="p-4 pe-0 ps-0 text-end"><span>50000</span>₴</td>
                <th className="border-0 pe-4"></th>
            </tr>
            <tr>
                <th className="border-0 ps-4"></th>
                <td className="p-4 pe-3 ps-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    </svg>
                    <span>NameExample</span>
                </td>
                <td className="p-4 pe-3 ps-0 text-center">2019 — 2020</td>
                <td className="p-4 pe-0 ps-0 text-end"><span>50000</span>₴</td>
                <th className="border-0 pe-4"></th>
            </tr>
        </tbody>
    </table>
</div>
    </div>
  );
}
