import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player/lazy";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import "./popupVideo.css";
import Iframe from "react-iframe";
import toast, { Toaster } from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";

export default function Popupvideo({ play, showfiltre, closefiltre, run }) {
  const params = useParams();
  const nextRef = useRef();
  const videoRef = useRef();
  const audioRef = useRef();
  const uri = process.env.REACT_APP_BASE_URL;
  const unite_id = play?.unite?.id;
  const user = localStorage.getItem("email");
  const [show, setShow] = useState(false);
  const [endToast, setEndToast] = useState(false);
  const [position, setPosition] = useState(1);
  const [total_document, setTotalDocument] = useState(1);
  const [unite, setUnite] = useState(play?.unite);
  const [elearning, setElearning] = useState(play?.elearning);
  const [chapitre, setChapitre] = useState(play?.chapitre);
  let [scormdirectoryname, setScormdirectoryname] = useState();
  let [scormScore, setScormScore] = useState();
  let [scormTemps, setScormTemps] = useState(0);
  let [scormNbQuestions, setScormNbQuestions] = useState(0);
  let [scorm_url, setScormUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoCurrentTime, setVideoCurrentTime] = useState(0);
  const [audioCurrentTime, setAudioCurrentTime] = useState(0);
  const [label, setLabel] = useState("Commencer");
  let xtoast = 1;
  let ytoast = 1;
  let history = useHistory();

  const onVideoStart = React.useCallback(
    (timeToStart) => {
      videoRef.current.seekTo(timeToStart, "seconds");
    },
    [videoRef.current]
  );

  const onAudioStart = React.useCallback(
    (timeToStart) => {
      audioRef.current.seekTo(timeToStart, "seconds");
    },
    [audioRef.current]
  );

  const handleClose = (unite = []) => {
    //getCurrentTime



    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if (unite?.extension == "mp4" || unite?.extension == "mp3") {
      var data = new FormData();
      data.append("email", user);
      data.append("unite", unite?.id);
      data.append("duree", videoCurrentTime);

      trackPromise(
        axios
          .post(uri + "/player_resume/new", data, headersFiles)
          .then((result) => {
            setShow(false);
          })
          .catch((err) => {
            console.log("err", err);
          })
      );
    } else {
      setShow(false);
    }

    // window.location.reload(false);
    history.push("/detailformations/" + params.id);
  };

  const handleShow = () => {
    if (play?.elearning_ended) {
      //elearning_ended
      //return;
    }
    //make start statement
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    var data = new FormData();
    let verb = "start";

    if (play?.label != "Commencer") {
      verb = "checkin";
    }

    data.append("verb", verb);
    data.append("user_email", user);
    data.append("unite", unite_id);
    trackPromise(
      /*local*/ axios
        .post(uri + "/watershed/new", data, headersFiles)
        .then((result) => {
          setShow(true);
        })
        .catch((err) => {
          console.log("err data", err);
        })
    );
  };

  function handleMessage(event) {
    setScormScore(event.data?.detail?.score);
    setScormNbQuestions(event.data?.detail?.nb_questions);

    if (event.data?.detail?.exit == "oui" && typeof unite !== "undefined") {
      setScormTemps(1);
      nextRef.current.click();
    }
  }

  useEffect(() => {
    setLabel(play?.label);
    setUnite(play?.unite);
    setElearning(play?.elearning);
    setChapitre(play?.chapitre);
    setTotalDocument(play?.total_document);
    setPosition(play?.position);
    setScormdirectoryname(play?.unite?.scormdirectoryname);
    setVideoUrl(
      process.env.REACT_APP_PIC_ELEARNING_FILE + play?.unite?.document
    );
    setScormUrl(
      process.env.REACT_APP_PIC_ELEARNING_SCORM +
        play?.unite?.scormdirectoryname +
        "/story.html"
    );

    setScormNbQuestions(0);
    setScormScore(0);

    if (window.addEventListener) {
      window.addEventListener("message", handleMessage);
    } else {
      window.attachEvent("onmessage", handleMessage);
    }
    if (run && run === "run") {
      setShow(true);
      run = "done";
    }
  }, [play, showfiltre, closefiltre]);

  const handlePrevClicked = () => {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    var data = new FormData();

    data.append("user_email", user);
    data.append("unite", unite?.id);

    trackPromise(
      axios
        .post(uri + "/watershed/get_back", data, headersFiles)
        .then((result) => {
          if (result?.data?.first === true) {
            toast.error("Désolé Oups! Vous ne pouvez aller plus loin.");
          } else {
            setPosition(result?.data?.position);
            setTotalDocument(result?.data?.total_document);
            setUnite(result?.data?.unite);
            setChapitre(result?.data?.chapitre);
            setScormdirectoryname(result?.data?.unite?.scormdirectoryname);
            setVideoUrl(
              process.env.REACT_APP_PIC_ELEARNING_FILE +
                result?.data?.unite?.document
            );
            setScormUrl(
              process.env.REACT_APP_PIC_ELEARNING_SCORM +
                result?.data?.unite?.scormdirectoryname +
                "/story.html"
            );
          }
        })
        .catch((err) => {
          console.log("err data", err);
        })
    );
  };

  function handleNextClicked(
    exit_scorm = "non",
    score = null,
    nb_questions = 0
  ) {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    var data = new FormData();
    //toast.success("hello!");
    if (scormdirectoryname !== null) {
      if (scormTemps == 0) {
        toast.error("Désolé vous devez finir le cours avant!");
        return;
      }
      //validation scorm
      data.append("scorm_score", scormScore);
      data.append("scorm_nb_questions", scormNbQuestions);
    }

    var verb = "checkin";

    data.append("user_email", user);
    data.append("unite", unite?.id);
    data.append("unite_passed", true);
    data.append("verb", verb);

    //if (endToast === false) {
      trackPromise(
        axios
          .post(uri + "/watershed/new", data, headersFiles)
          .then((result) => {
            if (result?.data?.elearning_passed === true) {
              //setEndToast(true);
              if(xtoast === 1){
                toast.success("Vous avez terminé cette formation!", {
                  id: 'formation',
                });
                xtoast = xtoast + 1
              }
              
              handleClose();
              window.location.reload(false);
            }

            setPosition(result?.data?.position);
            setTotalDocument(result?.data?.total_document);
            setUnite(result?.data?.unite);
            setChapitre(result?.data?.chapitre);
            setScormdirectoryname(result?.data?.unite?.scormdirectoryname);
            setVideoUrl(
              process.env.REACT_APP_PIC_ELEARNING_FILE +
                result?.data?.unite?.document
            );
            setScormUrl(
              process.env.REACT_APP_PIC_ELEARNING_SCORM +
                result?.data?.unite?.scormdirectoryname +
                "/story.html"
            );

            if (result?.data?.chapitre_passed === true) {
              if(ytoast === 1){
                toast.success("Vous avez terminé ce chapitre!", {
                  id: 'chapitre',
                });
                ytoast = ytoast + 1
              }
              window.location.reload(false);
            }
          })
          .catch((err) => {
            console.log("err data", err);
          })
      );
    //}
  }

  return (
    <>
      <div className="col-md-3 text-end">
        <button className="btn btn-primary" onClick={handleShow}>
          {label}
        </button>
      </div>
      <Modal
        show={show}
        onHide={() => handleClose(unite)}
        className="videomodal"
        backdrop="static"
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <div id="unite_title">
            <p className="elearning">{elearning?.intitule}</p>{" "}
            <p className="chapitre">
              {chapitre?.titre} | {unite?.titre}
            </p>
          </div>

          <div className="float-end" id="unite_footer">
            <p className="text-white" style={{ lineHeight: "3" }}>
              Document {position}/{total_document}
            </p>
            <div className="square">
              <button
                id="prev"
                type="button"
                className="btn btn-link"
                onClick={() => {
                  handlePrevClicked();
                }}
              >
                <img src={require("../../assets/chevronleft.png")} />
              </button>
            </div>
            <div className="square">
              <button
                ref={nextRef}
                id="next"
                type="button"
                className="btn btn-link"
                onClick={() => {
                  handleNextClicked();
                }}
              >
                <img src={require("../../assets/chevronright.png")} />
              </button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div id="content_player">
            {unite?.extension == "zip" && (
              <Iframe
                url={scorm_url}
                id="scorm_content"
                display="initial"
                position="relative"
                className="vidIframe"
              />
            )}
            {unite?.extension == "mp4" && (
              <ReactPlayer
                ref={videoRef}
                url={videoUrl}
                controls={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
                onProgress={(progress) => {
                  setVideoCurrentTime(progress.playedSeconds);
                }}
                onStart={() => {
                  onVideoStart(unite?.resume_time);
                }}
              />
            )}
            {unite?.extension == "pdf" && (
              <object data={videoUrl} type="application/pdf">
                <p>
                  Erreur de chargement, vous pouvez télécharger le PDF{" "}
                  <a href={videoUrl}>ici</a>
                </p>
              </object>
            )}
            {unite?.extension == "mp3" && (
              <ReactPlayer
                ref={audioRef}
                url={videoUrl}
                controls={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
                onProgress={(progress) => {
                  setVideoCurrentTime(progress.playedSeconds);
                }}
                onStart={() => {
                  onAudioStart(unite?.resume_time);
                }}
              />
            )}
          </div>
          {/* <div className="float-end" id="unite_footer">
            <p className="text-white" style={{ lineHeight: "3" }}>
              Document {position}/{total_document}
            </p>
            <div className="square">
              <button id="prev" type="button" className="btn btn-link" onClick={() => { handlePrevClicked(); }}>
                <img src={require("../../assets/chevronleft.png")} />
              </button>
            </div>
            <div className="square">

              <button
                ref={nextRef}
                id="next"
                type="button"
                className="btn btn-link"
                onClick={() => {
                  handleNextClicked();
                }}

              >
                <img src={require("../../assets/chevronright.png")} />
              </button>

            </div>
          </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
}
