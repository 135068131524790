import axios from "axios";
import dateFormat from "dateformat";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Hn from "../../../../../components/common/Hn";
import Icon from "../../../../../components/common/Icon";
import "./../session.css";
import Participant from "./show-sessions-participants/participantSession";

function ShowSessions() {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const uri = process.env.REACT_APP_BASE_URL;
  const params = useParams();
  const [session, setSession] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [etat, setEtat] = useState('');
  const [comment, setComment] = useState('');
  const data = {};
  const etats = ["À venir", "En cours", "Terminee"]
 //modifier une session sur les seuls champs status/comment dans session
  function updateSession() {
    const data = { "comment": comment, "condition": etat };
  
    axios
      .put(uri + "/session/edit/comment/" + params.id, data, headers)
      .then((result) => {
        //setSession(result?.data);
    
      })
      .catch((err) => {
        console.log("err ", err);
      });
  }
//récupérer les sessions 
  function getUnitySession() {

    axios
      .get(uri + "/session/" + params.id, headers)
      .then((result) => {
        setSession(result?.data);
       
      })
      .catch((err) => {
        console.log("err ", err);
      });
  }

  //récupérer les participants de cette session choisie
  function getParticipants() {

    axios
      .get(uri + "/participant/session/" + params.id, headers)
      .then((result) => {
        console.log("sess", "Terminee" === "Terminée")
        setParticipants(result?.data);
        
      })
      .catch((err) => {
        console.log("err ", err);
      });
  }


  useEffect(() => {
    getUnitySession();
    getParticipants()
  }, []);

 
  return (
  
    <div className="bunner-provider container-fluid session1">
      <div className="row pe-2">
        <div className=" container lists pe-4">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/Sessions">Formations présentielles - session de formation</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">{session?.ref}</li>
            </ol>
          </nav>
          <a type="submit" className=" btn-link resetBtn" href="javascript:history.back()">
            Retour{" "}
          </a>
          <div className="row pb-2">
            <div className="col-md-6">
              <Hn title="" level="h2" className="pt-4" />
            </div>
            <div className="col-md-6">
              <a
                href={"/admin/update-session-formation-presentielle/" + session.id}
                className="btn btn-primary float-end mt-4"
                type="button"
              >
                Modifier
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-xs-12 ">
              <div className="cardSession">
                <h5 className="card-title title16">Unités de formations</h5>
                <p className="card-text title23">{session?.lessons?.length}</p>
              </div>
            </div>
            <div className="col-md-3 col-xs-12 ">
              <div className="cardSession">
                <h5 className="card-title title16">Coût</h5>
                <p className="card-text title23">{session?.training?.cost}</p>
              </div>
            </div>
            <div className="col-md-3 col-xs-12 ">
              <div className="cardSession">
                <h5 className="card-title title16">Etat de la session</h5>
                <p className="card-text title23">{session?.conditions}</p>
              </div>
            </div>
            <div className="col-md-3 col-xs-12 ">
              <div className="cardSession">
                <h5 className="card-title title16">Participants inscrits</h5>
                <p className="card-text title23">
                  {session?.contactSessions?.length}
                </p>
              </div>
            </div>
          </div>

          {/* lists-filter */}
          <div className="row mt-30 mb-135">
            <div className="col-12">
              <div className="h-100 p-5 text-white bg-dark rounded-3">
                <Hn
                  title="Informations générales"
                  level="h4"
                  className="h4-size pb-5"
                  plus={false}
                />
                <div className="form-elements">
                  <div className="row g-3">
                    <div className="col-md-4">
                      <label htmlFor="ref" className="form-label title16">
                        Référence
                      </label>
                      <p>{session?.ref}</p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="nom" className="form-label title16">
                        Date de Début
                      </label>
                      <p> {dateFormat(session?.dateStart, "dd/mm/yyyy")}</p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="denomi" className="form-label title16">
                        Date de fin
                      </label>
                      <p>{dateFormat(session?.dateEnd, "dd/mm/yyyy")}</p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="denomi" className="form-label title16">
                        Lieux de formation
                      </label>
                      {session?.location!==null?
                      <p>
                        {session?.location?.address +
                          ", " +
                          session?.location?.zip +
                          " " +
                          session?.location?.town}
                      </p>
                      :""}
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="codepos" className="form-label">
                        Formateurs
                      </label>
                      <p>{session?.former?.organize}</p>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="codepos" className="form-label"></label>
                      <p>
                        {session?.former?.firstName +
                          " " +
                          session?.former?.lastName}
                      </p>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="codepos" className="form-label">
                        Etat
                      </label>
                      <select
                        className="form-control select"
                        name="etat"
                        onChange={event => setEtat(event.target.value)}
                        defaultValue={session?.conditions}
                        // value={etat || session?.conditions}
                      >
                        {etats.map((item, index) => (
                          <option value={item} key={index} selected={session?.conditions===item}>{item}</option>
                        ))}


                      </select>
                    </div>

                    <div className="col-md-12 mt-2">
                      <label htmlFor="ville" className="form-label">
                        Commentaire
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Ajouter un commentaire"
                        name="comment"
                        onChange={event => setComment(event.target.value)}
                        defaultValue={session.comment}
                        value={comment}
                      ></textarea>
                    </div>
                    <div className="col-md-12 mt-2">
                      <button class="btn btn-primary float-right"
                        onClick={updateSession}


                      >
                        valider
                      </button>
                    </div>

                    <div className="row">
                      <Hn
                        title="Unités de formation"
                        level="h4"
                        className="h4-size pb-5 pt-5"
                        plus={false}
                      />



                      {session?.lessons?.map((lesson, index) => (

                        <div className="col-md-6" key={index}>
                          <div className="cardUnit text-white grecard mb-3 p-3">
                            <div className="card-body grecard">
                              <h5 className="card-title">
                                {lesson?.name}
                              </h5>
                              <div className="pt-3">
                                <Icon
                                  name="localisation"
                                  data={
                                    lesson?.location?.address +
                                    ", " +
                                    lesson?.location?.zip +
                                    " " +
                                    lesson?.location?.town
                                  }
                                />
                                <ul className="list-inline">
                                  <li className="list-inline-item">
                                    <Icon name="Calendar" data={dateFormat(lesson?.date, "dd/mm/yyyy")} />
                                  </li>
                                  <li className="list-inline-item">
                                    <Icon
                                      name="Clock"
                                      data={
                                        lesson?.hourStartMorning +
                                        "h /" +
                                        lesson?.hourEndMorning +
                                        "h / et " +
                                        lesson?.hourStartmidi +
                                        "h /" +
                                        lesson?.hourEndMidi
                                      }
                                    />
                                  </li>
                                  <li className="list-inline-item">
                                    <Icon
                                      name="profil"
                                      data={
                                        lesson?.former?.organize +
                                        " : " + lesson?.former?.firstName +
                                        " " +
                                        lesson?.former?.lastName
                                      }
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                      ))}

                    </div>
                  
              
              <Participant participants={participants} getUnitySession={getUnitySession} setParticipant={setParticipants}/>
              


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ShowSessions;
