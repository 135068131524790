import { useState } from "react";
import Modal from "react-bootstrap/Modal";
// import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import axios from "axios";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import PopupFiltre from "../../../../components/popup/PopupFiltre";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AxioServices from "../../../../services/AxioServices";
import Papa from "papaparse";
//toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { createBrowserHistory } from "history";

export default function ListLocationTrainingDataGrid({
  lieuxFormation,
  setLieuxForamtion,
  getLocation,
  setShow,
  show,
}) {
  const [id, setId] = useState();
  let history = createBrowserHistory({ forceRefresh: true });
  let toastId = null;
  const uri = process.env.REACT_APP_BASE_URL;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showfiltre, setShowfiltre] = useState(false);
  const [eachEntry, setEachEntry] = useState("");
  const [initiallieuxFormation, setInitiallieuxFormation] = useState([]);
  const { ExportCSVButton } = CSVExport;
  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };
  const handleOnChange = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {

        AxioServices.create("location/import", JSON.stringify(results.data))
          .then((result) => {
            console.log(result.data);
          })
          .catch((err) => {
            console.log(err);
          });
      },
    });
  };
  const handleShowfiltre = () => {
    setShowfiltre(true);
  };
  const handleClosefiltre = (obj) => {
    setShowfiltre(false);

  };

  function searchwithfiltre(val) {
    setInitiallieuxFormation(lieuxFormation);
    setEachEntry(val);
    console.log(lieuxFormation)
    console.log(val)
    if (val.name === ""
      && val.ville === ""
      && val.zip === ""
      && val.minvalue === 0
      && val.maxvalue === 10000
    ) {

      getLocation();
      //  setParticipant(initialparticipant)
    } else {
      setLieuxForamtion([]);

      let locationFilter = lieuxFormation.filter(el => {
          let ch = 0; let lf;let i = 0;
          if(val.name){
            i = i + 1
            lf = el?.name?.toLowerCase().includes(val?.name?.toLowerCase())
            if(lf) ch = ch +1;
          }
          if(val.ville){
            i = i + 1
            lf = el?.town?.toLowerCase().includes(val?.ville?.toLowerCase())
            if(lf) ch = ch +1;
          } 
          if(val.zip){
            i = i + 1
            lf = el?.zip?.toLowerCase().includes(val?.zip?.toLowerCase())
            if(lf) ch = ch +1;
          }
          if(val.minvalue){
            i = i + 1
            if(el?.capacity >= val.minvalue) ch = ch +1;
          }
          if(val.maxvalue){
            i = i + 1
            if(el?.capacity <= val.maxvalue) ch = ch +1;
          }

          if(ch === i) {return true}
          return false
      }
      )


      setLieuxForamtion(locationFilter)

    }

  }
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }

  function deleteLocation(id) {

    axios
      .delete(uri + "/location/" + id)
      .then(() => {
        displayToast("Csuccess", "Location supprimé avec success, Reload...");
        setTimeout(() => {
          getLocation();
        }, 3000);
      })
      .catch(() => {
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
    setShow(false);
  }

  function duplicate(data) {

    AxioServices.create("location/new", data)
      .then((result) => {

        // setToastshow(true);
        // history.push("/admin/liste-lieu-formation");
        displayToast("Csuccess", "Lieux de formation dupliqué avec success, Reload...");
        setTimeout(() => {
          getLocation();
        }, 3000);
      })
      .catch((err) => {
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
  }
  const columns = [
    {
      dataField: "name",
      text: "Nom",
      csvText: "name",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "town",
      text: "Ville",
      csvText: "town",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "zip",
      text: "Code Postal",
      csvText: "zip",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "address",
      text: "Adresse",
      csvText: "address",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "capacity",
      text: "Capacité",
      csvText: "capacity",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "ref",
      text: "Ref",
      csvText: "ref",
      hidden: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "company_name",
      text: "company_name",
      csvText: "company_name",
      hidden: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "country",
      text: "country",
      csvText: "country",
      hidden: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "cost",
      text: "cost",
      csvText: "cost",
      hidden: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "interlocutor_name",
      text: "interlocutor_name",
      csvText: "interlocutor_name",
      hidden: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "tel",
      text: "tel",
      csvText: "tel",
      hidden: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "email",
      text: "email",
      csvText: "email",
      hidden: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "comment",
      text: "comment",
      csvText: "comment",
      hidden: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "follow",
      text: "Actions",

      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="btn-group" role="group" aria-label="Basic example">
            <a
              href={"/admin/update-location-training/" + row.id}
              type="button"
              className="btn btn-link"
            >
              <img src={require("../../../../assets/icons/edit.png")} />
            </a>
            {localStorage.getItem("role") === "Super-admin" ? (
              <button
                type="button"
                className="btn"
                onClick={() => {
                  setId(row.id);
                  handleShow();
                }}
              >
                <img src={require("../../../../assets/icons/delete.png")} />
              </button>
            ) : (
              ""
            )}
            <button
              type="button"
              className="btn"
              onClick={() => {
                duplicate(row);
              }}
            >
              <img src={require("../../../../assets/icons/duplicate.png")} />
            </button>
          </div>
        );
      },
      sort: true,
    },
  ];
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    // hideSelectAll: true,
  };
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    const onFollowChanged = () => {
      this.setState({ isFollow: !this.state.isFollow });

    };

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Button
          onClick={() => {
            this.onFollowChanged(row);
          }}
        >
          Follow
        </Button>
      );
    };

    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };
  let rows = lieuxFormation.map((item, i) => item);
  return (
    <>
      <ToastContainer />
      <ToolkitProvider
        keyField="id"
        data={rows}
        columns={columns}
        exportCSV={{ onlyExportSelection: true, exportAll: false }}
        search
      >
        {(props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter py-3">
              <div className="row">
                <div className="col-xl-4 col-lg-1"></div>
                <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                  <div className="flexgy">
                    <div className="col-md-4  mr-1  px-0">
                      <div className="input-group">
                        <input
                          className="form-control height43"
                          type="search"
                          placeholder="Recherche"
                          onChange={(e => props.searchProps.onSearch(e.target.value))}
                        />

                      </div>
                    </div>
                    <div className=" px-0 myxs">
                      <div className="flex">
                        <div className="square">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={handleShowfiltre}
                          >
                            <img
                              src={require("../../../../assets/icons/filter.png")}
                            />
                          </button>
                        </div>
                        <PopupFiltre
                          showfiltre={showfiltre}
                          closefiltre={handleClosefiltre}
                          searchwithfiltre={searchwithfiltre}
                          filterEntry={eachEntry}
                        />
                        <div className="square">
                          <button className="btn btn-link" onClick={() => props.csvProps.onExport()}>
                            <img
                              src={require("../../../../assets/icons/Export.png")}
                            />
                          </button>
                        </div>
                        <div className="square">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept={".csv"}
                            className="btn btn-link"
                            onChange={handleOnChange}
                            hidden
                          />
                          <label htmlFor="file" type="button">
                            <img
                              src={require("../../../../assets/icons/Import.png")}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="px10">
                      <div className="d-grid gap-2">
                        <a
                          href="/admin/create-location-training"
                          className="btn btn-primary btn-gy height43"
                          type="button"
                        >
                          Ajouter un lieu de formation
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BootstrapTable
              {...props.baseProps}
              classes="table-dark"
              bordered={false}
              hover
              selectRow={selectRow}
              pagination={paginationFactory(options)}
            />
          </div>
        )}
      </ToolkitProvider>

      <Modal show={show} onHide={handleClose} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            Etes-vous sûr(e) de vouloir supprimer ?
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={handleClose} style={{ color: "#006FFF" }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-primary"
            onClick={(e) => deleteLocation(id)}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
