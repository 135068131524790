import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import Elearning from "../../assets/icons/E-learning.svg";
import Webinar from "../../assets/icons/Micro2 1.svg";
import Presentiel from "../../assets/icons/presentiel.svg";

import axios from "axios";

import "./bunner.css";
import { useState, useEffect } from "react";
import { createBrowserHistory } from "history";
import PopupSubscribe from "../popup/PopupSubscribe";

import { useHistory, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Hn from "../../components/common/Hn";

export default function Bunner({ title, data, details, url, elearning, from }) {
  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const data_participant = {
    participant: localStorage.getItem("id"),
  };
  const uri = process.env.REACT_APP_BASE_URL;
  let history2 = useHistory();

  let [localData, setLocalData] = useState([]);
  //
  const [webinair, setWebinair] = useState([]);
  const [id, setId] = useState([]);
  const [showModalInscri, setShowModalInscri] = useState(false);
  const [webinarsTitle, setWebinarsTitle] = useState("...");

  const items = [1, 2];
  const [showfiltreSubscribe, setShowfiltreSubscribe] = useState(false);
  let history = createBrowserHistory({ forceRefresh: true });
  //ajouter un participant ou plus en checkant un de la liste affichée à un webinair déja crée
  function inscrire(id) {
    axios
      .put(`${uri}/webinars/edit/particiapnt/` + id, data_participant, headers)
      .then((response) => {
        hideModal();
        setTimeout(() => {
          history2.go(0);
        }, 100);
      })
      .catch((error) => {
        console.log("error", error);
        // displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
  }
  const options = {
    items: 4,
    responsiveClass: true,
    margin: 27,
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
    },
  };

  const hideModal = () => {
    setShowModalInscri(false);
  };



  const closefiltreSubscribe = () => {
    setShowfiltreSubscribe("false");
  };

  useEffect(() => {
    localData = data;
    setLocalData(data);
  }, [data]);

  return (
    <div className="bunner p-5">
      <PopupSubscribe
        showfiltreSubscribe={showfiltreSubscribe}
        setShowfiltreSubscribe={setShowfiltreSubscribe}
        closefiltreSubscribe={closefiltreSubscribe}
        title={""}
      />

      <OwlCarousel className="owl-theme" {...options}>
        {data?.map((item, index) => {
          return (
            <div className="item" key={index}>
              <div className="row p-1">
                <div className="col-md offset-md-1 mt-4">
                  <div className="bunner-title-elearning">
                   
                  </div>
                  <div className="bunner-texte mb-4">
                    <h2>{item?.title || item?.intitule}</h2>
                    <span className="text-white">{item?.theme?.name}</span>
                  </div>
                  <div className="d-flex ">
                    <div className="me-2">
                      {from == "presentiel" && item?.type_icone != "webinar" ? (
                        item?.forward_catalog === true ? (
                          item?.sessions?.filter(
                            (obj) => obj.id === localStorage.getItem("id")
                          ).length !== 0 ? (
                           null
                          ) : (
                           null
                          )
                        ) : (
                          <a
                            onClick={() => {
                              if (elearning === "non") {
                                setShowfiltreSubscribe("true");
                              } else {
                                history.push(
                                  url + "/detailformations/" + item?.id + "/run"
                                );
                              }
                            }}
                            className="btn btn-primary"
                          >
                            {item.label}
                          </a>
                        )
                      ) : item?.forward_catalog === true ? (
                        item?.contacts?.filter(
                          (obj) => obj.id === localStorage.getItem("id")
                        ).length !== 0 ? (
                          <a
                            href={"/Details-webinars/" + item.id}
                            title="Reprendre"
                            className="btn btn-primary"
                          >
                            Reprendre
                          </a>
                        ) : (
                          <a
                            //href="/Details-webinars/"
                            title="S'inscrire "
                            className="btn btn-primary"
                            onClick={() => {
                              setId(item.id);
                              setWebinarsTitle(item.title);
                              setShowModalInscri(true);
                            }}
                          >
                            Inscrire
                          </a>
                        )
                      ) : (
                        <a
                          onClick={() => {
                            if (elearning === "non") {
                              setShowfiltreSubscribe("true");
                            } else {
                              history.push(url + "/" + item?.id + "/run");
                            }
                          }}
                          title="en savoir plus"
                          className="btn btn-primary"
                        >
                          {item.label}
                        </a>
                      )}
                    </div>
                    {item.from === "webinar" ? (
                      <a
                        href={"Details-webinars" + "/" + item?.id}
                        title="en savoir plus"
                        className="btn btn-default border border-light btn-outline-light"
                      >
                        En savoir plus
                      </a>
                    ) : item?.from == "elearning" ? (
                      <a
                        href={"detailformations" + "/" + item?.id}
                        title="en savoir plus"
                        className="btn btn-default border border-light btn-outline-light"
                      >
                        En savoir plus 
                      </a>
                    ) : (
                      <a
                        href={"detailformation" + "/" + item?.id}
                       
                        title="en savoir plus"
                        className="btn btn-default border border-light btn-outline-light"
                      >
                        En savoir plus
                      </a>
                    )}
                  </div>
                </div>
                <div className="col-md d-none d-lg-block">
                  {from === "presentiel" ? (
                    <img
                      src={item.type_icone === "webinar" ? Webinar : Presentiel}
                      alt="elearning"
                      className={
                        item.type_icone === "webinar"
                          ? "imageWebinar"
                          : "imagePresentiel"
                      }
                    />
                  ) : (
                    <img
                      src={item.forward_catalog === true ? Webinar : Elearning}
                      alt="elearning"
                      className={
                        item.forward_catalog === true
                          ? "imageWebinar"
                          : "imageElearning"
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </OwlCarousel>
      <Modal
        show={showModalInscri}
        onHide={() => hideModal()}
        size="lg"
        className="special-width-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title className="px-3 nopad">
            <Hn title="Félicitations!" level="h3" plus={false} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3 nopad">
            <div className="col-md-12">
              <Hn
                title="Nous vous confirmons l'inscription de :"
                level="h5"
                plus={false}
                className="label-popup"
              />
              <p className="text-white textBox">
                {localStorage.getItem("nom") + " " +
                localStorage.getItem("prenom")}
              </p>
            </div>
            <div className="pt-2">
              <Hn
                title="Webinaire :"
                level="h5"
                plus={false}
                className="label-popup"
              />
              <p className="text-white textBox">{webinarsTitle}</p>
            </div>
          </div>
          {/* /row */}
          <br></br>
          {/* /row */}{" "}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary float-end"
            onClick={() => {
              inscrire(id);
            }}
          >
            Confirmer
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
