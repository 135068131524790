import axios from "axios";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Hn from "../../../../components/common/Hn";
import "./elearning.css";
import ListElearningsDataGrid from "./ListElearningsDataGrid";
import { trackPromise } from "react-promise-tracker";
import { ToastContainer, toast } from "react-toastify";

function Elearning() {
  const [elearnings, setElearnings] = useState([]);
  const [initraining, setInitraining] = useState([]);
  const [id, setId] = useState();
  const [elear, setElear] = useState();
  const [intitule, setIntitule] = useState();
  const [thematic, setThematic] = useState([]);

  const [show, setShow] = useState(false);
  const [term, setTerm] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [eachEntry, setEachEntry] = useState("");

  const uri = process.env.REACT_APP_BASE_URL;
  // Récupérer la liste des formations elearning
  function getElearnings() {
    trackPromise(
      axios
        .get(uri + "/elearning/")
        .then((result) => {
       
          setElearnings(result?.data);
          console.log('aaa', result?.data);
        })
        .catch((err) => {
          console.log("err elearning", err);
        })
    );
  }

  useEffect(() => {
    getElearnings();
    
  }, []);

  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn
              title="Formations E-learning"
              level="h1"
              className="pt-5"
              plus={false}
            />
            <Hn title="Actions de formation" level="h2" className="pt-4" />
          </div>

    
          {/* lists-filter */}

          <div className="pt-5 trainingTable">
            
              <ListElearningsDataGrid
                setShow={setShow}
                show={show}
                elearnings={elearnings}
                getElearnings={getElearnings}
                setElearnings={setElearnings}
              />
          
          </div>
        
        </div>
      </div>
    </div>
  );
}

export default Elearning;
