import profil from "../../assets/profil.png";
import "./bunnerProfil.css";
export default function BunnerProfil({ title }) {
  return (
    
    <div className="mobile bunner-profil p-4">
      <div className="row">
        <div className="offset-0 gridBP">
          <div className="img">
            <img
              src={profil}
              alt="profil"
              className="image-profil img-fluid"
            />
          
          </div>
          <div className="bunner-box">
              <div className="bunner-title">
                <h3 className="h5">
                  {title}
                  Stéphanie LEMOIGNE
                </h3>
              </div>
              <div className="bunner-texte">
                <h4 className="titre-h-5">Manager</h4>
              </div>
              <div className="bunner-info">
                <h5 className="titre-info-h-5">
                  Renouvellement de la carte:15 septembre 2024
                </h5>
              </div>
            </div>
        </div>
       
        <div className="row profilGrid">
          <div className="col-md-6  colCard">
            <div className="card text-white bg-dark mb-3 ">
              <div className="card-body">
                <h5 className="card-title">Score moyen</h5>
                <p className="card-text">
                 80%
                </p>
              </div>
            </div>
          </div>
           <div className="col-md-6 colCard">
           <div className="card text-white bg-dark mb-3">
              <div className="card-body">
                <h5 className="card-title">Score maximum</h5>
                <p className="card-text">
                100%
                </p>
              </div>
            </div>
           </div>
          <div className="col-md-6 colCard"> <div className="card text-white bg-dark mb-3">
              <div className="card-body">
                <h5 className="card-title">Score minimum</h5>
                <p className="card-text">
                 75%
                </p>
              </div>
            </div></div>
          <div className="col-md-6 colCard"> <div className="card text-white bg-dark mb-3">
              <div className="card-body">
                <h5 className="card-title">Temps total</h5>
                <p className="card-text">
                22:10:23
                </p>
              </div>
            </div></div>
            
        </div>
      </div>
    </div>
  
  );
}
