import axios from "axios";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import Hn from "../../components/common/Hn";

import "../admin/forms/face-training/crud/createFaceTraining.css";
import "../admin/forms/location/ListLocationTraining.css";
import Datatable from "./Datatable";
import Papa from "papaparse";
import AxioServices from "../../services/AxioServices";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

function Listecompetence() {
 
  const [displayetat, setDisplayetat] = useState("false");
  const [show, setShow] = useState(false);
  const [competence, setCompetence] = useState([]);
  const uri = process.env.REACT_APP_BASE_URL;
  let toastId = null;
  let history = useHistory();
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }
//récupérer toutes les compétences 
  function getcompetence() {
    setCompetence([]);
    axios
      .get(uri + "/skills")
      .then((result) => {
        setCompetence(result?.data);
        
      })
      .catch((err) => {
        console.log("err location", err);
      });
  }
  useEffect(() => {
    getcompetence();
  }, []);
//rechercher une compétence
  function findintable(e) {
    setCompetence([]);
  
    if (e.target.value) {
      axios
        .get(uri + "/skills?search=" + e.target.value)
        .then((result) => {
          setCompetence(result?.data);
          
        })
        .catch((err) => {
          console.log("err location", err);
        });
    } else {
      getcompetence();
    }
  }
//rechercher une compétences par son nom 
  function filresearch(val) {
    setCompetence([]);
   
    if (val) {
      axios
        .get(uri + "/filter?title=" + val.name)
        .then((result) => {
          if (result?.data) {
            setCompetence(result?.data);
           
          } else {
            getcompetence();
          }
        })
        .catch((err) => {
          console.log("err location", err);
        });
    } else {
      getcompetence();
    }
  }
  



  return (
    <div className="container-fluid">
      <div className="row me-3">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn title="Compétences" level="h1" className="pt-5" plus={false} />
          </div>
        

          {/* lists-filter */}
          {displayetat === "true" ? (
            <Alert color="primary">Aucun resultat trouvé</Alert>
          ) : (
            ""
          )}
          <div className="mt-20">
           
              <Datatable
                setShow={setShow}
                show={show}
                competence={competence}
                getcompetence={getcompetence}
                setCompetence={setCompetence}
              />
        
          </div>
        </div>
      </div>
    </div>
  );
}
export default Listecompetence;
