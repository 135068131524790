import axios from "axios";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import Hn from "../../components/common/Hn";
import Add from "../../assets/icons/add.png";
import Close from "../../assets/icons/Close.png";
import { createBrowserHistory } from 'history';

export default function Createthematique() {
  let history = createBrowserHistory({forceRefresh:true})
  const uri = process.env.REACT_APP_BASE_URL;
  //créer une nouvelle thématique 
  const handleSubmit = (values) => {
  
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    let data = {
      "ref": values.reference,
      "name": values.nom,
      "subtheme": values.soustheme
    }
  
    axios
      .post(
        uri+`/thematic/new`,
        data,
        headersFiles
      )
      .then((result) => {
        history.push("/admin/thematique");
    
      })
      .catch((err) => {
        console.log("err training", err);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/thematique">Thématique</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Ajouter une thématique
              </li>
            </ol>
          </nav>
          <div className="h-100 p-5 text-white bg-dark rounded-3">
            <Hn
              title="Ajouter une thématique"
              level="h4"
              className="h4-size pb-5"
              plus={false}
            />

            <div className="form-elements">
              <Formik
                initialValues={{
                  reference: "",
                  nom: "",
                  sousthemes: [{ soustheme: "" }],
                }}
                onSubmit={handleSubmit}
                validationSchema={Yup.object().shape({
                  sousthemes: Yup.array(),
                  reference: Yup.string(),
                  nom: Yup.string(),
                })}
                render={({ values, errors }) => (
                  <Form>
                    <div className="row">
                      <div className="col-4">
                        <div className="mb-3">
                          <label htmlFor="" className="form-label">
                            Référence
                          </label>
                          <Field
                            className="form-control"
                            name="reference"
                            placeholder="Réference"
                          />
                          <ErrorMessage
                            className="form-text text-danger"
                            name="reference"
                          />
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="mb-3">
                          <label htmlFor="" className="form-label">
                            Nom de la thématique
                          </label>
                          <Field
                            className="form-control"
                            name="nom"
                            placeholder="Nom"
                          />
                          <ErrorMessage
                            className="form-text text-danger"
                            name="nom"
                          />
                        </div>
                      </div>
                    </div>
                    <FieldArray
                      name="sousthemes"
                      render={(arrayHelpers) => (
                        <div className="">
                          {values.sousthemes.map((time, index) => (
                            <div className="row" key={index}>
                              <div className="col-8">
                                <div className="mb-3">
                                  <label htmlFor="" className="form-label">
                                    Sous-thème
                                  </label>
                                  <Field
                                    className="form-control"
                                    name={`soustheme.${index}`}
                                    placeholder="Sous thématique"
                                  />
                                  <ErrorMessage
                                    className="form-text text-danger"
                                    name={`soustheme.${index}`}
                                  />
                                </div>
                              </div>
                              <div className="col-2 mt-4">
                                <span
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <img
                                    src={Close}
                                    className="pe-1"
                                    style={{
                                      paddingTop: "11px",
                                      marginLeft: "-10px",
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          ))}

                          <span
                            onClick={() =>
                              arrayHelpers.insert(values.sousthemes.length, {
                                soustheme: "",
                              })
                            }
                            style={{ color: "#0d6efd" }}
                          >
                            <img src={Add} className="pe-1" /> Ajouter un sous
                            thème
                          </span>
                          <div className="col-12 pt-5">
                            <a
                              type="reset"
                              className="btn btn-link"
                              href="/admin/thematique"
                            >
                              Annuler
                            </a>
                            <button
                              type="submit"
                              className="btn btn-primary float-end"
                            >
                              Valider
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
        {/* lists */}
      </div>
    </div>
  );
}
