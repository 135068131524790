// import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import "react-toastify/dist/ReactToastify.css";
import Hn from "../../../../../components/common/Hn";
import PopupFiltre from "../../../../../components/popup/PopupFiltreParticipant";
import * as Loader from "react-loader-spinner";
{
  /* <div id="spinner">
<Loader.TailSpin
  type="ThreeDots"
  color="#2BAD60"
  height="100"
  width="100"
/>
</div> */
}
export default function ParticpantDatatable({ type="session" }) {
  // const { ExportCSVButton } = CSVExport;

  const [notInscrits, setNotInscrits] = useState([]);
  const [showfiltre, setShowfiltre] = useState(false);
  const [loading, setLoading] = useState(false);
  const [eachEntry, setEachEntry] = useState("");
  const params = useParams();
  const uri = process.env.REACT_APP_BASE_URL;
  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };
  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
  };
  const handleShowfiltre = () => {
    setShowfiltre(true);
  };

  function searchParticipant(value) {
    //setNotInscrits([]);
    let req = uri + "/participant/?search=" + value;

    axios
      .get(req)
      .then((result) => {
        if (result?.data.length > 0) {
          setNotInscrits(result?.data);
        } else {
          setNotInscrits([]);
        }
      })
      .catch((err) => {
        console.log("err search", err);
      });
  }

  function searchwithfiltre(val) {
    setNotInscrits([]);
    // setInitraining(participant);

    setEachEntry(val);
    let query = `collaborator=${val.collaborator}&agent=${val.agent}&offre=${val.offre}&manager=${val.agence}`;
    let req = uri + "/participant/filter?" + query;

    axios
      .get(req)
      .then((result) => {
        if (result?.data.length > 0) {
          setNotInscrits((prevState) => [...prevState, result?.data]);
        } else {
          setNotInscrits([]);
        }
      })
      .catch((err) => {
        console.log("err filter", err);
      });
  }

  function getParticipant() {
    setLoading(true);
  
    axios
      .get(uri + "/participant")
      .then((result) => {
      
        setNotInscrits(result?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err participant", err);
      });
  }


  useEffect(() => {
    getParticipant();
  }, []);

  let toastId = null;

  const columns = [
    {
      dataField: "apprenant",
      text: "Collaborateur",
      csvText: "apprenant",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            {row?.contactNom} {row?.contactPrenom}
          </>
        );
      },
    },
    {
      dataField: "mailcontact",
      text: "Email",
      csvText: "email",
      sort: true,
    },
    {
      dataField: "agencymain",
      text: "Agent",
      csvText: "agent",
      sort: true,
    },
    {
      dataField: "agenceEmail",
      text: "Email Agent",
      csvText: "emailAgent",
      sort: true,
    },
    {
      dataField: "offre",
      text: "Offre",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <span>{row.offre}</span>;
      },
    },
  ];

  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }

  let api_add = '';
  let api_delete = '';

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: (row, isSelect, rowIndex, e) => {

      let datasend = {
        participant: row.ContactID,
      };

      let datasendDelete = {
        participant: [row.id],
      };

      let datasendWebinars = {
        participant: row.id,
      };
      let datas;
      if (type === "webinars") {
        api_add = `webinars/edit/particiapnt/${params.id}`;
        api_delete = `webinars/delete/particiapnt/${params.id}`;
        datas = datasendWebinars
      } else {
        api_add = `session/edit/particiapnt/` + localStorage.getItem('idsession');
        api_delete = `session/delete/particiapnt/` + localStorage.getItem('idsession');
        datas = datasendDelete
      }

      if (isSelect) {
        
        axios
          .put(`${uri}/${api_add}`, datasendWebinars, headers)
          .then((response) => {
            console.log("succes", response.data);
          })
          .catch((error) => {
            console.log("error", error, datasend);
            // displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
          });
      } else {
       
        axios
          .put(`${uri}/${api_delete}`, datas, headers)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log('err delete', error, datasend)
            // displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
          });
      }
    },
  };
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };
  return (
    <>
      {notInscrits.length ? (
        <ToolkitProvider
          keyField="ContactID"
          data={notInscrits}
          columns={columns}
          exportCSV={{ onlyExportSelection: true, exportAll: false }}
          search
        >
          {(props) => (
            <div>
              <MyExportCSV {...props.csvProps} />
              <div className="lists-filter mb-5">
                <div className="row">
                  <div className="col-xl-4 col-lg-6">
                    <Hn
                      title="Ajouter un participant"
                      level="h4"
                      className="h4-size pb-5"
                      plus={false}
                    />
                  </div>
                  <div className="col-xl-8 col-lg-6 col-md-12 col-xs-12">
                    <div className="flexgy">
                      <div className="col-md-6  mr-1  px-0">
                        <div className="input-group">
                          <input
                            className="form-control height43"
                            type="search"
                            placeholder="Recherche"
                            onKeyUp={(e) => searchParticipant(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className=" px-0 myxs">
                        <div className="flex">
                          <div className="square">
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={handleShowfiltre}
                            >
                              <img
                                src={require("../../../../../assets/icons/filter.png")}
                              />
                            </button>
                          </div>
                          <PopupFiltre
                            showfiltre={showfiltre}
                            closefiltre={handleClosefiltre}
                            searchwithfiltre={searchwithfiltre}
                            filterEntry={eachEntry}
                          />
                          <div className="square">
                            <button
                              onClick={() => props.csvProps.onExport()}
                              type="button"
                              className="btn btn-link"
                            >
                              <img
                                src={require("../../../../../assets/icons/Export.png")}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <BootstrapTable
                {...props.baseProps}
                classes="table-dark table-responsive"
                bordered={false}
                hover
                selectRow={selectRow}
                pagination={paginationFactory(options)}
              />
            </div>
          )}
        </ToolkitProvider>
      ) :
        <div id="spinner">
          <Loader.TailSpin
            type="ThreeDots"
            color="#2BAD60"
            height="100"
            width="100"
          />
        </div>}

    </>
  );
}
