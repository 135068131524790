import { useEffect, useRef, useState } from "react";

import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import { createBrowserHistory } from 'history';
import Select from "react-select";
import * as Yup from "yup";
import Add from "../../assets/icons/add.png";
import Hn from "../../components/common/Hn";
//import "./createFaceTraining.css";
import "./webinar.css";
//const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']
const FILE_SIZE = 4 * 2048;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png"
];
const validationSchema = Yup.object().shape({
  // reference: Yup.string().required("Champ obligatoire."),
  // intitule: Yup.string().required("Champ obligatoire."),
  // theme: Yup.string().required("Champ obligatoire."),

});
function CreateWebinair() {
  const fileRef = useRef();
  const pdfRef = useRef();
  const [providers, setProviders] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [elearnings, setElearnings] = useState([]);
  const [options, setOptions] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [formateurs, setForamteurs] = useState([]);
  const [selectedFormateur, setSelectedFormateur] = useState(null);
  const [themes, setThemes] = useState([]);
  const [changeListformer, setChangeListformer] = useState(true);
  const imageValue = Yup.ref('image');
  const uri = process.env.REACT_APP_BASE_URL;
  //récupérer les formations présentielles
  function getTrainings() {

    axios.get(uri + "/training")
      .then((result) => {
        setTrainings(result?.data);
      })
      .catch((err) => {
        console.log("err trainning", err);
      });
  }

  //récupérer les prestataires
  function getProviders() {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };
    axios
      .get(uri + "/provider", headersFiles)
      .then((result) => {
        setProviders(result?.data);
        
      })
      .catch((err) => {
        console.log("err prestataire", err);
      });


  }

  //récupérer les formateurs avec leurs types(interne ou externe)
  function getformer(type) {
    let url = "/former";
    if (type) {
      url = "/former/organize/" + type;
    }

    axios
      .get(uri + url)
      .then((result) => {
        setForamteurs(result?.data);
        result?.data?.map((item) => {
          setOptions((options) => [
            ...options,
            {
              value: item.id,
              label: item?.lastName + " " + item?.firstName
            },
          ]);
        });
      })
      .catch((err) => {
        console.log("err formateurs", err);
      });
  }

  //récupérer les formations elearning
  function getElearnings() {

    axios.get(uri + "/elearning")
      .then((result) => {
        setElearnings(result?.data);
      })
      .catch((err) => {
        console.log("err elearning", err);
      });
  }
  let history = createBrowserHistory({ forceRefresh: true })
//créer un nouveau webinaire
  const handleSubmit = (values) => {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

  
    let fr = [];
    values.former.map((op) => {
      fr.push(op.value);
    });
 
    var data = new FormData();
    data.append('ref', values.reference);
    data.append('title', values.intitule);
    data.append('theme', values.theme);
    data.append('date', values.date);
    data.append('hour', values.heure);
    // data.append('former', fr);
    fr.forEach(former=>data.append('former[]',former));
    data.append('audience', values.prerequis);
    data.append('objects', values.objectifs);
    data.append('training_program', values.program);
    data.append('program_file', values.pdf);
    data.append('provider', values.prestataire);
    data.append('duration', values.duree);
    data.append('capacity_min', values.min);
    data.append('capacity_max', values.max);
    data.append('cost', values.tarif);
    data.append('status', values.statut);
    data.append('comment', values.commentaire);
    data.append('forward_catalog', values.miseavant);
    data.append('manager_spec', values.manager);
    data.append('obligation', values.obligation);
  
    axios.post(uri + '/webinars/new', data, headersFiles)
      .then((result) => {
        // history.push("/webinair/");

          setTimeout(() => {
          history.push("/createWebinairEtape2/"+result?.data?.id);
           }, 1500);
      
      })
      .catch((err) => {
        console.log("err webinar", err);
      });

  };

  //récupérer les prestataires
  function getProviders() {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };
    axios
      .get(uri + "/provider", headersFiles)
      .then((result) => {
        setProviders(result?.data);
       
      })
      .catch((err) => {
        console.log("err prestataire", err);
      });


  }
  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontSize: "16px",
      fontWeight: state.isSelected ? "600" : "400",
      backgroundColor: "#3A4048",
      color: "white",
      border: "none",
      borderRadius: "none",
    }),
    dropdownIndicator: (base) => ({
      color: "#006FFF",
      marginRight: "10px",
    }),
    menu: (base) => ({
      backgroundColor: "#212730",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#1E2E45" : "#212730",
      fontWeight: state.isSelected ? "600" : "400",
      borderBottom: "1px solid #3A4048",
      color: "white",
      margin: "5px 14px 5px 10px",
    }),
    multiValue: (base, state) => ({
      ...base,
      color: "white",
      backgroundColor: "#1E2E45",
      fontWeight: state.isSelected ? "600" : "400",
      margin: "6px 10px 5px 10px",
      padding: "5px 14px 5px 10px",
    }),
    multiValueLabel: (base) => ({
      color: "white",
    }),
    multiValueRemove: (base) => ({
      color: "#006FFF",
      fontSize: "16px",
      height: "20px",
      width: "20px",
    }),
  };
  //récupérer les thémes
  function getThem() {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };
    axios
      .get(uri + "/thematic", headersFiles)
      .then((result) => {
        setThemes(result?.data);
       
      })
      .catch((err) => {
        console.log("err theme", err);
      });
  }

  //si on choisit un type d'un formateur ca nous donne automatiquement sa liste des noms/prénoms de ce type choisi 
  function ChangeList(value) {
    setChangeListformer(false);
    getformer(value);
  }
  useEffect(() => {

    getProviders();
    getformer();
    getThem();
    getElearnings();
    getTrainings();
  }, []);

  

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/webinair">Webinar</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Créer un webinar
              </li>
            </ol>
          </nav>
          <div className=" p-5 text-white bg-dark rounded-3 me-3">
            <Hn
              title="Créer un webinar"
              level="h4"
              className="h4-size"
              plus={false}
            />
            <Hn
              title="Étape 1 : Informations générales"
              level="p"
              className="py-2 fw-bold"
              plus={false}
            />

            <div className="form-elements">
              <Formik
                initialValues={{
                  reference: "",
                  intitule: "",
                  theme: "",
                  date: "",
                  heure: "",
                  former: [],
                  prerequis: "",
                  objectifs: "",
                  program: "",
                  pdf: null,
                  object: "",
                  program: "",
                  prestataire: "",
                  duree: "",
                  min: 0,
                  max: 0,
                  tarif: "",
                  statut: "false",
                  commentaire: "",
                  miseavant: 0,
                  manager: 0,
                  obligation: 0,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
                  /* and other goodies */
                }) => (
                  <form className="row g-3" onSubmit={handleSubmit}>
                    <div className="col-md-4">
                      <label className="form-label">
                        Référence*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ref"
                        name="reference"
                        placeholder="Référence"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.reference}
                      />
                      <ErrorMessage name="reference">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-8">
                      <label className="form-label">
                        Intitulé*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="intitule"
                        id="init"
                        placeholder="Intitulé"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.intitule}
                      />
                      <ErrorMessage name="intitule">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">
                        Thème*
                      </label>

                      <select
                        className="form-select"
                        name="theme"
                        id="theme"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.theme}
                      >
                        <option value="">Sélectionner dans la liste</option>
                        {themes.map((theme, i) => {
                          return (
                            <option value={theme.id} key={i}>{theme.name}</option>
                          );
                        })}
                      </select>
                      <ErrorMessage name="themes">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label" >Date</label>
                      <input type="date" className="form-control" name="date" id="date-d" value={values.date} placeholder="Date" onChange={handleChange} />

                      <ErrorMessage name="date">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">

                      <label className="form-label"> Heure</label>
                      <input type="time" className="form-control date-hour" name="heure" id="heure" value={values.heure} placeholder="Date de début" onChange={handleChange} />

                      <ErrorMessage name="heure">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        Formateur
                      </label>

                      <select
                        className="form-select"
                        aria-label="Default select example"
                        id="actif"
                        defaultValue={"sélectinner dans la liste"}
                        onChange={(event) => ChangeList(event.target.value)}
                      >


                        <option value="">sélectionner dans la liste</option>
                        <option value="interne">Interne</option>
                        <option value="externe">Externe</option>
                      </select>

                      <ErrorMessage name="tarif">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label py-2"> </label>

                      <Select
                        styles={customStyles}
                        onChange={(value) => setFieldValue('former', value)}
                        className="themeSelect"
                        options={options}
                        isMulti={true}
                        placeholder="Sélectionner un ou des formateur(s)"
                        name="fr"
                      />

                      {/* <select className="form-select"  name="former"
                        id="former"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.former} >
                                {formateurs.map((former) => {
                            return (
                              <option value={former?.id}>{former?.firstName} {former?.lastName}</option>
                            );
                          })} 
                            </select> */}

                      <p className="header-text">
                        {/* {session?.former?.firstName}{" "}
                              {session?.former?.lastName} */}
                      </p>
                      <ErrorMessage name="tarif">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-12">
                      <label className="form-label">
                        Pré-requis et public visé
                      </label>
                      <textarea
                        className="form-control"
                        id="pre"
                        rows="3"
                        type="text"
                        name="prerequis"
                        placeholder="Détailler"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.prerequis}
                      ></textarea>
                      <ErrorMessage name="prerequis">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label className="form-label">
                        Objectifs
                      </label>
                      <textarea
                        className="form-control"
                        type="text"
                        id="object"
                        rows="3"
                        name="objectifs"
                        placeholder="Description des objectifs"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.objectifs}
                      ></textarea>
                      <ErrorMessage name="object">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label className="form-label">
                        Programme de formation
                      </label>
                      <textarea
                        className="form-control"
                        id="program"
                        type="text"
                        rows="3"
                        name="program"
                        placeholder="Description du programme"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.program}
                      ></textarea>
                      <ErrorMessage name="program">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">
                        Programme de formation
                      </label>
                      <span
                        className="form-control"
                        onClick={() => pdfRef.current.click()}
                      >
                        <img src={Add} className="pe-1" />  {pdfRef?.current?.value ? pdfRef?.current?.value : 'Ajouter un fichier'}
                      </span>

                      <input
                        ref={pdfRef}
                        multiple={false}
                        type="file"
                        name="pdf"
                        onChange={(event) => {
                          setFieldValue("pdf", event.currentTarget.files[0]);
                        }}
                        hidden
                        accept=".pdf"
                      />
                      <ErrorMessage name="pdf">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        Prestataire
                      </label>
                      <select
                        className="form-select"

                        name="prestataire"
                        id="prestat"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.prestataire}
                      >
                        <option value="">Sélectionner dans la liste</option>
                        {providers.map((provider, i) => {
                          return (
                            <option value={provider.id} key={i}>{provider?.contact_name} |{provider?.contact_surname}</option>
                          );
                        })}
                      </select>
                      <ErrorMessage name="prestataire">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        Durée théorique (en heure)*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="duree"
                        id="duree"
                        placeholder="00:00"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.duree}
                        pattern="\d\d:\d\d"
                      />
                      <ErrorMessage name="duree">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">Capacité d'acccueil minimale</label>
                      <input type="number" className="form-control" id="capacity-min" value={values.min} placeholder="00" onChange={handleChange} name="min" />
                      <ErrorMessage name="min">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">Capacité d’accueil maximale</label>
                      <input type="number" className="form-control" id="capacity-max" value={values.max} placeholder="00" onChange={handleChange} name="max" />
                      <ErrorMessage name="max">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        Tarif
                      </label>
                      <input
                        type="number"
                        step="0.5"
                        className="form-control cost"
                        name="tarif"
                        id="tarif"
                        placeholder="00"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tarif}
                      />
                      <ErrorMessage name="tarif">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        Actif
                      </label>
                      <select
                        className="form-select"
                        name="etat"

                        id="etat"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.statut}
                      >
                        <option value="">
                          Oui
                        </option>
                        <option value="non">Non</option>
                      </select>
                      <ErrorMessage name="etat">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label className="form-label">
                        Commentaire
                      </label>
                      <textarea
                        className="form-control"
                        type="text"
                        name="commentaire"
                        id="comment"
                        rows="3"
                        placeholder=""
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.commentaire}
                      ></textarea>
                      <ErrorMessage name="commentaire">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4 col-xs-12">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          name="miseavant"
                          type="checkbox"
                          id="check"
                          onClick={(e)=>{setFieldValue('miseavant',e.target.checked)}}
                          value={values.miseavant}
                        />
                        <label className="form-check-label" >
                          Mettre en avant dans le catalogue
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          name="manager"
                          type="checkbox"
                          id="check2"
                          onClick={(e)=>{setFieldValue('manager',e.target.checked)}}
                          value={values.manager}
                        />
                        <label className="form-check-label" >
                          Spécifique au manager
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          name="obligation"
                          type="checkbox"
                          id="check3"
                          onClick={(e)=>{setFieldValue('obligation',e.target.checked)}}

                          value={values.obligation}
                        />
                        <label className="form-check-label" >
                          Participe à l'obligation de formation
                        </label>
                      </div>
                    </div>

                    <div className="col-12 mt-5">
                      <a
                        href="/webinair"

                        className="btn btn-link text-decoration-none resetBtn"

                      >
                        Annuler
                      </a>
                      <button
                        type="submit"
                        className="btn btn-primary float-end"

                      //href="/createWebinairEtape2"
                      >
                        Suivant
                      </button>

                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* lists */}
      </div>
    </div>
  );
}

export default CreateWebinair;
